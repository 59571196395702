import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase/config';
import { ref, push } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { HiOutlineCamera, HiOutlineX, HiChevronLeft } from 'react-icons/hi';

const ProjectAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    startTime: '',
    endTime: '',
    location: '',
    city: '',
    budget: '',
    projectType: '',
    status: 'active',
    duration: '',
    images: [],
    requirements: {
      gender: '',
      ageMin: '',
      ageMax: '',
      height: '',
      weight: '',
      hairColor: '',
      eyeColor: '',
      shoeSize: '',
      experience: ''
    },
    actorDetails: {
      men: 0,
      women: 0,
      children: 0,
      extras: 0
    },
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    paymentDetails: {
      method: '',
      term: ''
    },
    additionalServices: {
      transportation: '',
      accommodation: '',
      meals: ''
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageUpload = async (e) => {
    try {
      setLoading(true);
      const files = Array.from(e.target.files);
      
      if (files.length + formData.images.length > 5) {
        alert('En fazla 5 görsel yükleyebilirsiniz');
        return;
      }

      // Önizleme için URL'ler oluştur
      const previewUrls = files.map(file => URL.createObjectURL(file));
      setFormData(prev => ({
        ...prev,
        images: [...prev.images, ...previewUrls]
      }));

      const uploadPromises = files.map(async (file, index) => {
        if (file.size > 5 * 1024 * 1024) {
          throw new Error('Her görsel en fazla 5MB olabilir');
        }

        const fileExtension = file.name.split('.').pop().toLowerCase();
        const validExtensions = ['jpg', 'jpeg', 'png', 'webp'];
        
        if (!validExtensions.includes(fileExtension)) {
          throw new Error('Sadece JPG, PNG ve WEBP formatları desteklenir');
        }

        const fileName = `projects/${Date.now()}_${Math.random().toString(36).substring(7)}.${fileExtension}`;
        const imageRef = storageRef(storage, fileName);
        
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);

        setFormData(prev => ({
          ...prev,
          images: prev.images.map((prevUrl, i) => 
            i === prev.images.length - files.length + index ? url : prevUrl
          )
        }));

        URL.revokeObjectURL(previewUrls[index]);
        return url;
      });

      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Görsel yükleme hatası:', error);
      alert(error.message || 'Görsel yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const projectRef = ref(db, 'projects');
      await push(projectRef, {
        ...formData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      navigate('/admin/projects');
    } catch (error) {
      console.error('Proje ekleme hatası:', error);
      alert('Proje eklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="bg-beyaz border-b border-kirik-beyaz">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate(-1)}
                className="p-2 -ml-2 text-yogun-antrasit rounded-lg hover:bg-kirik-beyaz"
              >
                <HiChevronLeft className="w-6 h-6" />
              </button>
              <h1 className="text-2xl font-semibold text-yogun-antrasit">
                Yeni Proje Ekle
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-7xl mx-auto px-8 py-8">
        <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-8">
          {/* Sol Kolon - Temel Bilgiler */}
          <div className="space-y-6">
            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                Temel Bilgiler
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Proje Başlığı
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Proje başlığını girin"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Proje Tipi
                  </label>
                  <select
                    name="projectType"
                    value={formData.projectType}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  >
                    <option value="">Seçiniz</option>
                    <option value="film">Film</option>
                    <option value="dizi">Dizi</option>
                    <option value="reklam">Reklam</option>
                    <option value="katalog">Katalog</option>
                    <option value="diger">Diğer</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Proje Açıklaması
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Proje detaylarını girin"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                Zaman ve Lokasyon
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Tarih
                  </label>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Başlangıç Saati
                    </label>
                    <input
                      type="time"
                      name="startTime"
                      value={formData.startTime}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Bitiş Saati
                    </label>
                    <input
                      type="time"
                      name="endTime"
                      value={formData.endTime}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Şehir
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Şehir"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Adres
                  </label>
                  <textarea
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    rows="3"
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Tam adresi girin"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Orta Kolon - Oyuncu Bilgileri */}
          <div className="space-y-6">
            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                Oyuncu Gereksinimleri
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Cinsiyet
                  </label>
                  <select
                    name="requirements.gender"
                    value={formData.requirements.gender}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  >
                    <option value="">Seçiniz</option>
                    <option value="male">Erkek</option>
                    <option value="female">Kadın</option>
                    <option value="both">Farketmez</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Minimum Yaş
                    </label>
                    <input
                      type="number"
                      name="requirements.ageMin"
                      value={formData.requirements.ageMin}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                      placeholder="18"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Maksimum Yaş
                    </label>
                    <input
                      type="number"
                      name="requirements.ageMax"
                      value={formData.requirements.ageMax}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                      placeholder="65"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Deneyim
                  </label>
                  <select
                    name="requirements.experience"
                    value={formData.requirements.experience}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  >
                    <option value="">Seçiniz</option>
                    <option value="beginner">Başlangıç</option>
                    <option value="intermediate">Orta</option>
                    <option value="advanced">İleri</option>
                    <option value="professional">Profesyonel</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                Oyuncu Sayıları
              </h2>
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Erkek
                    </label>
                    <input
                      type="number"
                      name="actorDetails.men"
                      value={formData.actorDetails.men}
                      onChange={handleInputChange}
                      min="0"
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Kadın
                    </label>
                    <input
                      type="number"
                      name="actorDetails.women"
                      value={formData.actorDetails.women}
                      onChange={handleInputChange}
                      min="0"
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Çocuk
                    </label>
                    <input
                      type="number"
                      name="actorDetails.children"
                      value={formData.actorDetails.children}
                      onChange={handleInputChange}
                      min="0"
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Ekstra
                    </label>
                    <input
                      type="number"
                      name="actorDetails.extras"
                      value={formData.actorDetails.extras}
                      onChange={handleInputChange}
                      min="0"
                      className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Sağ Kolon - Diğer Bilgiler */}
          <div className="space-y-6">
            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                Görseller
              </h2>
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-4">
                  {formData.images.map((image, index) => (
                    <div key={index} className="relative aspect-square rounded-xl overflow-hidden">
                      <img
                        src={image}
                        alt={`Proje görseli ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            images: prev.images.filter((_, i) => i !== index)
                          }));
                        }}
                        className="absolute top-2 right-2 p-1.5 bg-black/50 text-beyaz rounded-lg hover:bg-black/70"
                      >
                        <HiOutlineX className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                  {formData.images.length < 5 && (
                    <label className="aspect-square rounded-xl border-2 border-dashed border-kirik-beyaz hover:border-yogun-antrasit cursor-pointer flex items-center justify-center">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageUpload}
                        className="hidden"
                      />
                      <HiOutlineCamera className="w-8 h-8 text-grafit-gri" />
                    </label>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                Ödeme Detayları
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Bütçe
                  </label>
                  <input
                    type="text"
                    name="budget"
                    value={formData.budget}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Örn: 1000₺"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Ödeme Yöntemi
                  </label>
                  <select
                    name="paymentDetails.method"
                    value={formData.paymentDetails.method}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  >
                    <option value="">Seçiniz</option>
                    <option value="cash">Nakit</option>
                    <option value="bank">Banka Transferi</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Ödeme Vadesi
                  </label>
                  <select
                    name="paymentDetails.term"
                    value={formData.paymentDetails.term}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  >
                    <option value="">Seçiniz</option>
                    <option value="immediate">Çekim Günü</option>
                    <option value="weekly">1 Hafta</option>
                    <option value="biweekly">2 Hafta</option>
                    <option value="monthly">1 Ay</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="bg-beyaz rounded-2xl p-6">
              <h2 className="text-lg font-semibold text-yogun-antrasit mb-6">
                İletişim Bilgileri
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    İletişim Kişisi
                  </label>
                  <input
                    type="text"
                    name="contact.name"
                    value={formData.contact.name}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Ad Soyad"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    E-posta
                  </label>
                  <input
                    type="email"
                    name="contact.email"
                    value={formData.contact.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="ornek@email.com"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Telefon
                  </label>
                  <input
                    type="tel"
                    name="contact.phone"
                    value={formData.contact.phone}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="05XX XXX XX XX"
                  />
                </div>
              </div>
            </div>

            <div className="sticky bottom-8">
              <button
                type="submit"
                disabled={loading}
                className="w-full py-4 bg-yogun-antrasit text-beyaz rounded-xl font-medium hover:bg-black transition-colors disabled:opacity-50 disabled:hover:bg-yogun-antrasit"
              >
                {loading ? 'Kaydediliyor...' : 'Projeyi Yayınla'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectAdd;
