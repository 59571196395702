import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaBell, FaUser, FaSignOutAlt, FaChevronDown } from 'react-icons/fa';
import KlaketLogo from '../../assets/logos.svg';
import { ref, onValue, update } from 'firebase/database';
import { db } from '../../firebase/config';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const menuRef = useRef(null);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (user?.uid) {
      const notificationsRef = ref(db, `notifications/${user.uid}`);
      onValue(notificationsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const notifArray = Object.entries(data).map(([id, notif]) => ({
            id,
            ...notif,
            timestamp: new Date(notif.timestamp),
          })).sort((a, b) => b.timestamp - a.timestamp);
          setNotifications(notifArray);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const unreadCount = notifications.filter(notif => !notif.read).length;

  const markAsRead = async (notificationId) => {
    try {
      const notificationRef = ref(db, `notifications/${user.uid}/${notificationId}`);
      await update(notificationRef, { read: true });
    } catch (error) {
      console.error('Bildirim güncellenirken hata:', error);
    }
  };

  const formatNotificationTime = (timestamp) => {
    const now = new Date();
    const diff = now - timestamp;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(diff / 3600000);
    const days = Math.floor(diff / 86400000);

    if (minutes < 60) return `${minutes} dakika önce`;
    if (hours < 24) return `${hours} saat önce`;
    return `${days} gün önce`;
  };

  const menuItems = [
    { label: 'Anasayfa', path: '/home' },
    { label: 'Projeler', path: '/projects' },
    { label: 'Oyuncular', path: '/players' },
    { label: 'Duyurular', path: '/announcements' },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Çıkış yapılırken hata oluştu:', error);
    }
  };

  return (
    <header className="bg-beyaz border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <img 
              src={KlaketLogo} 
              alt="Klaket Logo" 
              className="h-8 w-auto cursor-pointer"
              onClick={() => navigate('/home')}
            />
          </div>

          {/* Ana Menü */}
          <nav className="hidden md:flex space-x-8">
            {menuItems.map((item) => (
              <button
                key={item.label}
                onClick={() => navigate(item.path)}
                className={`text-sm font-medium transition-colors hover:text-yogun-antrasit ${
                  location.pathname === item.path 
                    ? 'text-yogun-antrasit border-b-2 border-yogun-antrasit' 
                    : 'text-grafit-gri'
                }`}
              >
                {item.label}
              </button>
            ))}
          </nav>

          {/* Sağ Taraf */}
          <div className="flex items-center space-x-6">
            {/* Bildirimler */}
            <div className="relative" ref={notificationRef}>
              <button 
                onClick={() => setShowNotifications(!showNotifications)}
                className="relative text-grafit-gri hover:text-yogun-antrasit transition-colors p-2 rounded-xl hover:bg-kirik-beyaz"
              >
                <FaBell className="w-5 h-5" />
                {unreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 w-5 h-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                    {unreadCount}
                  </span>
                )}
              </button>

              {/* Bildirimler Menüsü */}
              {showNotifications && (
                <div className="absolute right-0 mt-2 w-80 bg-beyaz rounded-xl shadow-lg overflow-hidden border border-grafit-gri/10 z-50">
                  <div className="px-4 py-3 border-b border-grafit-gri/10 bg-kirik-beyaz">
                    <h3 className="text-sm font-medium text-yogun-antrasit">Bildirimler</h3>
                  </div>

                  <div className="max-h-96 overflow-y-auto">
                    {notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <div
                          key={notification.id}
                          onClick={() => markAsRead(notification.id)}
                          className={`p-4 border-b border-grafit-gri/10 cursor-pointer hover:bg-kirik-beyaz transition-colors ${
                            !notification.read ? 'bg-kirik-beyaz/50' : ''
                          }`}
                        >
                          <div className="flex items-start gap-3">
                            <div className="flex-1">
                              <p className="text-sm text-yogun-antrasit">
                                {notification.message}
                              </p>
                              <p className="text-xs text-grafit-gri mt-1">
                                {formatNotificationTime(notification.timestamp)}
                              </p>
                            </div>
                            {!notification.read && (
                              <span className="w-2 h-2 rounded-full bg-red-500 mt-1.5" />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="p-4 text-center text-grafit-gri text-sm">
                        Bildirim bulunmuyor
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Profil */}
            <div className="relative" ref={menuRef}>
              <button 
                onClick={() => setShowProfileMenu(!showProfileMenu)}
                className="flex items-center space-x-3 hover:bg-kirik-beyaz rounded-xl p-2 transition-colors"
              >
                <div className="w-8 h-8 rounded-full bg-kirik-beyaz flex items-center justify-center overflow-hidden">
                  {user?.profileImage ? (
                    <img 
                      src={user.profileImage} 
                      alt="Profil" 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <FaUser className="w-4 h-4 text-grafit-gri" />
                  )}
                </div>
                <span className="text-sm font-medium text-grafit-gri">
                  {user?.firstName || 'Profil'}
                </span>
                <FaChevronDown className="w-4 h-4 text-grafit-gri" />
              </button>

              {/* Profil Menüsü */}
              {showProfileMenu && (
                <div className="absolute right-0 mt-2 w-48 bg-beyaz rounded-xl shadow-lg overflow-hidden border border-grafit-gri/10 z-50">
                  {/* Profil Bilgisi */}
                  <div className="px-4 py-3 border-b border-grafit-gri/10 bg-kirik-beyaz">
                    <p className="text-sm font-medium text-yogun-antrasit truncate">
                      {user?.firstName} {user?.lastName}
                    </p>
                    <p className="text-xs text-grafit-gri truncate mt-0.5">
                      {user?.email}
                    </p>
                  </div>

                  {/* Menü Seçenekleri */}
                  <div className="py-1">
                    <button
                      onClick={() => {
                        navigate('/profile');
                        setShowProfileMenu(false);
                      }}
                      className="w-full px-4 py-2 text-sm text-grafit-gri hover:bg-kirik-beyaz hover:text-yogun-antrasit text-left transition-colors flex items-center"
                    >
                      <FaUser className="w-4 h-4 mr-2" />
                      Profil
                    </button>
                    
                    {/* Ayırıcı Çizgi */}
                    <div className="h-px bg-grafit-gri/10 my-1" />
                    
                    {/* Çıkış Yap */}
                    <button
                      onClick={() => {
                        setShowLogoutConfirm(true);
                        setShowProfileMenu(false);
                      }}
                      className="w-full px-4 py-2 text-sm text-yogun-antrasit hover:bg-kirik-beyaz text-left transition-colors flex items-center group"
                    >
                      <FaSignOutAlt className="w-4 h-4 mr-2 group-hover:rotate-90 transition-transform duration-200" />
                      Çıkış Yap
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Çıkış Onay Popup */}
      {showLogoutConfirm && (
        <div className="fixed inset-0 bg-yogun-antrasit/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-xl p-6 max-w-sm w-full mx-4 shadow-lg" onClick={e => e.stopPropagation()}>
            <h3 className="text-lg font-medium text-yogun-antrasit mb-2">
              Çıkış Yap
            </h3>
            <p className="text-grafit-gri mb-6">
              Çıkış yapmak istediğinizden emin misiniz?
            </p>
            <div className="flex gap-3">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="flex-1 py-2 px-4 rounded-xl border border-grafit-gri text-grafit-gri hover:bg-kirik-beyaz transition-colors"
              >
                İptal
              </button>
              <button
                onClick={() => {
                  handleLogout();
                  setShowLogoutConfirm(false);
                }}
                className="flex-1 py-2 px-4 rounded-xl bg-yogun-antrasit text-beyaz hover:bg-grafit-gri transition-colors"
              >
                Çıkış Yap
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
