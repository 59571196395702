import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { ref, set, get } from 'firebase/database';
import { FaChevronLeft, FaPlus, FaTimes, FaInfoCircle, FaSpinner } from 'react-icons/fa';

import PersonalInfoForm from '../../components/cv/PersonalInfoForm';
import PhysicalInfoForm from '../../components/cv/PhysicalInfoForm';
import EducationForm from '../../components/cv/EducationForm';
import SkillsForm from '../../components/cv/SkillsForm';
import CVPreview from '../../components/cv/CVPreview';
import EducationModal from '../../components/cv/EducationModal';
import { formConfig } from '../../config/formConfig';

// LoadingSpinner komponenti
const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-white flex items-center justify-center">
    <FaSpinner className="w-8 h-8 text-yogun-antrasit animate-spin" />
  </div>
);

// SuccessModal komponenti
const SuccessModal = ({ show, message, subMessage }) => {
  if (!show) return null;
  
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-beyaz rounded-2xl p-8 max-w-md w-full mx-4 text-center animate-fade-scale">
        <div className="w-20 h-20 rounded-full bg-yesil/10 flex items-center justify-center mx-auto mb-6">
          <svg className="w-10 h-10 text-yesil" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h3 className="text-2xl font-medium text-yogun-antrasit mb-3">{message}</h3>
        <p className="text-grafit-gri">{subMessage}</p>
      </div>
    </div>
  );
};

// Ana CvForm komponenti
function CvForm() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeSection, setActiveSection] = useState('personal');
  const [formData, setFormData] = useState({
    personal: {
      positions: [],
      fullName: '',
      birthDate: '',
      tcNo: '',
      gender: '',
      city: '',
      phone: '',
      email: ''
    },
    physical: {
      height: '',
      weight: '',
      bodySize: '',
      shoeSize: '',
      eyeColor: '',
      hairColor: ''
    },
    education: [],
    skills: []
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [currentEducation, setCurrentEducation] = useState({
    institution: '',
    department: '',
    startYear: '',
    endYear: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);

  // Veri çekme işlemi
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Önce kullanıcının temel bilgilerini çekelim
        const userSnapshot = await get(ref(db, `users/${user.uid}`));
        const userData = userSnapshot.val();
        
        // Sonra CV verilerini çekelim
        const cvSnapshot = await get(ref(db, `users/${user.uid}/profilecv/globalcv`));
        const cvData = cvSnapshot.exists() ? cvSnapshot.val() : {};

        setFormData(prev => ({
          ...prev,
          ...cvData,
          education: cvData.education || [],
          personal: {
            ...prev.personal,
            ...cvData.personal,
            fullName: userData.fullName || '',
            email: userData.email || '',
            phone: userData.phone || ''
          }
        }));
        setLoading(false);
      } catch (error) {
        console.error('Veri çekme hatası:', error);
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [user?.uid]);

  // Form alanı değişiklik işleyicisi
  const handleInputChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
    // Hata varsa temizle
    if (errors[`${section}.${field}`]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[`${section}.${field}`];
        return newErrors;
      });
    }
  };

  // Yetenek toggle işleyicisi
  const handleSkillToggle = (skill) => {
    setFormData(prev => ({
      ...prev,
      skills: prev.skills.includes(skill)
        ? prev.skills.filter(s => s !== skill)
        : [...prev.skills, skill]
    }));
  };

  // Eğitim ekleme işleyicisi
  const handleEducationAdd = () => {
    if (currentEducation.institution && currentEducation.startYear) {
      setFormData(prev => ({
        ...prev,
        education: Array.isArray(prev.education) 
          ? [...prev.education, currentEducation]
          : [currentEducation]
      }));
      setCurrentEducation({
        institution: '',
        department: '',
        startYear: '',
        endYear: ''
      });
      setShowEducationModal(false);
    }
  };

  // Eğitim silme işleyicisi
  const handleEducationDelete = (index) => {
    setFormData(prev => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index)
    }));
  };

  // Form doğrulama
  const validateForm = () => {
    const newErrors = {};
    
    // Kişisel bilgiler doğrulama
    if (!formData.personal.positions) {
      newErrors['personal.positions'] = 'Pozisyon seçimi zorunludur';
    }
    if (!formData.personal.fullName) {
      newErrors['personal.fullName'] = 'Ad Soyad zorunludur';
    }
    if (!formData.personal.birthDate) {
      newErrors['personal.birthDate'] = 'Doğum tarihi zorunludur';
    }
    if (!formData.personal.tcNo) {
      newErrors['personal.tcNo'] = 'TC Kimlik No zorunludur';
    } else if (!/^[0-9]{11}$/.test(formData.personal.tcNo)) {
      newErrors['personal.tcNo'] = 'Geçerli bir TC Kimlik No giriniz';
    }
    if (!formData.personal.gender) {
      newErrors['personal.gender'] = 'Cinsiyet seçimi zorunludur';
    }
    if (!formData.personal.city) {
      newErrors['personal.city'] = 'Şehir seçimi zorunludur';
    }

    // Fiziksel özellikler doğrulama
    if (!formData.physical.height) {
      newErrors['physical.height'] = 'Boy zorunludur';
    }
    if (!formData.physical.weight) {
      newErrors['physical.weight'] = 'Kilo zorunludur';
    }
    if (!formData.physical.bodySize) {
      newErrors['physical.bodySize'] = 'Beden zorunludur';
    }
    if (!formData.physical.shoeSize) {
      newErrors['physical.shoeSize'] = 'Ayakkabı numarası zorunludur';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Kaydetme işleyicisi
  const handleSave = async () => {
    if (!validateForm()) {
      setError('Lütfen zorunlu alanları doldurunuz');
      return;
    }

    setSaving(true);
    setError(null);

    try {
      await set(ref(db, `users/${user.uid}/profilecv/globalcv`), formData);
      setShowSuccess(true);
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    } catch (error) {
      console.error('Kaydetme hatası:', error);
      setError('CV kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  const renderFormContent = () => {
    switch (activeSection) {
      case 'personal':
        return (
          <PersonalInfoForm 
            formData={formData}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        );
      case 'physical':
        return (
          <PhysicalInfoForm 
            formData={formData}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        );
      case 'education':
        return (
          <EducationForm
            items={formData.education}
            onAdd={() => setShowEducationModal(true)}
            onDelete={handleEducationDelete}
          />
        );
      case 'skills':
        return (
          <SkillsForm
            skills={formConfig.skills.options}
            selected={formData.skills}
            onToggle={handleSkillToggle}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-[#F9F9F9]">
      {/* Header */}
      <div className="bg-beyaz shadow-sm">
        <div className="max-w-7xl mx-auto px-6 py-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button onClick={() => navigate(-1)} className="text-yogun-antrasit hover:text-grafit-gri transition-colors">
              <FaChevronLeft className="w-6 h-6" />
            </button>
            <h1 className="text-2xl font-bold text-yogun-antrasit">CV Oluştur</h1>
          </div>
          <button
            onClick={handleSave}
            disabled={saving}
            className="px-6 py-2 bg-yogun-antrasit text-beyaz rounded-xl hover:bg-opacity-90 
              transition-colors disabled:opacity-50 flex items-center gap-2"
          >
            {saving ? <FaSpinner className="w-4 h-4 animate-spin" /> : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-6 py-8 grid grid-cols-12 gap-8">
        {/* Form */}
        <div className="col-span-8">
          <div className="bg-beyaz rounded-xl shadow-sm p-6">
            {/* Tab Bar */}
            <div className="flex space-x-4 mb-8 border-b">
              {Object.entries(formConfig).map(([key, config]) => (
                <button
                  key={key}
                  onClick={() => setActiveSection(key)}
                  className={`px-4 py-3 border-b-2 transition-colors
                    ${activeSection === key
                      ? 'border-yogun-antrasit text-yogun-antrasit'
                      : 'border-transparent text-grafit-gri hover:text-yogun-antrasit'}`}
                >
                  {config.title}
                </button>
              ))}
            </div>

            {/* Form Content */}
            {renderFormContent()}
          </div>
        </div>

        {/* Preview */}
        <div className="col-span-4 sticky top-8">
          <CVPreview data={formData} />
        </div>
      </div>

      {/* Modals */}
      <EducationModal
        show={showEducationModal}
        education={currentEducation}
        onChange={setCurrentEducation}
        onSave={handleEducationAdd}
        onClose={() => setShowEducationModal(false)}
      />

      <SuccessModal
        show={showSuccess}
        message="CV başarıyla kaydedildi"
        subMessage="Profilinize yönlendiriliyorsunuz..."
      />

      {/* Error mesajı */}
      {error && (
        <div className="fixed bottom-4 right-4 bg-kirmizi/10 text-kirmizi px-6 py-3 rounded-xl shadow-lg">
          {error}
        </div>
      )}
    </div>
  );
}

export default CvForm; 