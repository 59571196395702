import React from 'react';
import { FaTimes } from 'react-icons/fa';

const EducationModal = ({ show, education, onChange, onSave, onClose }) => {
  if (!show) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave();
  };

  const handleChange = (field, value) => {
    onChange({
      ...education,
      [field]: value
    });
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 50 }, (_, i) => currentYear - i);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-beyaz rounded-2xl w-full max-w-lg mx-4 animate-fade-scale">
        {/* Modal Header */}
        <div className="flex items-center justify-between p-4 border-b border-kirik-beyaz">
          <h3 className="text-lg font-medium text-yogun-antrasit">
            Eğitim Bilgisi Ekle
          </h3>
          <button 
            onClick={onClose}
            className="p-2 text-grafit-gri hover:text-kirmizi rounded-lg transition-colors"
          >
            <FaTimes className="w-4 h-4" />
          </button>
        </div>

        {/* Modal Content */}
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {/* Kurum */}
          <div>
            <label className="block text-sm font-medium text-yogun-antrasit mb-1">
              Kurum / Okul <span className="text-kirmizi">*</span>
            </label>
            <input
              type="text"
              value={education.institution || ''}
              onChange={(e) => handleChange('institution', e.target.value)}
              className="w-full h-12 px-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit"
              placeholder="Örn: İstanbul Üniversitesi"
              required
            />
          </div>

          {/* Bölüm */}
          <div>
            <label className="block text-sm font-medium text-yogun-antrasit mb-1">
              Bölüm <span className="text-kirmizi">*</span>
            </label>
            <input
              type="text"
              value={education.department || ''}
              onChange={(e) => handleChange('department', e.target.value)}
              className="w-full h-12 px-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit"
              placeholder="Örn: Tiyatro"
              required
            />
          </div>

          {/* Yıllar */}
          <div className="grid grid-cols-2 gap-4">
            {/* Başlangıç Yılı */}
            <div>
              <label className="block text-sm font-medium text-yogun-antrasit mb-1">
                Başlangıç Yılı <span className="text-kirmizi">*</span>
              </label>
              <select
                value={education.startYear || ''}
                onChange={(e) => handleChange('startYear', e.target.value)}
                className="w-full h-12 px-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit appearance-none"
                required
              >
                <option value="">Seçiniz</option>
                {yearOptions.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>

            {/* Bitiş Yılı */}
            <div>
              <label className="block text-sm font-medium text-yogun-antrasit mb-1">
                Bitiş Yılı
              </label>
              <select
                value={education.endYear || ''}
                onChange={(e) => handleChange('endYear', e.target.value)}
                className="w-full h-12 px-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit appearance-none"
              >
                <option value="">Devam Ediyor</option>
                {yearOptions.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 h-12 rounded-xl border border-grafit-gri/30 text-grafit-gri hover:border-yogun-antrasit hover:text-yogun-antrasit transition-colors"
            >
              İptal
            </button>
            <button
              type="submit"
              className="flex-1 h-12 rounded-xl bg-yogun-antrasit text-beyaz hover:bg-yogun-antrasit/90 transition-colors"
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EducationModal; 