import React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';

const EducationForm = ({ items = [], onAdd, onDelete }) => {
  return (
    <div className="space-y-4">
      {items?.map((edu, index) => (
        <div key={index} className="flex justify-between items-start p-4 bg-kirik-beyaz rounded-xl group hover:bg-kirik-beyaz/80 transition-colors">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <h4 className="font-medium text-yogun-antrasit">{edu.institution}</h4>
              <span className="px-2 py-0.5 bg-yogun-antrasit/10 text-yogun-antrasit text-xs rounded-full">
                {edu.endYear || 'Devam Ediyor'}
              </span>
            </div>
            <p className="text-sm text-grafit-gri mt-1">{edu.department}</p>
            <p className="text-xs text-grafit-gri mt-0.5">
              {edu.startYear} - {edu.endYear || 'Devam Ediyor'}
            </p>
          </div>
          <button 
            onClick={() => onDelete(index)} 
            className="p-2 text-grafit-gri hover:text-kirmizi hover:bg-kirmizi/10 rounded-lg transition-colors opacity-0 group-hover:opacity-100"
            title="Sil"
          >
            <FaTimes className="w-4 h-4" />
          </button>
        </div>
      ))}
      <button
        onClick={onAdd}
        className="w-full py-4 px-4 rounded-xl border-2 border-dashed border-grafit-gri/30
          text-grafit-gri hover:border-yogun-antrasit hover:text-yogun-antrasit hover:bg-kirik-beyaz/50
          transition-all flex items-center justify-center gap-2 group"
      >
        <FaPlus className="w-4 h-4 group-hover:scale-110 transition-transform" /> 
        <span>Eğitim Ekle</span>
      </button>
      {items?.length === 0 && (
        <div className="text-center text-grafit-gri py-8">
          <p>Henüz eğitim bilgisi eklenmemiş</p>
          <p className="text-sm mt-1">Eğitim bilgilerinizi eklemek için yukarıdaki butonu kullanın</p>
        </div>
      )}
    </div>
  );
};

export default EducationForm; 