import { useState, useEffect } from 'react';
import { db, storage } from '../../firebase/config';
import { set, ref as dbRef, onValue } from 'firebase/database';
import { ref as storageRef, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaCheck, FaInfoCircle  } from 'react-icons/fa';

function Kolaj() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([null, null, null]);
  const [photoURLs, setPhotoURLs] = useState([null, null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    current: 0,
    total: 0,
    currentFile: 0
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (!user?.uid) return;

    const kolajRef = dbRef(db, `users/${user.uid}/profilecv/kolaj`);
    const unsubscribe = onValue(kolajRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPhotoURLs([data.photo1, data.photo2, data.photo3]);
        setPhotos([data.photo1, data.photo2, data.photo3]);
      }
    });

    return () => unsubscribe();
  }, [user?.uid]);

  const handlePhotoChange = (index) => async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Dosya tipi kontrolü
    if (!file.type.startsWith('image/')) {
      setError('Lütfen sadece resim dosyası yükleyin.');
      return;
    }

    // Dosya boyutu kontrolü (5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('Dosya boyutu 5MB\'dan küçük olmalıdır.');
      return;
    }

    const newPhotos = [...photos];
    newPhotos[index] = file;
    setPhotos(newPhotos);

    const newPhotoURLs = [...photoURLs];
    newPhotoURLs[index] = URL.createObjectURL(file);
    setPhotoURLs(newPhotoURLs);
    setError(null);
  };

  const uploadPhoto = async (file) => {
    const storageReference = storageRef(storage, `photos/${user.uid}/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageReference, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(prev => ({
            ...prev,
            current: progress
          }));
        },
        (error) => {
          console.error('Yükleme başarısız:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadProgress(prev => ({
              ...prev,
              currentFile: prev.currentFile + 1
            }));
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const handleSavePhotos = async () => {
    if (photos.filter(photo => photo).length < 3) {
      setError('Lütfen 3 adet fotoğraf yükleyin.');
      return;
    }

    setIsLoading(true);
    setError(null);
    const newPhotosCount = photos.filter(photo => photo instanceof File).length;
    setUploadProgress({ current: 0, total: newPhotosCount, currentFile: 0 });

    try {
      const updatedPhotoURLs = await Promise.all(
        photos.map(async (photo, index) => {
          if (photo instanceof File) {
            return await uploadPhoto(photo);
          } else if (typeof photo === 'string') {
            return photo;
          } else {
            return photoURLs[index] || null;
          }
        })
      );

      if (updatedPhotoURLs.some(url => !url)) {
        throw new Error('Bazı fotoğraflar yüklenemedi');
      }

      await set(dbRef(db, `users/${user.uid}/profilecv/kolaj`), {
        photo1: updatedPhotoURLs[0],
        photo2: updatedPhotoURLs[1],
        photo3: updatedPhotoURLs[2],
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/profile');
      }, 2000);

    } catch (error) {
      console.error('Hata:', error);
      setError('Fotoğraflar kaydedilirken bir hata oluştu.');
    } finally {
      setIsLoading(false);
      setUploadProgress({ current: 0, total: 0, currentFile: 0 });
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="bg-beyaz border-b border-gray-200">
        <div className="max-w-7xl mx-auto flex items-center justify-between px-6 h-16">
          <div className="flex items-center gap-6">
            <button 
              onClick={() => navigate(-1)}
              className="p-1.5 hover:bg-kirik-beyaz rounded-lg transition-colors"
            >
              <FaArrowLeft className="w-5 h-5 text-yogun-antrasit" />
            </button>
            <h1 className="text-xl font-medium text-yogun-antrasit">
              Kolaj Fotoğrafları
            </h1>
          </div>
          <button 
            onClick={handleSavePhotos} 
            disabled={isLoading || photos.filter(photo => photo).length < 3}
            className={`px-5 py-2 rounded-lg text-sm font-medium transition-all
              ${isLoading || photos.filter(photo => photo).length < 3 
                ? 'text-grafit-gri bg-kirik-beyaz' 
                : 'text-beyaz bg-yogun-antrasit hover:bg-grafit-gri'}`}
          >
            {isLoading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="max-w-5xl mx-auto px-6 py-8">
        {/* Fotoğraf Yükleme Alanları - Ana Kare */}
        <div className="aspect-square max-w-[800px] mx-auto max-h-[500px] bg-beyaz rounded-2xl p-6 border border-gray-200">
          <div className="flex h-full gap-6">
            {/* Sol taraf - Uzun fotoğraf */}
            <div className="w-1/2 h-full">
              <div 
                onClick={() => document.getElementById('photo-upload-0').click()}
                className="relative h-full w-full rounded-xl border-2 border-dashed border-gray-200 
                  overflow-hidden bg-kirik-beyaz hover:border-yogun-antrasit transition-colors cursor-pointer"
              >
                {photoURLs[0] ? (
                  <img
                    src={photoURLs[0]}
                    alt="Boy fotoğrafı"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center h-full text-grafit-gri p-6">
                    <div className="w-14 h-14 rounded-full bg-beyaz flex items-center justify-center mb-3">
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                      </svg>
                    </div>
                    <span className="text-base font-medium">Boy Fotoğrafı Yükle</span>
                    <span className="text-sm mt-1 text-center">veya sürükle bırak</span>
                  </div>
                )}
                <input
                  type="file"
                  id="photo-upload-0"
                  accept="image/*"
                  onChange={handlePhotoChange(0)}
                  className="hidden"
                />
              </div>
            </div>

            {/* Sağ taraf - İki kare fotoğraf */}
            <div className="w-1/2 h-full flex flex-col gap-6">
              {[1, 2].map((index) => (
                <div
                  key={index}
                  onClick={() => document.getElementById(`photo-upload-${index}`).click()}
                  className="relative h-[calc(50%-12px)] w-full rounded-xl border-2 border-dashed border-gray-200 
                    overflow-hidden bg-kirik-beyaz hover:border-yogun-antrasit transition-colors cursor-pointer"
                >
                  {photoURLs[index] ? (
                    <img
                      src={photoURLs[index]}
                      alt={`Portre ${index}`}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full text-grafit-gri p-6">
                      <div className="w-12 h-12 rounded-full bg-beyaz flex items-center justify-center mb-2">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                        </svg>
                      </div>
                      <span className="text-sm font-medium">Portre Fotoğrafı Yükle</span>
                      <span className="text-xs mt-1 text-center">veya sürükle bırak</span>
                    </div>
                  )}
                  <input
                    type="file"
                    id={`photo-upload-${index}`}
                    accept="image/*"
                    onChange={handlePhotoChange(index)}
                    className="hidden"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Hata Mesajı */}
        {error && (
          <div className="max-w-2xl mx-auto mb-6 p-4 bg-kirmizi/10 text-kirmizi rounded-xl text-sm">
            {error}
          </div>
        )}

        {/* Info İkonu */}
        <div className="fixed bottom-6 right-6 z-[60] group">
          <div className="absolute bottom-full right-0 mb-2 opacity-0 group-hover:opacity-100 
            transition-opacity duration-200 ease-in-out">
            <div className="bg-[#292929] text-white px-4 py-2 rounded-xl text-sm font-medium 
              shadow-lg relative min-w-[180px]">
              Nasıl olmalı?
              <div className="absolute -bottom-1 right-6 w-2 h-2 bg-[#292929] rotate-45"></div>
            </div>
          </div>
          <button
            onClick={() => setShowInfo(!showInfo)}
            className="bg-gradient-to-br from-[#292929] to-[#1a1a1a] text-white p-3 rounded-xl 
              shadow-lg hover:shadow-xl hover:-translate-y-0.5 active:translate-y-0 
              transition-all duration-200 ease-in-out hover:from-[#333333] hover:to-[#1f1f1f]"
          >
            <FaInfoCircle size={20} className="filter drop-shadow-md" />
          </button>
        </div>

        {/* Modal'lar için max-width'ler güncellendi */}
        {showInfo && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70] p-6">
            <div className="bg-beyaz rounded-xl w-full max-w-lg p-6 animate-fade-in">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-medium text-yogun-antrasit">
                  Nasıl Fotoğraf Yüklemeliyim?
                </h3>
                <button 
                  onClick={() => setShowInfo(false)}
                  className="text-grafit-gri hover:text-yogun-antrasit transition-colors"
                >
                  ✕
                </button>
              </div>
              <ul className="text-base text-grafit-gri space-y-4">
                <li className="flex items-start gap-3">
                  <span className="text-yogun-antrasit text-lg">•</span>
                  <span>Sol alan: Boydan çekilmiş, net ve kaliteli fotoğrafınız</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="text-yogun-antrasit text-lg">•</span>
                  <span>Sağ alanlar: Yüzünüzün net göründüğü portre fotoğraflarınız</span>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* Yükleme Göstergesi */}
        {isLoading && uploadProgress.total > 0 && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70] p-8">
            <div className="bg-beyaz rounded-2xl w-full max-w-xl p-8">
              <div className="space-y-6">
                <div className="flex flex-col items-center">
                  <FaSpinner className="animate-spin w-12 h-12 text-yogun-antrasit mb-4" />
                  <h3 className="text-xl font-medium text-yogun-antrasit mb-2">
                    Fotoğraflar Yükleniyor
                  </h3>
                  <p className="text-grafit-gri">
                    Lütfen işlem tamamlanana kadar bekleyin...
                  </p>
                </div>
                <div className="space-y-3">
                  <div className="w-full bg-kirik-beyaz rounded-full h-2.5">
                    <div 
                      className="bg-yogun-antrasit h-2.5 rounded-full transition-all duration-300"
                      style={{ width: `${uploadProgress.current}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-sm text-grafit-gri">
                    <span>
                      Fotoğraf {uploadProgress.currentFile + 1}/{uploadProgress.total}
                    </span>
                    <span>
                      %{uploadProgress.current}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Başarı Mesajı */}
        {showSuccess && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70] p-8">
            <div className="bg-beyaz rounded-2xl w-full max-w-xl p-8 text-center animate-fade-scale">
              <div className="w-20 h-20 rounded-full bg-yesil/10 flex items-center justify-center mx-auto mb-6">
                <FaCheck className="w-10 h-10 text-yesil" />
              </div>
              <h3 className="text-2xl font-medium text-yogun-antrasit mb-3">
                Fotoğraflar Başarıyla Kaydedildi
              </h3>
              <p className="text-grafit-gri">
                Profilinize yönlendiriliyorsunuz...
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Kolaj;