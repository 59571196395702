import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/desktop/Header';


function DesktopLayout() {
  return (
    <div className="min-h-screen bg-beyaz flex flex-col">
      <Header />
      <main className="flex-1">
        <div className="">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default DesktopLayout;
