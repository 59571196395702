import React from 'react';
import FormField from '../common/FormField';
import { formConfig } from '../../config/formConfig';

const PersonalInfoForm = ({ formData, handleInputChange, errors }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        {/* Pozisyonlar - Tam genişlik */}
        <div className="col-span-2">
          <FormField
            {...formConfig.personal.fields[0]}
            value={formData.personal.positions}
            onChange={(value) => handleInputChange('personal', 'positions', value)}
            error={errors['personal.positions']}
          />
        </div>

        {/* Doğum Tarihi */}
        <div>
          <FormField
            {...formConfig.personal.fields[1]}
            value={formData.personal.birthDate}
            onChange={(value) => handleInputChange('personal', 'birthDate', value)}
            error={errors['personal.birthDate']}
          />
        </div>

        {/* TC Kimlik No */}
        <div>
          <FormField
            {...formConfig.personal.fields[2]}
            value={formData.personal.tcNo}
            onChange={(value) => handleInputChange('personal', 'tcNo', value)}
            error={errors['personal.tcNo']}
          />
        </div>

        {/* Cinsiyet */}
        <div>
          <FormField
            {...formConfig.personal.fields[3]}
            value={formData.personal.gender}
            onChange={(value) => handleInputChange('personal', 'gender', value)}
            error={errors['personal.gender']}
          />
        </div>

        {/* Şehir */}
        <div>
          <FormField
            {...formConfig.personal.fields[4]}
            value={formData.personal.city}
            onChange={(value) => handleInputChange('personal', 'city', value)}
            error={errors['personal.city']}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoForm; 