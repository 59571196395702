import React from 'react';

const CVPreview = ({ data }) => (
  <div className="bg-beyaz rounded-xl p-6 shadow-lg">
    <div className="prose max-w-none">
      <h2 className="text-2xl font-bold text-yogun-antrasit mb-6">
        {data.personal?.fullName}
      </h2>

      {/* Kişisel Bilgiler */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">Kişisel Bilgiler</h3>
        <div className="space-y-2 text-grafit-gri">
          {data.personal?.positions?.length > 0 && (
            <p>
              <span className="font-medium">Pozisyonlar: </span>
              {data.personal.positions.map(pos => pos.label).join(', ')}
            </p>
          )}
          {data.personal?.birthDate && (
            <p>
              <span className="font-medium">Doğum Tarihi: </span>
              {data.personal.birthDate}
            </p>
          )}
          {data.personal?.tcNo && (
            <p>
              <span className="font-medium">TC Kimlik No: </span>
              {data.personal.tcNo}
            </p>
          )}
          {data.personal?.gender && (
            <p>
              <span className="font-medium">Cinsiyet: </span>
              {data.personal.gender}
            </p>
          )}
          {data.personal?.city && (
            <p>
              <span className="font-medium">Şehir: </span>
              {data.personal.city}
            </p>
          )}
        </div>
      </div>

      {/* Fiziksel Özellikler */}
      {data.physical && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">Fiziksel Özellikler</h3>
          <div className="space-y-2 text-grafit-gri">
            {data.physical.height && (
              <p>
                <span className="font-medium">Boy: </span>
                {data.physical.height} cm
              </p>
            )}
            {data.physical.weight && (
              <p>
                <span className="font-medium">Kilo: </span>
                {data.physical.weight} kg
              </p>
            )}
            {data.physical.bodySize && (
              <p>
                <span className="font-medium">Beden: </span>
                {data.physical.bodySize}
              </p>
            )}
            {data.physical.shoeSize && (
              <p>
                <span className="font-medium">Ayakkabı Numarası: </span>
                {data.physical.shoeSize}
              </p>
            )}
            {data.physical.eyeColor && (
              <p>
                <span className="font-medium">Göz Rengi: </span>
                {data.physical.eyeColor}
              </p>
            )}
            {data.physical.hairColor && (
              <p>
                <span className="font-medium">Saç Rengi: </span>
                {data.physical.hairColor}
              </p>
            )}
          </div>
        </div>
      )}

      {/* Eğitim Bilgileri */}
      {data.education?.length > 0 && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">Eğitim Bilgileri</h3>
          <div className="space-y-4">
            {data.education.map((edu, index) => (
              <div key={index} className="text-grafit-gri">
                <p className="font-medium">{edu.institution}</p>
                <p>{edu.department}</p>
                <p className="text-sm">
                  {edu.startYear} - {edu.endYear || 'Devam Ediyor'}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Yetenekler */}
      {data.skills?.length > 0 && (
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">Yetenekler</h3>
          <div className="flex flex-wrap gap-2">
            {data.skills.map((skill, index) => (
              <span 
                key={index}
                className="px-3 py-1 bg-kirik-beyaz text-grafit-gri rounded-lg text-sm"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default CVPreview; 