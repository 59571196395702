// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Authentication servisini ekle
import { getDatabase } from 'firebase/database';
import { getStorage } from "firebase/storage"; // Storage servisini ekle

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAPllMJQUkYkv4at7WU2SeJ57H-9G-k2PY",
  authDomain: "klaket-c49c6.firebaseapp.com",
  databaseURL: "https://klaket-c49c6-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "klaket-c49c6",
  storageBucket: "klaket-c49c6.appspot.com",
  messagingSenderId: "620385046854",
  appId: "1:620385046854:web:98c481ebc156d3857b5547",
  measurementId: "G-BLG27CDT0E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Authentication'ı initialize et
const db = getDatabase(app);
const storage = getStorage(app); // Storage'ı initialize et

// Export the services to use them elsewhere
export { auth, db, storage };
