import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaArrowLeft, FaPhone, FaEnvelope, FaMapMarkerAlt, FaSpinner } from 'react-icons/fa';

const db = getDatabase();

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

const translateKey = (key) => {
  const translations = {
    'positions': 'Pozisyonlar',
    'birthDate': 'Doğum Tarihi',
    'tcNo': 'TC Kimlik No',
    'gender': 'Cinsiyet',
    'city': 'Şehir',
    'height': 'Boy',
    'weight': 'Kilo',
    'bodySize': 'Beden',
    'shoeSize': 'Ayakkabı Numarası',
    'eyeColor': 'Göz Rengi',
    'hairColor': 'Saç Rengi'
  };
  return translations[key] || key;
};

const formatValue = (key, value) => {
  if (key === 'height') return `${value} cm`;
  if (key === 'weight') return `${value} kg`;
  if (key === 'birthDate') {
    return new Date(value).toLocaleDateString('tr-TR');
  }
  if (key === 'positions') {
    const positionMap = {
      'actor': 'Oyuncu',
      'model': 'Model',
      'supporting_actor': 'Yardımcı Oyuncu'
    };
    return Array.isArray(value) ? value.map(v => positionMap[v] || v).join(', ') : value;
  }
  return value;
};

const PlayerDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const playerRef = ref(db, `users/${id}`);
        const selectedRef = ref(db, `users/${user.uid}/selectedPlayers/${id}`);
        
        const [playerSnapshot, selectedSnapshot] = await Promise.all([
          get(playerRef),
          get(selectedRef)
        ]);
        
        if (playerSnapshot.exists()) {
          setPlayer(playerSnapshot.val());
          setIsSelected(selectedSnapshot.exists());
        }
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [id, user, navigate]);

  const handleSelectPlayer = async () => {
    if (!user || isUpdating) return;

    setIsUpdating(true);
    try {
      const selectedRef = ref(db, `users/${user.uid}/selectedPlayers/${id}`);
      
      if (isSelected) {
        await remove(selectedRef);
        setIsSelected(false);
      } else {
        await update(selectedRef, { id });
        setIsSelected(true);
      }
    } catch (error) {
      console.error("İşlem başarısız:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-grafit-gri border-t-yogun-antrasit"></div>
      </div>
    );
  }

  if (!player) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h2 className="text-2xl font-bold text-yogun-antrasit">Oyuncu bulunamadı</h2>
        <button
          onClick={() => navigate(-1)}
          className="mt-4 px-6 py-2 bg-yogun-antrasit text-beyaz rounded-lg hover:bg-grafit-gri transition-colors"
        >
          Geri Dön
        </button>
      </div>
    );
  }

  const personalInfo = player.profilecv?.globalcv?.personal || {};
  const physicalInfo = player.profilecv?.globalcv?.physical || {};
  const education = player.profilecv?.globalcv?.education || [];
  const skills = player.profilecv?.globalcv?.skills || [];

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-12">
      {/* Header */}
      <div className="bg-beyaz shadow-sm">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <button 
            onClick={() => navigate(-1)}
            className="text-yogun-antrasit hover:text-grafit-gri transition-colors"
          >
            <FaArrowLeft size={24} />
          </button>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="grid grid-cols-12 gap-8">
          {/* Sol Kolon */}
          <div className="col-span-4">
            <div className="bg-beyaz rounded-xl p-6 shadow-sm">
              <div className="aspect-square rounded-xl overflow-hidden mb-6">
                <img
                  src={player.profileImage}
                  alt={player.fullName}
                  className="w-full h-full object-cover"
                />
              </div>

              <h1 className="text-2xl font-bold text-yogun-antrasit mb-2">
                {player.fullName}
              </h1>
              
              <p className="text-grafit-gri mb-6">
                {personalInfo.positions ? formatValue('positions', personalInfo.positions) : 'Pozisyon belirtilmemiş'}
              </p>

              {user?.role === 'admin' && (
                <div className="space-y-3 mb-6">
                  {player.phone && (
                    <div className="flex items-center text-grafit-gri">
                      <FaPhone className="mr-3" />
                      <a href={`tel:${player.phone}`} className="hover:text-yogun-antrasit transition-colors">
                        {formatPhoneNumber(player.phone)}
                      </a>
                    </div>
                  )}
                  {player.email && (
                    <div className="flex items-center text-grafit-gri">
                      <FaEnvelope className="mr-3" />
                      <a href={`mailto:${player.email}`} className="hover:text-yogun-antrasit transition-colors">
                        {player.email}
                      </a>
                    </div>
                  )}
                  {personalInfo.city && (
                    <div className="flex items-center text-grafit-gri">
                      <FaMapMarkerAlt className="mr-3" />
                      <span>{personalInfo.city}</span>
                    </div>
                  )}
                </div>
              )}

              {(user?.role === 'production' || user?.role === 'admin') && (
                <button
                  onClick={handleSelectPlayer}
                  disabled={isUpdating}
                  className={`w-full py-3 rounded-xl transition-colors ${
                    isSelected 
                      ? 'bg-yogun-antrasit text-beyaz hover:bg-grafit-gri' 
                      : 'bg-kirik-beyaz text-yogun-antrasit hover:bg-grafit-gri/20'
                  }`}
                >
                  {isUpdating ? 'İşleniyor...' : (isSelected ? 'Seçimi Kaldır' : 'Oyuncuyu Seç')}
                </button>
              )}
            </div>
          </div>

          {/* Sağ Kolon */}
          <div className="col-span-8 space-y-8">
            {/* Kişisel Bilgiler */}
            <div className="bg-beyaz rounded-xl p-6 shadow-sm">
              <h2 className="text-xl font-bold text-yogun-antrasit mb-6">Kişisel Bilgiler</h2>
              <div className="grid grid-cols-3 gap-6">
                {Object.entries(personalInfo).map(([key, value]) => {
                  if (['fullName', 'email', 'phone'].includes(key)) return null;
                  
                  return (
                    <div key={key}>
                      <p className="text-grafit-gri text-sm mb-1">{translateKey(key)}</p>
                      <p className="text-yogun-antrasit font-medium">
                        {formatValue(key, value) || 'Belirtilmemiş'}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Fiziksel Özellikler */}
            <div className="bg-beyaz rounded-xl p-6 shadow-sm">
              <h2 className="text-xl font-bold text-yogun-antrasit mb-6">Fiziksel Özellikler</h2>
              <div className="grid grid-cols-3 gap-6">
                {Object.entries(physicalInfo).map(([key, value]) => (
                  <div key={key}>
                    <p className="text-grafit-gri text-sm mb-1">{translateKey(key)}</p>
                    <p className="text-yogun-antrasit font-medium">
                      {formatValue(key, value) || 'Belirtilmemiş'}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Eğitim */}
            {education.length > 0 && (
              <div className="bg-beyaz rounded-xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">Eğitim Bilgileri</h2>
                <div className="space-y-4">
                  {education.map((edu, index) => (
                    <div key={index} className="p-4 bg-kirik-beyaz rounded-xl">
                      <h3 className="font-medium text-yogun-antrasit">{edu.institution}</h3>
                      <p className="text-grafit-gri mt-1">{edu.department}</p>
                      <p className="text-sm text-grafit-gri mt-1">
                        {edu.startYear} - {edu.endYear || 'Devam Ediyor'}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Yetenekler */}
            {skills.length > 0 && (
              <div className="bg-beyaz rounded-xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">Yetenekler</h2>
                <div className="flex flex-wrap gap-2">
                  {skills.map((skill, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-kirik-beyaz text-grafit-gri rounded-lg text-sm"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Fotoğraflar */}
            {player.profilecv?.kolaj && (
              <div className="bg-beyaz rounded-xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">Fotoğraflar</h2>
                <div className="grid grid-cols-2 gap-6">
                  {/* Sol taraf - Uzun fotoğraf */}
                  {player.profilecv.kolaj.photo1 && (
                    <div className="h-[500px] rounded-xl overflow-hidden">
                      <img
                        src={player.profilecv.kolaj.photo1}
                        alt="Fotoğraf 1"
                        className="w-full h-full object-cover hover:scale-105 transition-transform cursor-pointer"
                      />
                    </div>
                  )}

                  {/* Sağ taraf - Üst ve alt fotoğraflar */}
                  <div className="flex flex-col gap-6">
                    {player.profilecv.kolaj.photo2 && (
                      <div className="h-[242px] rounded-xl overflow-hidden">
                        <img
                          src={player.profilecv.kolaj.photo2}
                          alt="Fotoğraf 2"
                          className="w-full h-full object-cover hover:scale-105 transition-transform cursor-pointer"
                        />
                      </div>
                    )}
                    
                    {player.profilecv.kolaj.photo3 && (
                      <div className="h-[242px] rounded-xl overflow-hidden">
                        <img
                          src={player.profilecv.kolaj.photo3}
                          alt="Fotoğraf 3"
                          className="w-full h-full object-cover hover:scale-105 transition-transform cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Video */}
            {player.profilecv?.introVideo && (
              <div className="bg-beyaz rounded-xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">Tanıtım Videosu</h2>
                <div className="aspect-video rounded-xl overflow-hidden">
                  <video
                    className="w-full h-full object-cover"
                    controls
                  >
                    <source src={player.profilecv.introVideo} type="video/mp4" />
                    Tarayıcınız video etiketini desteklemiyor.
                  </video>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetailPage;
