import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { ref, onValue, remove, update } from 'firebase/database';
import { HiOutlinePencil, HiOutlineTrash, HiOutlinePlus, HiChevronRight, HiOutlineSearch } from 'react-icons/hi';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const ProjectManage = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    const projectsRef = ref(db, 'projects');
    const unsubscribe = onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const projectsList = Object.entries(data).map(([id, project]) => ({
          id,
          ...project
        }));
        setProjects(projectsList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      } else {
        setProjects([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleStatusToggle = async (project) => {
    try {
      const projectRef = ref(db, `projects/${project.id}`);
      await update(projectRef, {
        status: project.status === 'active' ? 'inactive' : 'active',
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Durum güncelleme hatası:', error);
      alert('Durum güncellenirken bir hata oluştu');
    }
  };

  const handleDelete = async () => {
    if (!selectedProject) return;

    try {
      const projectRef = ref(db, `projects/${selectedProject.id}`);
      await remove(projectRef);
      setShowDeleteModal(false);
      setSelectedProject(null);
    } catch (error) {
      console.error('Proje silme hatası:', error);
      alert('Proje silinirken bir hata oluştu');
    }
  };

  const filteredProjects = projects.filter(project => {
    const matchesSearch = project.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || project.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'Tarih belirtilmemiş';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Geçersiz tarih';
      return format(date, 'd MMMM yyyy', { locale: tr });
    } catch (error) {
      return 'Tarih formatlanamadı';
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="bg-beyaz border-b border-kirik-beyaz">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex items-center justify-between h-20">
            <h1 className="text-2xl font-semibold text-yogun-antrasit">
              Projeler
            </h1>
            <button
              onClick={() => navigate('/admin/projects/add')}
              className="flex items-center gap-2 px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl font-medium hover:bg-black transition-colors"
            >
              <HiOutlinePlus className="w-5 h-5" />
              Yeni Proje
            </button>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="bg-beyaz border-b border-kirik-beyaz">
        <div className="max-w-7xl mx-auto px-8 py-4">
          <div className="flex items-center justify-between gap-8">
            <div className="flex-1 relative">
              <HiOutlineSearch className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-grafit-gri" />
              <input
                type="text"
                placeholder="Proje ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-12 pr-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
              />
            </div>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
            >
              <option value="all">Tüm Projeler</option>
              <option value="active">Aktif</option>
              <option value="inactive">Pasif</option>
            </select>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-7xl mx-auto px-8 py-8">
        {loading ? (
          <div className="flex items-center justify-center py-12">
            <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
          </div>
        ) : (
          <div className="bg-beyaz rounded-2xl overflow-hidden">
            {filteredProjects.length > 0 ? (
              <table className="w-full">
                <thead>
                  <tr className="border-b border-kirik-beyaz">
                    <th className="text-left px-6 py-4 text-sm font-medium text-grafit-gri">Proje Adı</th>
                    <th className="text-left px-6 py-4 text-sm font-medium text-grafit-gri">Tarih</th>
                    <th className="text-left px-6 py-4 text-sm font-medium text-grafit-gri">Durum</th>
                    <th className="text-left px-6 py-4 text-sm font-medium text-grafit-gri">Bütçe</th>
                    <th className="px-6 py-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProjects.map(project => (
                    <tr 
                      key={project.id} 
                      className="border-b border-kirik-beyaz hover:bg-kirik-beyaz/50 transition-colors"
                    >
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-4">
                          {project.images?.[0] && (
                            <img 
                              src={project.images[0]} 
                              alt={project.title}
                              className="w-12 h-12 rounded-lg object-cover"
                            />
                          )}
                          <div>
                            <h3 className="font-medium text-yogun-antrasit">
                              {project.title}
                            </h3>
                            <p className="text-sm text-grafit-gri">
                              {project.projectType}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-grafit-gri">
                        {formatDate(project.date)}
                      </td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => handleStatusToggle(project)}
                          className={`px-3 py-1.5 rounded-lg text-sm font-medium ${
                            project.status === 'active'
                              ? 'bg-yesil/10 text-yesil'
                              : 'bg-grafit-gri/10 text-grafit-gri'
                          }`}
                        >
                          {project.status === 'active' ? 'Aktif' : 'Pasif'}
                        </button>
                      </td>
                      <td className="px-6 py-4 text-grafit-gri">
                        {project.budget || '-'}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center justify-end gap-2">
                          <button
                            onClick={() => navigate(`/admin/projects/edit/${project.id}`)}
                            className="p-2 text-grafit-gri hover:text-yogun-antrasit rounded-lg"
                          >
                            <HiOutlinePencil className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedProject(project);
                              setShowDeleteModal(true);
                            }}
                            className="p-2 text-grafit-gri hover:text-kirmizi rounded-lg"
                          >
                            <HiOutlineTrash className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => navigate(`/admin/projects/${project.id}`)}
                            className="p-2 text-grafit-gri hover:text-yogun-antrasit rounded-lg"
                          >
                            <HiChevronRight className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-12">
                <p className="text-grafit-gri mb-4">
                  {searchTerm || filterStatus !== 'all' 
                    ? 'Arama kriterlerine uygun proje bulunamadı' 
                    : 'Henüz proje eklenmemiş'}
                </p>
                <button
                  onClick={() => navigate('/admin/projects/add')}
                  className="px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl text-sm font-medium hover:bg-black transition-colors"
                >
                  İlk Projeyi Ekle
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-2xl p-8 max-w-md w-full mx-4 animate-scale-up">
            <h3 className="text-xl font-semibold text-yogun-antrasit mb-2">
              Projeyi Sil
            </h3>
            <p className="text-grafit-gri mb-6">
              "{selectedProject?.title}" projesini silmek istediğinizden emin misiniz?
            </p>
            <div className="flex gap-3">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setSelectedProject(null);
                }}
                className="flex-1 py-3 border border-kirik-beyaz rounded-xl font-medium text-yogun-antrasit hover:bg-kirik-beyaz transition-colors"
              >
                Vazgeç
              </button>
              <button
                onClick={handleDelete}
                className="flex-1 py-3 bg-kirmizi text-beyaz rounded-xl font-medium hover:bg-kirmizi/90 transition-colors"
              >
                Sil
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectManage;
