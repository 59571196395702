import React from 'react';

const SkillsForm = ({ skills, selected, onToggle }) => {
  return (
    <div className="grid grid-cols-3 gap-3">
      {skills.map(skill => (
        <button
          key={skill}
          onClick={() => onToggle(skill)}
          className={`py-3 px-4 rounded-xl text-sm font-medium transition-all
            ${selected.includes(skill)
              ? 'bg-yogun-antrasit text-beyaz'
              : 'bg-kirik-beyaz text-grafit-gri hover:bg-[#E5E5E5]'}`}
        >
          {skill}
        </button>
      ))}
    </div>
  );
};

export default SkillsForm; 