import React, { useState } from 'react';

const FilterModal = ({ isOpen, onClose, onApplyFilters }) => {
  const [filters, setFilters] = useState({
    ageRange: { min: '', max: '' },
    gender: '',
    hairColor: '',
    height: { min: '', max: '' },
    city: '',
  });

  const handleApplyFilters = () => {
    onApplyFilters(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-yogun-antrasit bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-kirik-beyaz rounded-2xl p-6 max-w-md w-full mx-4 shadow-lg border border-grafit-gri transform transition-all duration-200 ease-in-out"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex flex-col">
          <h3 className="text-lg font-medium text-yogun-antrasit mb-4">
            Filtreleme Seçenekleri
          </h3>

          {/* Yaş Aralığı */}
          <div className="mb-4">
            <label className="text-sm text-grafit-gri mb-2 block">Yaş Aralığı</label>
            <div className="flex gap-2">
              <input
                type="number"
                placeholder="Min"
                className="w-1/2 p-2 rounded-lg border border-grafit-gri bg-beyaz"
                value={filters.ageRange.min}
                onChange={(e) => setFilters({
                  ...filters,
                  ageRange: { ...filters.ageRange, min: e.target.value }
                })}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-1/2 p-2 rounded-lg border border-grafit-gri bg-beyaz"
                value={filters.ageRange.max}
                onChange={(e) => setFilters({
                  ...filters,
                  ageRange: { ...filters.ageRange, max: e.target.value }
                })}
              />
            </div>
          </div>

          {/* Cinsiyet */}
          <div className="mb-4">
            <label className="text-sm text-grafit-gri mb-2 block">Cinsiyet</label>
            <select
              className="w-full p-2 rounded-lg border border-grafit-gri bg-beyaz"
              value={filters.gender}
              onChange={(e) => setFilters({ ...filters, gender: e.target.value })}
            >
              <option value="">Tümü</option>
              <option value="male">Erkek</option>
              <option value="female">Kadın</option>
            </select>
          </div>

          {/* Saç Rengi */}
          <div className="mb-4">
            <label className="text-sm text-grafit-gri mb-2 block">Saç Rengi</label>
            <select
              className="w-full p-2 rounded-lg border border-grafit-gri bg-beyaz"
              value={filters.hairColor}
              onChange={(e) => setFilters({ ...filters, hairColor: e.target.value })}
            >
              <option value="">Tümü</option>
              <option value="black">Siyah</option>
              <option value="brown">Kahverengi</option>
              <option value="blonde">Sarı</option>
              <option value="red">Kızıl</option>
              <option value="white">Beyaz</option>
            </select>
          </div>

          {/* Boy Aralığı */}
          <div className="mb-4">
            <label className="text-sm text-grafit-gri mb-2 block">Boy Aralığı (cm)</label>
            <div className="flex gap-2">
              <input
                type="number"
                placeholder="Min"
                className="w-1/2 p-2 rounded-lg border border-grafit-gri bg-beyaz"
                value={filters.height.min}
                onChange={(e) => setFilters({
                  ...filters,
                  height: { ...filters.height, min: e.target.value }
                })}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-1/2 p-2 rounded-lg border border-grafit-gri bg-beyaz"
                value={filters.height.max}
                onChange={(e) => setFilters({
                  ...filters,
                  height: { ...filters.height, max: e.target.value }
                })}
              />
            </div>
          </div>

          {/* Şehir */}
          <div className="mb-6">
            <label className="text-sm text-grafit-gri mb-2 block">Şehir</label>
            <input
              type="text"
              placeholder="Şehir giriniz"
              className="w-full p-2 rounded-lg border border-grafit-gri bg-beyaz"
              value={filters.city}
              onChange={(e) => setFilters({ ...filters, city: e.target.value })}
            />
          </div>

          {/* Butonlar */}
          <div className="flex gap-3">
            <button
              onClick={onClose}
              className="w-1/2 py-2.5 rounded-xl border border-grafit-gri text-yogun-antrasit"
            >
              İptal
            </button>
            <button
              onClick={handleApplyFilters}
              className="w-1/2 py-2.5 rounded-xl bg-yogun-antrasit text-kirik-beyaz hover:bg-grafit-gri transition-colors"
            >
              Uygula
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
