import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaRegClock, 
  FaMapMarkerAlt, 
  FaChevronRight, 
  FaSearch,
  FaFilter,
  FaFilm,
  FaTv,
  FaTheaterMasks,
  FaPhotoVideo,
  FaRegCalendarAlt,
  FaUserFriends
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('personal');
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}/profilecv/globalcv`);
        try {
          const userSnapshot = await get(userRef);
          if (userSnapshot.exists()) {
            setUserProfile(userSnapshot.val());
          }
        } catch (error) {
          console.error("Kullanıcı profili yüklenirken hata oluştu:", error);
        }
      }
    };
    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    const projectsRef = ref(db, 'projects');
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      const loadedProjects = [];
      for (let id in data) {
        loadedProjects.push({ id, ...data[id] });
      }
      setProjects(loadedProjects);
      setLoading(false);
    });
  }, []);

  const isProjectSuitableForUser = (project, userProfile) => {
    if (!project.requirements || !userProfile) return true;
    const { requirements } = project;

    if (userProfile.birthDate) {
      const birthDate = new Date(userProfile.birthDate);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18 && !requirements.childActor) return false;
      if (age >= 18 && requirements.childActor) return false;
    }

    const checks = {
      height: () => {
        if (!requirements.height || !userProfile.height) return true;
        const minHeight = parseInt(requirements.height) - 5;
        const maxHeight = parseInt(requirements.height) + 5;
        return parseInt(userProfile.height) >= minHeight && parseInt(userProfile.height) <= maxHeight;
      },
      weight: () => {
        if (!requirements.weight || !userProfile.weight) return true;
        const minWeight = parseInt(requirements.weight) - 5;
        const maxWeight = parseInt(requirements.weight) + 5;
        return parseInt(userProfile.weight) >= minWeight && parseInt(userProfile.weight) <= maxWeight;
      },
      hairColor: () => !requirements.hairColor || requirements.hairColor === userProfile.hairColor,
      eyeColor: () => !requirements.eyeColor || requirements.eyeColor === userProfile.eyeColor,
      shoeSize: () => !requirements.shoeSize || requirements.shoeSize === userProfile.shoeSize
    };

    return Object.values(checks).every(check => check());
  };

  useEffect(() => {
    if (user && userProfile) {
      const filterProjects = {
        personal: () => projects.filter(project => 
          project.userId === user.uid || isProjectSuitableForUser(project, userProfile)
        ),
        other: () => projects.filter(project => 
          project.userId !== user.uid && !isProjectSuitableForUser(project, userProfile)
        ),
        concluded: () => projects.filter(project => project.status === 'concluded')
      };

      setFilteredProjects(filterProjects[filter]?.() || []);
    }
  }, [filter, projects, user, userProfile]);

  const getProjectTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'film':
        return <FaFilm className="w-5 h-5" />;
      case 'dizi':
        return <FaTv className="w-5 h-5" />;
      case 'tiyatro':
        return <FaTheaterMasks className="w-5 h-5" />;
      case 'reklam':
        return <FaPhotoVideo className="w-5 h-5" />;
      default:
        return <FaFilm className="w-5 h-5" />;
    }
  };

  const getProjectStatus = (project) => {
    const today = new Date();
    const projectDate = new Date(project.date);
    
    if (project.status === 'concluded') {
      return { text: 'Tamamlandı', color: 'text-yesil bg-yesil/10' };
    } else if (projectDate < today) {
      return { text: 'Süresi Doldu', color: 'text-kirmizi bg-kirmizi/10' };
    } else {
      return { text: 'Aktif', color: 'text-mavi bg-mavi/10' };
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-20">
      {/* Header */}
      <div className="sticky top-0 bg-beyaz z-10 shadow-sm">
        <div className="flex items-center justify-between p-4">
          <h1 className="text-xl font-semibold text-yogun-antrasit">Projeler</h1>
          <div className="flex items-center gap-3">
            <button className="p-2 hover:bg-kirik-beyaz rounded-xl transition-colors">
              <FaSearch className="w-5 h-5 text-grafit-gri" />
            </button>
            <button className="p-2 hover:bg-kirik-beyaz rounded-xl transition-colors">
              <FaFilter className="w-5 h-5 text-grafit-gri" />
            </button>
          </div>
        </div>

        {/* Filtreler */}
        <div className="flex gap-2 p-2 overflow-x-auto">
          {[
            { id: 'personal', label: 'Projelerim', icon: FaUserFriends },
            { id: 'other', label: 'Diğer Projeler', icon: FaFilm },
            { id: 'concluded', label: 'Tamamlanan', icon: FaRegCalendarAlt }
          ].map((tab) => (
            <button
              key={tab.id}
              onClick={() => setFilter(tab.id)}
              className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm whitespace-nowrap transition-colors ${
                filter === tab.id 
                  ? 'bg-yogun-antrasit text-beyaz' 
                  : 'bg-beyaz text-grafit-gri border border-kirik-beyaz'
              }`}
            >
              <tab.icon className="w-4 h-4" />
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      {/* Proje Listesi */}
      <div className="px-4 py-4 space-y-3">
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
          </div>
        ) : filteredProjects.length > 0 ? (
          filteredProjects.map((project) => (
            <div
              key={project.id}
              onClick={() => navigate(`/projects/${project.id}`)}
              className="bg-beyaz rounded-xl p-4 shadow-sm active:scale-98 transition-transform"
            >
              <div className="flex items-start gap-4">
                <div className="shrink-0 w-16 h-16 bg-kirik-beyaz rounded-xl overflow-hidden">
                  {project.images ? (
                    <img
                      src={project.images[0]}
                      alt={project.title}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-kirik-beyaz">
                      {getProjectTypeIcon(project.projectType)}
                    </div>
                  )}
                </div>

                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between gap-2 mb-1">
                    <h3 className="font-medium text-yogun-antrasit truncate">
                      {project.title}
                    </h3>
                    <span className={`shrink-0 text-xs px-2 py-1 rounded-full ${getProjectStatus(project).color}`}>
                      {getProjectStatus(project).text}
                    </span>
                  </div>

                  <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-grafit-gri mt-2">
                    {project.projectType && (
                      <div className="flex items-center gap-1">
                        {getProjectTypeIcon(project.projectType)}
                        <span>{project.projectType}</span>
                      </div>
                    )}
                    {project.date && (
                      <div className="flex items-center gap-1">
                        <FaRegClock className="w-4 h-4" />
                        <span>{format(new Date(project.date), 'd MMM yyyy', { locale: tr })}</span>
                      </div>
                    )}
                    {project.city && project.location && (
                      <div className="flex items-center gap-1">
                        <FaMapMarkerAlt className="w-4 h-4" />
                        <span>{project.city}, {project.location}</span>
                      </div>
                    )}
                  </div>
                </div>

                <FaChevronRight className="shrink-0 w-5 h-5 text-grafit-gri" />
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center py-12">
            <FaFilm className="w-12 h-12 text-grafit-gri mb-4" />
            <p className="text-grafit-gri text-center">
              {filter === 'personal' 
                ? 'Henüz projeniz bulunmuyor.'
                : filter === 'concluded'
                  ? 'Tamamlanan proje bulunmuyor.'
                  : 'Uygun proje bulunmuyor.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsPage;
