import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  MdList, 
  MdCampaign, 
  MdGroup, 
  MdAccountCircle,
  MdOutlineList,
  MdOutlineCampaign,
  MdOutlineGroup,
  MdOutlineAccountCircle,
} from 'react-icons/md';

const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    {
      label: 'Projeler',
      path: '/projects',
      icon: MdOutlineList,
      activeIcon: MdList
    },
    {
      label: 'Duyurular',
      path: '/announcements',
      icon: MdOutlineCampaign,
      activeIcon: MdCampaign
    },
    {
      label: 'Oyuncular',
      path: '/players',
      icon: MdOutlineGroup,
      activeIcon: MdGroup
    },
    {
      label: 'Profil',
      path: '/profile',
      icon: MdOutlineAccountCircle,
      activeIcon: MdAccountCircle
    }
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-beyaz border-t border-gray-200 shadow-lg pb-safe">
      <div className="flex justify-around items-center h-16">
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          const Icon = isActive ? item.activeIcon : item.icon;

          return (
            <button
              key={item.path}
              onClick={() => navigate(item.path)}
              className={`flex flex-col items-center justify-center w-full h-full space-y-1
                ${isActive 
                  ? 'text-yogun-antrasit' 
                  : 'text-grafit-gri hover:text-yogun-antrasit transition-colors'}`}
            >
              <Icon className="w-6 h-6" />
              <span className="text-xs font-medium">{item.label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default BottomNavbar;
