import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaSpinner } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { ref, set, get } from 'firebase/database';

import EducationForm from '../../components/cv/EducationForm';
import FormField from '../../components/common/FormField';
import { formConfig } from '../../config/formConfig';

function CvForm() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('personal');
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [currentEducation, setCurrentEducation] = useState({});
  const [formData, setFormData] = useState({
    personal: {},
    physical: {},
    education: [],
    skills: []
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loadData = async () => {
      try {
        const cvRef = ref(db, `cvs/${user.uid}`);
        const snapshot = await get(cvRef);
        if (snapshot.exists()) {
          setFormData(snapshot.val());
        }
      } catch (err) {
        setError('CV yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      loadData();
    }
  }, [user]);

  const handleInputChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
    // Hata varsa temizle
    if (errors[`${section}.${field}`]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[`${section}.${field}`];
        return newErrors;
      });
    }
  };

  const handleEducationDelete = (index) => {
    setFormData(prev => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index)
    }));
  };

  const handleEducationAdd = () => {
    if (!currentEducation.institution || !currentEducation.department) {
      setError('Lütfen gerekli alanları doldurun');
      return;
    }

    setFormData(prev => ({
      ...prev,
      education: [...prev.education, currentEducation]
    }));
    setCurrentEducation({});
    setShowEducationModal(false);
  };

  const handleSkillToggle = (skill) => {
    setFormData(prev => ({
      ...prev,
      skills: prev.skills.includes(skill)
        ? prev.skills.filter(s => s !== skill)
        : [...prev.skills, skill]
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Kişisel bilgiler validasyonu
    formConfig.personal.fields.forEach(field => {
      if (field.required && !formData.personal[field.name]) {
        newErrors[`personal.${field.name}`] = 'Bu alan zorunludur';
      }
    });

    // Fiziksel özellikler validasyonu
    formConfig.physical.fields.forEach(field => {
      if (field.required && !formData.physical[field.name]) {
        newErrors[`physical.${field.name}`] = 'Bu alan zorunludur';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      setError('Lütfen tüm zorunlu alanları doldurun');
      return;
    }

    setSaving(true);
    setError(null);

    try {
      const cvRef = ref(db, `cvs/${user.uid}`);
      await set(cvRef, formData);
      navigate('/profile');
    } catch (err) {
      setError('CV kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-white flex items-center justify-center">
        <FaSpinner className="w-8 h-8 text-yogun-antrasit animate-spin" />
      </div>
    );
  }

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white">
      {/* Header */}
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
          <FaChevronLeft className="w-6 h-6" />
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">
          CV Oluştur
        </h2>
        <div className="flex w-12 items-center justify-end">
          <button 
            onClick={handleSave}
            disabled={saving}
            className="text-[#141414] text-base font-bold leading-normal tracking-[0.015em] 
              shrink-0 hover:text-[#666666] transition-colors disabled:opacity-50"
          >
            {saving ? <FaSpinner className="w-4 h-4 animate-spin" /> : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Tab Bar */}
      <div className="flex px-4 space-x-4 overflow-x-auto pb-3 scrollbar-hide">
        {Object.entries(formConfig).map(([key, config]) => (
          <button
            key={key}
            onClick={() => setActiveSection(key)}
            className={`px-4 py-2 rounded-lg whitespace-nowrap transition-colors
              ${activeSection === key
                ? 'bg-yogun-antrasit text-beyaz'
                : 'text-grafit-gri hover:text-yogun-antrasit bg-kirik-beyaz hover:bg-[#E5E5E5]'}`}
          >
            {config.title}
          </button>
        ))}
      </div>

      {/* Form Content */}
      <div className="p-4 pb-20">
        {activeSection === 'personal' && (
          <div className="space-y-6">
            {formConfig.personal.fields.map(field => (
              <FormField
                key={field.name}
                {...field}
                value={formData.personal[field.name]}
                onChange={(value) => handleInputChange('personal', field.name, value)}
                error={errors[`personal.${field.name}`]}
              />
            ))}
          </div>
        )}

        {activeSection === 'physical' && (
          <div className="space-y-6">
            {formConfig.physical.fields.map(field => (
              <FormField
                key={field.name}
                {...field}
                value={formData.physical[field.name]}
                onChange={(value) => handleInputChange('physical', field.name, value)}
                error={errors[`physical.${field.name}`]}
              />
            ))}
          </div>
        )}

        {activeSection === 'education' && (
          <EducationForm
            items={formData.education}
            onAdd={() => setShowEducationModal(true)}
            onDelete={handleEducationDelete}
          />
        )}

        {activeSection === 'skills' && (
          <div className="grid grid-cols-2 gap-3">
            {formConfig.skills.options.map(skill => (
              <button
                key={skill}
                onClick={() => handleSkillToggle(skill)}
                className={`py-3 px-4 rounded-xl text-sm font-medium transition-all
                  ${formData.skills.includes(skill)
                    ? 'bg-yogun-antrasit text-beyaz'
                    : 'bg-kirik-beyaz text-grafit-gri hover:bg-[#E5E5E5]'}`}
              >
                {skill}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Error mesajı gösterimi */}
      {error && (
        <div className="fixed bottom-4 left-4 right-4 bg-kirmizi/10 text-kirmizi px-4 py-3 rounded-xl">
          {error}
        </div>
      )}
    </div>
  );
}

export default CvForm; 