import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { 
  FaMapMarkerAlt, 
  FaClock, 
  FaUsers, 
  FaCalendarAlt, 
  FaFilm,
  FaTv,
  FaTheaterMasks,
  FaPhotoVideo, 
  FaPalette, 
  FaEye, 
  FaRulerVertical, 
  FaWeight, 
  FaShoePrints, 
  FaMale, 
  FaFemale, 
  FaChild,
  FaArrowLeft,
  FaMoneyBillWave,
  FaRegClock,
  FaInfoCircle,
  FaCheckCircle,
  FaTimesCircle
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const ProjectDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const projectRef = ref(db, `projects/${id}`);
        const snapshot = await get(projectRef);
        if (snapshot.exists()) {
          setProject({ id, ...snapshot.val() });
        }
      } catch (error) {
        console.error("Veri alınırken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-beyaz">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-yogun-antrasit"></div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="flex justify-center items-center h-screen bg-beyaz text-grafit-gri">
        Proje bulunamadı.
      </div>
    );
  }

  const getProjectTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'film':
        return <FaFilm className="w-6 h-6" />;
      case 'dizi':
        return <FaTv className="w-6 h-6" />;
      case 'tiyatro':
        return <FaTheaterMasks className="w-6 h-6" />;
      case 'reklam':
        return <FaPhotoVideo className="w-6 h-6" />;
      default:
        return <FaFilm className="w-6 h-6" />;
    }
  };

  const StatusBadge = ({ status, date }) => {
    const today = new Date();
    const projectDate = new Date(date);
    
    if (status === 'concluded') {
      return (
        <div className="flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-yesil/10 text-yesil">
          <FaCheckCircle className="w-4 h-4" />
          <span>Tamamlandı</span>
        </div>
      );
    } else if (projectDate < today) {
      return (
        <div className="flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-kirmizi/10 text-kirmizi">
          <FaTimesCircle className="w-4 h-4" />
          <span>Süresi Doldu</span>
        </div>
      );
    }
    return (
      <div className="flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-mavi/10 text-mavi">
        <FaRegClock className="w-4 h-4" />
        <span>Aktif</span>
      </div>
    );
  };

  const RequirementCard = ({ icon: Icon, label, value, unit }) => (
    <div className="bg-kirik-beyaz p-3 rounded-xl">
      <div className="flex items-center gap-2">
        <Icon className="w-4 h-4 text-yogun-antrasit" />
        <span className="text-grafit-gri text-sm">
          {label}: {value}{unit}
        </span>
      </div>
    </div>
  );

  const InfoCard = ({ icon: Icon, title, content }) => (
    <div className="bg-kirik-beyaz p-4 rounded-xl">
      <div className="flex items-center gap-2 text-grafit-gri mb-1">
        <Icon className="w-4 h-4" />
        <span className="text-sm">{title}</span>
      </div>
      <p className="text-yogun-antrasit font-medium">
        {content}
      </p>
    </div>
  );

  const PeopleNeededCard = ({ icon: Icon, count, label }) => (
    <div className="flex items-center gap-2 bg-kirik-beyaz px-4 py-2 rounded-xl">
      <Icon className="w-5 h-5 text-yogun-antrasit" />
      <span className="text-grafit-gri">{count} {label}</span>
    </div>
  );

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-24">
      {/* Header */}
      <div className="sticky top-0 bg-beyaz z-10 shadow-sm">
        <div className="flex items-center p-4">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 hover:bg-kirik-beyaz rounded-full transition-colors"
          >
            <FaArrowLeft className="w-5 h-5 text-yogun-antrasit" />
          </button>
          <h1 className="text-lg font-semibold text-yogun-antrasit flex-1 text-center pr-8">
            Proje Detayı
          </h1>
        </div>
      </div>

      {/* Proje Görseli */}
      <div className="relative">
        <img 
          src={project.images[0] || 'https://via.placeholder.com/400x225'} 
          alt={project.title} 
          className="w-full aspect-video object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <div className="flex items-center gap-2 mb-2">
            {getProjectTypeIcon(project.projectType)}
            <span className="text-beyaz text-sm">{project.projectType}</span>
          </div>
          <h2 className="text-2xl font-bold text-beyaz mb-2">
            {project.title}
          </h2>
          <StatusBadge status={project.status} date={project.date} />
        </div>
      </div>

      {/* Proje Bilgileri */}
      <div className="bg-beyaz rounded-t-3xl -mt-4 relative z-10">
        <div className="p-4 space-y-6">
          {/* Temel Bilgiler */}
          <div className="grid grid-cols-2 gap-3">
            <InfoCard 
              icon={FaCalendarAlt}
              title="Tarih"
              content={format(new Date(project.date), 'd MMM yyyy', { locale: tr })}
            />
            
            <InfoCard 
              icon={FaMapMarkerAlt}
              title="Konum"
              content={`${project.city}, ${project.location}`}
            />

            {project.payment && (
              <InfoCard 
                icon={FaMoneyBillWave}
                title="Ödeme"
                content={project.payment}
              />
            )}

            {(project.startTime && project.endTime) && (
              <InfoCard 
                icon={FaClock}
                title="Saat"
                content={`${project.startTime} - ${project.endTime}`}
              />
            )}
          </div>

          {/* İhtiyaç */}
          {project.peopleNeeded && (
            <div>
              <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">
                İhtiyaç
              </h3>
              <div className="flex flex-wrap gap-2">
                {project.peopleNeeded.men > 0 && (
                  <PeopleNeededCard 
                    icon={FaMale}
                    count={project.peopleNeeded.men}
                    label="Erkek"
                  />
                )}
                {project.peopleNeeded.women > 0 && (
                  <PeopleNeededCard 
                    icon={FaFemale}
                    count={project.peopleNeeded.women}
                    label="Kadın"
                  />
                )}
                {project.peopleNeeded.children > 0 && (
                  <PeopleNeededCard 
                    icon={FaChild}
                    count={project.peopleNeeded.children}
                    label="Çocuk"
                  />
                )}
              </div>
            </div>
          )}

          {/* Açıklama */}
          <div>
            <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">
              Açıklama
            </h3>
            <p className="text-grafit-gri whitespace-pre-wrap">
              {project.description}
            </p>
          </div>

          {/* Gereksinimler */}
          {project.requirements && (
            <div>
              <h3 className="text-lg font-semibold text-yogun-antrasit mb-3">
                Gereksinimler
              </h3>
              <div className="grid grid-cols-2 gap-3">
                {project.requirements.hairColor && (
                  <RequirementCard 
                    icon={FaPalette}
                    label="Saç Rengi"
                    value={project.requirements.hairColor}
                  />
                )}
                {project.requirements.eyeColor && (
                  <RequirementCard 
                    icon={FaEye}
                    label="Göz Rengi"
                    value={project.requirements.eyeColor}
                  />
                )}
                {project.requirements.height && (
                  <RequirementCard 
                    icon={FaRulerVertical}
                    label="Boy"
                    value={project.requirements.height}
                    unit=" cm"
                  />
                )}
                {project.requirements.weight && (
                  <RequirementCard 
                    icon={FaWeight}
                    label="Kilo"
                    value={project.requirements.weight}
                    unit=" kg"
                  />
                )}
                {project.requirements.shoeSize && (
                  <RequirementCard 
                    icon={FaShoePrints}
                    label="Ayakkabı No"
                    value={project.requirements.shoeSize}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Başvuru Butonu */}
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-beyaz shadow-lg">
        <button 
          className="w-full bg-yogun-antrasit text-beyaz py-3 rounded-xl font-medium active:scale-98 transition-transform"
          onClick={() => {/* Başvuru işlemi */}}
        >
          Başvur
        </button>
      </div>
    </div>
  );
};

export default ProjectDetailPage;
