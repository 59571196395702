import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaMapMarkerAlt, 
  FaCalendarAlt, 
  FaUsers,
  FaFilm,
  FaTv,
  FaTheaterMasks,
  FaPhotoVideo,
  FaSearch,
  FaRegClock,
  FaCheckCircle,
  FaTimesCircle,
  FaMale,
  FaFemale,
  FaChild,
  FaThLarge,
  FaList
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Tarih belirtilmemiş';
    }
    return format(date, 'd MMM yyyy', { locale: tr });
  } catch (error) {
    return 'Tarih belirtilmemiş';
  }
};

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();
  const [viewMode, setViewMode] = useState('grid');

  useEffect(() => {
    if (!user?.uid) {
      navigate('/login');
      return;
    }

    const projectsRef = ref(db, 'projects');
    const unsubscribe = onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedProjects = Object.entries(data)
          .map(([id, project]) => ({ id, ...project }))
          .filter(project => !project.isDeleted)
          .sort((a, b) => {
            const dateA = new Date(a.date || 0);
            const dateB = new Date(b.date || 0);
            return dateB - dateA;
          });
        setProjects(loadedProjects);
      } else {
        setProjects([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user, navigate]);

  const filteredProjects = projects.filter(project => 
    project.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.projectType?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getProjectTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'film':
        return <FaFilm className="w-5 h-5" />;
      case 'dizi':
        return <FaTv className="w-5 h-5" />;
      case 'tiyatro':
        return <FaTheaterMasks className="w-5 h-5" />;
      case 'reklam':
        return <FaPhotoVideo className="w-5 h-5" />;
      default:
        return <FaFilm className="w-5 h-5" />;
    }
  };

  const getProjectStatus = (project) => {
    try {
      const today = new Date();
      const projectDate = project.date ? new Date(project.date) : null;
      
      if (project.status === 'concluded') {
        return { 
          text: 'Tamamlandı', 
          color: 'text-yesil bg-yesil/10',
          Icon: FaCheckCircle 
        };
      } else if (projectDate && projectDate < today) {
        return { 
          text: 'Süresi Doldu', 
          color: 'text-kirmizi bg-kirmizi/10',
          Icon: FaTimesCircle 
        };
      }
      return { 
        text: 'Aktif', 
        color: 'text-mavi bg-mavi/10',
        Icon: FaRegClock 
      };
    } catch (error) {
      return { 
        text: 'Aktif', 
        color: 'text-mavi bg-mavi/10',
        Icon: FaRegClock 
      };
    }
  };

  const ProjectCard = ({ project }) => {
    const status = getProjectStatus(project);
    const { Icon } = status;

    return (
      <div
        className="bg-beyaz rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-all cursor-pointer hover:-translate-y-1"
        onClick={() => navigate(`/project/${project.id}`)}
      >
        <div className="relative aspect-video bg-kirik-beyaz">
          <img
            src={project.images?.[0] || '/placeholder-project.jpg'}
            alt={project.title}
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-project.jpg';
            }}
          />
          <div className="absolute top-4 right-4">
            <div className={`flex items-center gap-1 px-3 py-1 rounded-full text-sm ${status.color}`}>
              <Icon className="w-4 h-4" />
              <span>{status.text}</span>
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="flex items-center gap-2 mb-3">
            {getProjectTypeIcon(project.projectType)}
            <span className="text-grafit-gri text-sm capitalize">{project.projectType}</span>
          </div>
          
          <h3 className="text-xl font-semibold text-yogun-antrasit mb-4 line-clamp-2">
            {project.title}
          </h3>

          <div className="space-y-3 mb-4">
            <div className="flex items-center text-grafit-gri">
              <FaCalendarAlt className="w-4 h-4 mr-2 shrink-0" />
              <span className="text-sm">
                {formatDate(project.date)}
              </span>
            </div>

            {(project.city || project.location) && (
              <div className="flex items-center text-grafit-gri">
                <FaMapMarkerAlt className="w-4 h-4 mr-2 shrink-0" />
                <span className="text-sm line-clamp-1">
                  {project.city}
                  {project.location && `, ${project.location}`}
                </span>
              </div>
            )}

            {project.peopleNeeded && Object.values(project.peopleNeeded).some(value => value > 0) && (
              <div className="flex flex-wrap gap-2">
                {project.peopleNeeded.men > 0 && (
                  <div className="flex items-center gap-1 bg-kirik-beyaz px-2 py-1 rounded-lg">
                    <FaMale className="w-4 h-4 text-yogun-antrasit shrink-0" />
                    <span className="text-xs text-grafit-gri">{project.peopleNeeded.men}</span>
                  </div>
                )}
                {project.peopleNeeded.women > 0 && (
                  <div className="flex items-center gap-1 bg-kirik-beyaz px-2 py-1 rounded-lg">
                    <FaFemale className="w-4 h-4 text-yogun-antrasit shrink-0" />
                    <span className="text-xs text-grafit-gri">{project.peopleNeeded.women}</span>
                  </div>
                )}
                {project.peopleNeeded.children > 0 && (
                  <div className="flex items-center gap-1 bg-kirik-beyaz px-2 py-1 rounded-lg">
                    <FaChild className="w-4 h-4 text-yogun-antrasit shrink-0" />
                    <span className="text-xs text-grafit-gri">{project.peopleNeeded.children}</span>
                  </div>
                )}
              </div>
            )}
          </div>

          <button 
            className="w-full py-2.5 bg-kirik-beyaz text-yogun-antrasit rounded-xl hover:bg-gray-100 transition-colors text-sm font-medium active:scale-98"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/project/${project.id}`);
            }}
          >
            Projeyi Görüntüle
          </button>
        </div>
      </div>
    );
  };

  const ProjectListItem = ({ project }) => {
    const status = getProjectStatus(project);
    const { Icon } = status;

    return (
      <div
        className="bg-beyaz rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-all cursor-pointer hover:-translate-y-0.5"
        onClick={() => navigate(`/project/${project.id}`)}
      >
        <div className="flex items-center p-4">
          <div className="w-40 h-24 bg-kirik-beyaz rounded-lg overflow-hidden shrink-0">
            <img
              src={project.images?.[0] || '/placeholder-project.jpg'}
              alt={project.title}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/placeholder-project.jpg';
              }}
            />
          </div>

          <div className="flex-1 ml-6">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-3">
                {getProjectTypeIcon(project.projectType)}
                <span className="text-grafit-gri text-sm capitalize">{project.projectType}</span>
                <div className={`flex items-center gap-1 px-3 py-1 rounded-full text-sm ${status.color}`}>
                  <Icon className="w-4 h-4" />
                  <span>{status.text}</span>
                </div>
              </div>
            </div>

            <h3 className="text-xl font-semibold text-yogun-antrasit mb-3">
              {project.title}
            </h3>

            <div className="flex items-center gap-6">
              <div className="flex items-center text-grafit-gri">
                <FaCalendarAlt className="w-4 h-4 mr-2 shrink-0" />
                <span className="text-sm">{formatDate(project.date)}</span>
              </div>

              {(project.city || project.location) && (
                <div className="flex items-center text-grafit-gri">
                  <FaMapMarkerAlt className="w-4 h-4 mr-2 shrink-0" />
                  <span className="text-sm">
                    {project.city}
                    {project.location && `, ${project.location}`}
                  </span>
                </div>
              )}

              {project.peopleNeeded && (
                <div className="flex items-center gap-3">
                  {project.peopleNeeded.men > 0 && (
                    <div className="flex items-center gap-1">
                      <FaMale className="w-4 h-4 text-yogun-antrasit" />
                      <span className="text-sm text-grafit-gri">{project.peopleNeeded.men}</span>
                    </div>
                  )}
                  {project.peopleNeeded.women > 0 && (
                    <div className="flex items-center gap-1">
                      <FaFemale className="w-4 h-4 text-yogun-antrasit" />
                      <span className="text-sm text-grafit-gri">{project.peopleNeeded.women}</span>
                    </div>
                  )}
                  {project.peopleNeeded.children > 0 && (
                    <div className="flex items-center gap-1">
                      <FaChild className="w-4 h-4 text-yogun-antrasit" />
                      <span className="text-sm text-grafit-gri">{project.peopleNeeded.children}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      <div className="bg-beyaz shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6">
            <h1 className="text-2xl font-bold text-yogun-antrasit">
              Projeler
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex items-center bg-kirik-beyaz rounded-lg p-1">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-md transition-colors ${
                    viewMode === 'grid' 
                      ? 'bg-beyaz shadow-sm text-yogun-antrasit' 
                      : 'text-grafit-gri hover:text-yogun-antrasit'
                  }`}
                >
                  <FaThLarge className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setViewMode('list')}
                  className={`p-2 rounded-md transition-colors ${
                    viewMode === 'list' 
                      ? 'bg-beyaz shadow-sm text-yogun-antrasit' 
                      : 'text-grafit-gri hover:text-yogun-antrasit'
                  }`}
                >
                  <FaList className="w-5 h-5" />
                </button>
              </div>

              <div className="relative">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Proje ara..."
                  className="w-80 pl-10 pr-4 py-2.5 rounded-xl border border-kirik-beyaz focus:outline-none focus:ring-2 focus:ring-mavi/20"
                />
                <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-grafit-gri" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
          </div>
        ) : filteredProjects.length > 0 ? (
          viewMode === 'grid' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {filteredProjects.map((project) => (
                <ProjectListItem key={project.id} project={project} />
              ))}
            </div>
          )
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <FaFilm className="w-12 h-12 text-grafit-gri mb-4" />
            <p className="text-grafit-gri text-lg">
              {searchTerm ? 'Arama sonucu bulunamadı.' : 'Henüz proje bulunmuyor.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsPage;
