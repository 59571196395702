import React, { useState, useEffect } from "react";
import { storage, db } from '../../firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, set, get } from 'firebase/database';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaInfoCircle } from 'react-icons/fa';

function ShowreelPageDesktop() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showreelVideo, setShowreelVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const fetchVideoURL = async () => {
      try {
        const dbReference = dbRef(db, `users/${user.uid}/profilecv/showreelVideo`);
        const snapshot = await get(dbReference);
        if (snapshot.exists()) {
          setVideoPreview(snapshot.val());
        }
      } catch (error) {
        console.error('Video yüklenirken hata:', error);
      }
    };
    fetchVideoURL();
  }, [user.uid]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 100 * 1024 * 1024) {
      setError('Video boyutu 100MB\'dan küçük olmalıdır.');
      return;
    }

    const allowedTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo'];
    if (!allowedTypes.includes(file.type)) {
      setError('Sadece MP4, MOV ve AVI formatları desteklenmektedir.');
      return;
    }

    setShowreelVideo(file);
    setVideoPreview(URL.createObjectURL(file));
    setError(null);
  };

  const handleUpload = async () => {
    if (!showreelVideo) {
      setError('Lütfen bir video seçin.');
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    setError(null);

    try {
      const storageRef = ref(storage, `showreelVideos/${user.uid}/${Date.now()}_${showreelVideo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, showreelVideo);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        }, 
        (error) => {
          console.error('Yükleme hatası:', error);
          setError('Video yüklenirken bir hata oluştu.');
          setUploading(false);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await set(dbRef(db, `users/${user.uid}/profilecv/showreelVideo`), downloadURL);
          setUploading(false);
          setShowSuccess(true);
          setTimeout(() => {
            navigate('/profile');
          }, 2000);
        }
      );
    } catch (error) {
      console.error('Hata:', error);
      setError('Bir hata oluştu. Lütfen tekrar deneyin.');
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="bg-beyaz border-b border-gray-200">
        <div className="max-w-7xl mx-auto flex items-center justify-between px-8 h-20">
          <div className="flex items-center gap-8">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-kirik-beyaz rounded-lg transition-colors"
            >
              <FaArrowLeft className="w-6 h-6 text-yogun-antrasit" />
            </button>
            <h1 className="text-2xl font-medium text-yogun-antrasit">
              Showreel Video
            </h1>
          </div>
          <button 
            onClick={handleUpload} 
            disabled={uploading || !showreelVideo}
            className={`px-6 py-2.5 rounded-xl text-base font-medium transition-all
              ${uploading || !showreelVideo 
                ? 'text-grafit-gri bg-kirik-beyaz' 
                : 'text-beyaz bg-yogun-antrasit hover:bg-grafit-gri'}`}
          >
            {uploading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="max-w-4xl mx-auto px-8 py-12">
        <div className="bg-beyaz rounded-2xl p-8 shadow-sm">
          {/* Video Önizleme */}
          <div
            className="relative aspect-video w-full rounded-xl overflow-hidden bg-yogun-antrasit mb-8 
              hover:opacity-95 active:opacity-90 transition-opacity cursor-pointer"
            onClick={() => !uploading && document.getElementById('showreel-input').click()}
          >
            {uploading ? (
              <div className="absolute inset-0 flex flex-col items-center justify-center text-beyaz">
                <FaSpinner className="animate-spin w-12 h-12 mb-4" />
                <p className="text-lg font-medium">Video Yükleniyor</p>
                <p className="text-base text-beyaz/80 mt-2">
                  %{uploadProgress.toFixed(0)}
                </p>
              </div>
            ) : videoPreview ? (
              <video 
                src={videoPreview} 
                className="w-full h-full object-cover"
                controls
              />
            ) : (
              <div className="absolute inset-0 flex flex-col items-center justify-center text-beyaz/80">
                <div className="w-20 h-20 rounded-full bg-beyaz/10 flex items-center justify-center mb-4">
                  <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 8v8m4-4H8m8 0a8 8 0 11-16 0 8 8 0 0116 0z" />
                  </svg>
                </div>
                <p className="text-lg font-medium">Video Yükle</p>
                <p className="text-base mt-2">veya sürükle bırak</p>
              </div>
            )}
          </div>

          {/* Hata Mesajı */}
          {error && (
            <div className="mb-8 p-4 bg-kirmizi/10 text-kirmizi rounded-xl text-base">
              {error}
            </div>
          )}

          {/* Video Seçme Butonu */}
          <input
            type="file"
            id="showreel-input"
            accept="video/*"
            onChange={handleVideoChange}
            className="hidden"
          />

          <div className="flex justify-center">
            <button
              onClick={() => !uploading && document.getElementById('showreel-input').click()}
              disabled={uploading}
              className="w-64 h-12 rounded-xl bg-kirik-beyaz text-yogun-antrasit 
                font-medium transition-all hover:bg-gray-200 active:bg-gray-300 
                disabled:opacity-50 disabled:hover:bg-kirik-beyaz"
            >
              Video Seç
            </button>
          </div>
        </div>
      </div>

      {/* Info İkonu */}
      <div className="fixed bottom-8 right-8 z-[60] group">
        <div className="absolute bottom-full right-0 mb-2 opacity-0 group-hover:opacity-100 
          transition-opacity duration-200 ease-in-out">
          <div className="bg-[#292929] text-white px-4 py-2 rounded-xl text-sm font-medium 
            shadow-lg relative min-w-[180px]">
            Nasıl olmalı?
            <div className="absolute -bottom-1 right-6 w-2 h-2 bg-[#292929] rotate-45"></div>
          </div>
        </div>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="bg-gradient-to-br from-[#292929] to-[#1a1a1a] text-white p-3 rounded-xl 
            shadow-lg hover:shadow-xl hover:-translate-y-0.5 active:translate-y-0 
            transition-all duration-200 ease-in-out hover:from-[#333333] hover:to-[#1f1f1f]"
        >
          <FaInfoCircle size={24} className="filter drop-shadow-md" />
        </button>
      </div>

      {/* Info Modal */}
      {showInfo && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70] p-6">
          <div className="bg-beyaz rounded-xl w-full max-w-lg p-6 animate-fade-in">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-medium text-yogun-antrasit">
                Showreel Video Nasıl Olmalı?
              </h3>
              <button 
                onClick={() => setShowInfo(false)}
                className="text-grafit-gri hover:text-yogun-antrasit transition-colors"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="space-y-4 text-grafit-gri">
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>En iyi performanslarınızı içeren 2-3 dakikalık bir video hazırlayın</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Profesyonel ve net bir görüntü kalitesi kullanın</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>İyi aydınlatılmış bir ortamda çekim yapın</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Yatay formatta çekim yapmanız önerilir</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Öne çıkan performanslarınızı özenle seçin</span>
              </p>
            </div>

            <div className="mt-8 pt-6 border-t border-gray-200">
              <h4 className="text-lg font-medium text-yogun-antrasit mb-4">
                Teknik Gereksinimler
              </h4>
              <div className="space-y-3 text-grafit-gri">
                <p className="flex items-center gap-2">
                  <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                  <span>Maksimum dosya boyutu: 100MB</span>
                </p>
                <p className="flex items-center gap-2">
                  <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                  <span>Desteklenen formatlar: MP4, MOV, AVI</span>
                </p>
                <p className="flex items-center gap-2">
                  <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                  <span>Önerilen çözünürlük: 1920x1080 (Full HD)</span>
                </p>
                <p className="flex items-center gap-2">
                  <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                  <span>Minimum video kalitesi: 720p</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Başarı Modal */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-2xl p-8 max-w-md w-full mx-4 text-center animate-fade-scale">
            <div className="w-20 h-20 rounded-full bg-yesil/10 flex items-center justify-center mx-auto mb-6">
              <svg className="w-10 h-10 text-yesil" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h3 className="text-2xl font-medium text-yogun-antrasit mb-3">
              Video Başarıyla Yüklendi
            </h3>
            <p className="text-grafit-gri">
              Profilinize yönlendiriliyorsunuz...
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowreelPageDesktop; 