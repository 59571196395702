import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaBell, FaCog, FaLock, FaImage, FaFileAlt, FaVideo, FaUser, FaPlus, FaGift, FaProjectDiagram, FaMoneyBill, FaEdit } from 'react-icons/fa';
import KlaketLogo from '../../assets/logo.svg';

const db = getDatabase();

const translateRole = (role) => {
  const roles = {
    admin: 'Yönetici',
    user: 'Kullanıcı',
    production: 'Yapım'
  };
  return roles[role] || role;
};

function ProfilePage() {
  const { user } = useAuth();
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = ref(db, `users/${user.uid}`);
    const unsubscribe = onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        setProfileData(snapshot.val());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-16 h-16 relative">
          <div className="w-full h-full border-4 border-kirik-beyaz rounded-full animate-spin border-t-yogun-antrasit" />
        </div>
      </div>
    );
  }

  const getProfileImage = () => {
    if (profileData.profileImage) {
      return { type: 'image', src: profileData.profileImage };
    } else if (profileData.role === 'production') {
      return { type: 'logo', src: KlaketLogo };
    }
    return { type: 'icon', Icon: FaUser };
  };

  const getMenuItems = () => {
    const baseMenuItems = [
      { label: 'Ayarlar', icon: <FaCog />, path: '/settings' },
    ];

    const userMenuItems = [
      { label: 'Kolaj Yap', icon: <FaImage />, path: '/kolaj', description: 'Zorunlu alan*' },
      { label: 'CV Düzenle', icon: <FaFileAlt />, path: '/cv-form', description: 'Zorunlu alan*' },
      { label: 'Tanıtım Videosu', icon: <FaVideo />, path: '/intro-video-upload', description: 'Zorunlu alan*' },
      { label: 'Showreel Videosu', icon: <FaVideo />, path: '/showreel', description: 'Zorunlu değil' },
      { label: 'Mimik Videosu', icon: <FaVideo />, path: '/mimic-video-upload', description: 'Zorunlu değil' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/profile-approval' },
      { label: 'Banka Detayı', icon: <FaMoneyBill />, path: '/bank-detail' }
    ];

    const adminMenuItems = [
      { label: 'Proje Ekle', icon: <FaPlus />, path: '/project-add' },
      { label: 'Proje Yönetimi', icon: <FaProjectDiagram />, path: '/project-manage' },
      { label: 'Seçilmiş Kullanıcılar', icon: <FaUser />, path: '/selected-users' },
      { label: 'Duyuru Yönetimi', icon: <FaBell />, path: '/announcement-manage' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/admin-approval' },
    ];

    const productionMenuItems = [
      { label: 'Seçilmiş Kullanıcılar', icon: <FaUser />, path: '/selected-users' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/admin-approval' },
    ];

    const roleMenuItems = {
      admin: [...baseMenuItems, ...adminMenuItems],
      production: [...baseMenuItems, ...productionMenuItems],
      user: [...userMenuItems, ...baseMenuItems]
    };

    return roleMenuItems[profileData.role] || [...userMenuItems, ...baseMenuItems];
  };

  const profileImageData = getProfileImage();
  const menuItems = getMenuItems();

  return (
    <div className="min-h-screen bg-kirik-beyaz py-8">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-12 gap-8">
          {/* Sol Taraf - Profil Kartı */}
          <div className="col-span-4">
            <div className="bg-beyaz rounded-2xl p-8 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex flex-col items-center space-y-6">
                {/* Profil Fotoğrafı */}
                <div className="relative group">
                  <div className="w-48 h-48 rounded-full bg-kirik-beyaz flex items-center justify-center overflow-hidden border-4 border-kirik-beyaz shadow-lg">
                    {profileImageData.type === 'image' && (
                      <img 
                        src={profileImageData.src} 
                        alt="Profil" 
                        className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                      />
                    )}
                    {profileImageData.type === 'logo' && (
                      <img 
                        src={profileImageData.src} 
                        alt="Logo" 
                        className="w-4/5 h-4/5 object-contain opacity-75"
                      />
                    )}
                    {profileImageData.type === 'icon' && (
                      <profileImageData.Icon size={64} className="text-grafit-gri opacity-75" />
                    )}
                  </div>
                  
                  {/* Düzenleme Overlay */}
                  <div 
                    className="absolute inset-0 bg-black/0 group-hover:bg-black/20 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300"
                    onClick={() => navigate('/profile-edit')}
                  >
                    <FaEdit className="w-8 h-8 text-beyaz opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>
                </div>

                {/* Kullanıcı Bilgileri */}
                <div className="text-center space-y-3">
                  <h2 className="text-2xl font-bold text-yogun-antrasit">
                    {profileData.fullName}
                  </h2>
                  <span className="px-4 py-1.5 bg-kirik-beyaz text-grafit-gri rounded-full text-sm font-medium inline-block">
                    {profileData.title || translateRole(profileData.role)}
                  </span>
                </div>

                {/* Profil Aksiyonları */}
                <div className="w-full space-y-3">
                  <ActionButton
                    label="Profili Düzenle"
                    icon={FaUser}
                    onClick={() => navigate('/profile-edit')}
                    primary
                  />
                  <ActionButton
                    label="Kampanyalar"
                    icon={FaGift}
                    onClick={() => navigate('/campaigns')}
                    secondary
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Sağ Taraf - Menü Grid */}
          <div className="col-span-8">
            <div className="grid grid-cols-2 gap-4">
              {menuItems.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Yardımcı Bileşenler
const StatItem = ({ label, value, icon: Icon }) => (
  <div className="text-center group cursor-default">
    <div className="flex justify-center mb-2">
      <Icon className="w-5 h-5 text-grafit-gri group-hover:text-yogun-antrasit transition-colors" />
    </div>
    <div className="text-2xl font-bold text-yogun-antrasit group-hover:scale-110 transition-transform">
      {value}
    </div>
    <div className="text-sm text-grafit-gri">
      {label}
    </div>
  </div>
);

const ActionButton = ({ label, icon: Icon, onClick, primary }) => (
  <button
    onClick={onClick}
    className={`
      w-full py-3 rounded-xl text-sm font-medium
      flex items-center justify-center gap-2
      transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]
      ${primary 
        ? 'bg-yogun-antrasit text-beyaz hover:bg-grafit-gri shadow-sm' 
        : 'bg-kirik-beyaz text-yogun-antrasit hover:bg-kirik-beyaz/80'
      }
    `}
  >
    <Icon className="w-4 h-4" />
    {label}
  </button>
);

const MenuItem = ({ icon, label, description, path }) => {
  const navigate = useNavigate();
  
  return (
    <div
      onClick={() => navigate(path)}
      className="bg-beyaz p-6 rounded-2xl shadow-sm hover:shadow-md cursor-pointer group transition-all duration-200"
    >
      <div className="flex items-start gap-4">
        <div className="w-12 h-12 rounded-xl bg-kirik-beyaz flex items-center justify-center group-hover:bg-yogun-antrasit transition-colors">
          {React.cloneElement(icon, { 
            className: "w-6 h-6 text-yogun-antrasit group-hover:text-beyaz transition-colors" 
          })}
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-medium text-yogun-antrasit group-hover:text-yogun-antrasit/80 transition-colors">
            {label}
          </h3>
          {description && (
            <p className="text-sm text-grafit-gri mt-1">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
