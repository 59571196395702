import React, { useState, useEffect } from "react";
import { storage, db } from '../../firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, set, get } from 'firebase/database';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaInfoCircle, FaTimes } from 'react-icons/fa';

function ShowreelPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showreelVideo, setShowreelVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const fetchVideoURL = async () => {
      try {
        const dbReference = dbRef(db, `users/${user.uid}/profilecv/showreelVideo`);
        const snapshot = await get(dbReference);
        if (snapshot.exists()) {
          setVideoPreview(snapshot.val());
        }
      } catch (error) {
        console.error('Video yüklenirken hata:', error);
      }
    };
    fetchVideoURL();
  }, [user.uid]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Video boyut kontrolü (örn: 100MB)
      if (file.size > 100 * 1024 * 1024) {
        alert('Video boyutu 100MB\'dan küçük olmalıdır.');
        return;
      }
      setShowreelVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    if (!showreelVideo) {
      alert('Lütfen bir showreel videosu seçin.');
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    try {
      const storageRef = ref(storage, `showreelVideos/${user.uid}/${Date.now()}_${showreelVideo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, showreelVideo);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        }, 
        (error) => {
          console.error('Yükleme hatası:', error);
          alert('Video yüklenirken bir hata oluştu.');
          setUploading(false);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await set(dbRef(db, `users/${user.uid}/profilecv/showreelVideo`), downloadURL);
          setUploading(false);
          navigate('/profile');
        }
      );
    } catch (error) {
      console.error('Hata:', error);
      alert('Bir hata oluştu. Lütfen tekrar deneyin.');
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="bg-beyaz sticky top-0 z-50 border-b border-gray-200">
        <div className="flex items-center justify-between px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 hover:bg-kirik-beyaz rounded-lg"
          >
            <FaChevronLeft className="w-5 h-5 text-yogun-antrasit" />
          </button>
          <h1 className="text-lg font-medium text-yogun-antrasit">Showreel Video</h1>
          <button
            onClick={handleUpload}
            disabled={!showreelVideo || uploading}
            className="text-yogun-antrasit font-medium disabled:opacity-50 
              disabled:cursor-not-allowed hover:opacity-80 transition-opacity"
          >
            {uploading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="max-w-2xl mx-auto px-4 py-6">
        {/* Video Önizleme */}
        <div
          className="relative aspect-video rounded-2xl bg-yogun-antrasit overflow-hidden 
            cursor-pointer transition-transform hover:scale-[1.02] active:scale-[0.98] mb-6"
          onClick={() => !uploading && document.getElementById('showreel-input').click()}
        >
          {videoPreview ? (
            <video 
              src={videoPreview} 
              className="w-full h-full object-cover"
              controls
            />
          ) : (
            <div className="absolute inset-0 flex flex-col items-center justify-center text-beyaz">
              <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <p className="text-lg font-medium">Showreel Video Yükle</p>
              <p className="text-sm text-beyaz/70 mt-2">veya buraya sürükleyip bırakın</p>
            </div>
          )}

          {uploading && (
            <div className="absolute inset-0 bg-black/75 flex flex-col items-center justify-center text-beyaz">
              <div className="w-16 h-16 mb-4 relative">
                <svg className="w-full h-full animate-spin" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-sm font-medium">{Math.round(uploadProgress)}%</span>
                </div>
              </div>
              <p className="text-lg font-medium">Yükleniyor...</p>
            </div>
          )}
        </div>

        <input
          type="file"
          id="showreel-input"
          className="hidden"
          accept="video/*"
          onChange={handleVideoChange}
        />

        {/* Video Seç Butonu */}
        <button
          onClick={() => !uploading && document.getElementById('showreel-input').click()}
          disabled={uploading}
          className="w-full h-12 rounded-xl bg-kirik-beyaz text-yogun-antrasit 
            font-medium transition-all hover:bg-gray-200 active:bg-gray-300 
            disabled:opacity-50 disabled:hover:bg-kirik-beyaz"
        >
          Video Seç
        </button>
      </div>

      {/* Floating Info Button */}
      <div className="fixed bottom-20 right-6 z-[60] group">
        <div className="absolute bottom-full right-0 mb-2 opacity-0 group-hover:opacity-100 
          transition-opacity duration-200 ease-in-out">
          <div className="bg-[#292929] text-white px-4 py-2 rounded-xl text-sm font-medium 
            shadow-lg relative min-w-[180px]">
            Showreel nasıl olmalı?
            <div className="absolute -bottom-1 right-6 w-2 h-2 bg-[#292929] rotate-45"></div>
          </div>
        </div>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="bg-gradient-to-br from-[#292929] to-[#1a1a1a] text-white p-3 rounded-xl 
            shadow-lg hover:shadow-xl hover:-translate-y-0.5 active:translate-y-0 
            transition-all duration-200 ease-in-out hover:from-[#333333] hover:to-[#1f1f1f]"
        >
          <FaInfoCircle size={20} className="filter drop-shadow-md" />
        </button>
      </div>

      {/* Info Modal */}
      {showInfo && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70] p-6">
          <div className="bg-beyaz rounded-2xl p-8 max-w-2xl w-full animate-fade-in">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-medium text-yogun-antrasit">
                Showreel Video Nasıl Olmalı?
              </h3>
              <button 
                onClick={() => setShowInfo(false)}
                className="text-grafit-gri hover:text-yogun-antrasit transition-colors"
              >
                <FaTimes className="w-6 h-6" />
              </button>
            </div>
            
            <div className="space-y-4 text-grafit-gri">
              <p className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                <span>En iyi performanslarınızı içeren 2-3 dakikalık bir video hazırlayın</span>
              </p>
              <p className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                <span>Video kalitesi en az 720p olmalıdır</span>
              </p>
              <p className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                <span>Maksimum dosya boyutu: 100MB</span>
              </p>
              <p className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                <span>Desteklenen formatlar: MP4, MOV, AVI</span>
              </p>
              <p className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-yogun-antrasit"></span>
                <span>Önerilen çözünürlük: 1920x1080 (Full HD)</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowreelPage; 