import React from 'react';
import { Outlet } from 'react-router-dom';
import BottomNavbar from '../components/mobile/BottomNavbar';

const MobileLayout = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Ana içerik */}
      <main> {/* Bottom navbar için padding */}
        <Outlet />
      </main>

      {/* Bottom Navigation */}
      <BottomNavbar />
    </div>
  );
};

export default MobileLayout;
