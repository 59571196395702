import React, { useEffect, useState } from 'react';
import { getDatabase, ref, query, orderByChild, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaCalendar, 
  FaEye, 
  FaTag, 
  FaExclamationCircle, 
  FaCog, 
  FaBullhorn,
  FaUsers,
  FaUserTie,
  FaTheaterMasks,
  FaExternalLinkAlt,
  FaFilter
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const DuyurularPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('all');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const announcementsRef = ref(db, 'announcements');
    const announcementsQuery = query(announcementsRef, orderByChild('createdAt'));

    const unsubscribe = onValue(announcementsQuery, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const announcementsList = Object.entries(data)
          .map(([id, announcement]) => ({
            id,
            ...announcement,
          }))
          .filter(announcement => announcement.status === 'active')
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setAnnouncements(announcementsList);
      } else {
        setAnnouncements([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getTypeColor = (type) => {
    switch (type) {
      case 'urgent':
        return 'bg-kirmizi/10 text-kirmizi';
      case 'maintenance':
        return 'bg-turuncu/10 text-turuncu';
      default:
        return 'bg-yesil/10 text-yesil';
    }
  };

  const getTypeText = (type) => {
    switch (type) {
      case 'urgent':
        return 'Acil';
      case 'maintenance':
        return 'Bakım/Güncelleme';
      default:
        return 'Genel';
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'urgent':
        return <FaExclamationCircle className="w-4 h-4" />;
      case 'maintenance':
        return <FaCog className="w-4 h-4" />;
      default:
        return <FaBullhorn className="w-4 h-4" />;
    }
  };

  const getAudienceIcon = (audience) => {
    switch (audience) {
      case 'actors':
        return <FaTheaterMasks className="w-4 h-4" />;
      case 'clients':
        return <FaUserTie className="w-4 h-4" />;
      default:
        return <FaUsers className="w-4 h-4" />;
    }
  };

  const filteredAnnouncements = selectedType === 'all' 
    ? announcements 
    : announcements.filter(a => a.type === selectedType);

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <FaBullhorn className="w-6 h-6 text-yogun-antrasit" />
            <h1 className="text-2xl font-semibold text-yogun-antrasit">
              Duyurular
            </h1>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2 px-4 py-2 bg-beyaz rounded-xl border border-kirik-beyaz">
              <FaFilter className="w-4 h-4 text-grafit-gri" />
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="bg-transparent focus:outline-none text-sm text-grafit-gri"
              >
                <option value="all">Tüm Duyurular</option>
                <option value="urgent">Acil Duyurular</option>
                <option value="maintenance">Bakım/Güncelleme</option>
                <option value="general">Genel Duyurular</option>
              </select>
            </div>
            <span className="text-sm text-grafit-gri bg-beyaz px-4 py-2 rounded-xl border border-kirik-beyaz">
              {filteredAnnouncements.length} duyuru
            </span>
          </div>
        </div>

        <div className="grid gap-6">
          {filteredAnnouncements.map((announcement) => (
            <div
              key={announcement.id}
              className="bg-beyaz rounded-xl shadow-sm hover:shadow-md transition-all duration-300 cursor-pointer group"
              onClick={() => navigate(`/announcement/${announcement.id}`)}
            >
              <div className="p-6">
                <div className="flex items-start gap-6">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center gap-3 mb-3">
                      <h2 className="text-lg font-medium text-yogun-antrasit">
                        {announcement.title}
                      </h2>
                      <span className={`flex items-center gap-2 px-3 py-1 rounded-full text-xs font-medium ${getTypeColor(announcement.type)}`}>
                        {getTypeIcon(announcement.type)}
                        {getTypeText(announcement.type)}
                      </span>
                    </div>
                    
                    <p className="text-grafit-gri line-clamp-2 mb-4 group-hover:text-yogun-antrasit transition-colors">
                      {announcement.description}
                    </p>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-6 text-sm text-grafit-gri">
                        <div className="flex items-center gap-2">
                          <FaCalendar className="w-4 h-4" />
                          {format(new Date(announcement.createdAt), 'd MMM yyyy', { locale: tr })}
                        </div>
                        <div className="flex items-center gap-2">
                          {getAudienceIcon(announcement.targetAudience)}
                          {announcement.targetAudience === 'actors' 
                            ? 'Oyuncular' 
                            : announcement.targetAudience === 'clients' 
                              ? 'İşverenler' 
                              : 'Herkes'}
                        </div>
                      </div>

                      {announcement.link && (
                        <a
                          href={announcement.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className="flex items-center gap-2 text-sm text-mavi hover:underline"
                        >
                          <span>Detaylı Bilgi</span>
                          <FaExternalLinkAlt className="w-3 h-3" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {filteredAnnouncements.length === 0 && (
            <div className="text-center py-12 bg-beyaz rounded-xl">
              <FaBullhorn className="w-8 h-8 text-grafit-gri mx-auto mb-3" />
              <p className="text-grafit-gri">
                {selectedType === 'all' 
                  ? 'Henüz duyuru bulunmuyor.'
                  : 'Bu kategoride duyuru bulunmuyor.'}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DuyurularPage;
