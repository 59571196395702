import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import MobileLayout from '../layouts/MobileLayout';
import AuthPage from '../pages/mobile/AuthPage';
import MobileProfile from '../pages/mobile/MobileProfile';
import ProjectsPage from '../pages/mobile/ProjectsPage';
import ProjectDetailPage from '../pages/mobile/ProjectDetailPage';
import DuyurularPage from '../pages/mobile/DuyurularPage';
import DuyuruDetailPage from '../pages/mobile/DuyuruDetailPage';
import PlayersPage from '../pages/mobile/PlayersPage';
import PlayerDetailPage from '../pages/mobile/PlayerDetailPage';
import ProfileEditPage from '../pages/mobile/ProfileEditPage';
import ProjectAddMobile from '../pages/mobile/ProjectAddMobile';
import ProjectManageMobile from '../pages/mobile/ProjectManageMobile';
import AnnouncementManageMobile from '../pages/mobile/AnnouncementManageMobile';
import SelectedUsersMobile from '../pages/mobile/SelectedUsersMobile';
import AdminApprovalMobile from '../pages/mobile/AdminApprovalMobile';
import ProfileApproval from '../pages/mobile/ProfileApproval';
import Kolaj from '../pages/mobile/Kolaj';
import IntroVideoUpload from '../pages/mobile/IntroVideoUpload';
import CvForm from '../pages/mobile/CvForm';
import ShowreelPage from '../pages/mobile/ShowreelPage';
import MimicVideoUpload from '../pages/mobile/MimicVideoUpload';
import BankDetailMobile from '../pages/mobile/BankDetail';
// Korumalı Route bileşeni
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (!user) {
    // Kullanıcı giriş yapmamışsa auth sayfasına yönlendir
    return <Navigate to="/auth" replace />;
  }

  return children;
};

const MobileRoutes = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />} />
      
      {/* Layout ve Korumalı rotalar */}
      <Route element={
        <ProtectedRoute>
          <MobileLayout />
        </ProtectedRoute>
      }>
        <Route path="/" element={<ProjectsPage />} />
        <Route path="/profile" element={<MobileProfile />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/:id" element={<ProjectDetailPage />} />
        <Route path="/announcements" element={<DuyurularPage />} />
        <Route path="/announcement/:id" element={<DuyuruDetailPage />} />
        <Route path="/announcement/manage" element={<AnnouncementManageMobile />} />  
        <Route path="/players" element={<PlayersPage />} />
        <Route path="/player/:id" element={<PlayerDetailPage />} />
        <Route path="/profile/edit" element={<ProfileEditPage />} />
        <Route path="/project/add" element={<ProjectAddMobile />} />
        <Route path="/project/manage" element={<ProjectManageMobile />} />
        <Route path="/selected-users" element={<SelectedUsersMobile />} />
        <Route path="/admin-approval" element={<AdminApprovalMobile />} />
        <Route path="/profile-approval" element={<ProfileApproval />} />
        <Route path="/kolaj" element={<Kolaj />} />
        <Route path="/intro-video-upload" element={<IntroVideoUpload />} />
        <Route path="/cv-form" element={<CvForm />} />
        <Route path="/showreel" element={<ShowreelPage />} />
        <Route path="/mimic-video-upload" element={<MimicVideoUpload />} />
        <Route path="/bank-detail" element={<BankDetailMobile />} />
      </Route>

      {/* Bilinmeyen rotaları auth sayfasına yönlendir */}
      <Route path="*" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default MobileRoutes;
