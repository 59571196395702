import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaArrowLeft, 
  FaCalendarAlt, 
  FaMapMarkerAlt, 
  FaClock, 
  FaUsers,
  FaFilm,
  FaTv,
  FaTheaterMasks,
  FaPhotoVideo,
  FaMale,
  FaFemale,
  FaChild,
  FaMoneyBillWave,
  FaEnvelope,
  FaPhone,
  FaRulerVertical,
  FaWeight,
  FaEye,
  FaGraduationCap
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Tarih belirtilmemiş';
    }
    return format(date, 'd MMMM yyyy', { locale: tr });
  } catch (error) {
    return 'Tarih belirtilmemiş';
  }
};

const getProjectTypeIcon = (type) => {
  switch (type?.toLowerCase()) {
    case 'film':
      return <FaFilm className="w-5 h-5" />;
    case 'dizi':
      return <FaTv className="w-5 h-5" />;
    case 'tiyatro':
      return <FaTheaterMasks className="w-5 h-5" />;
    case 'reklam':
      return <FaPhotoVideo className="w-5 h-5" />;
    default:
      return <FaFilm className="w-5 h-5" />;
  }
};

const ProjectDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedActors, setSelectedActors] = useState([]);
  const [projectOwner, setProjectOwner] = useState(null);
  const [applications, setApplications] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [hasApplied, setHasApplied] = useState(false);
  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        // Proje verilerini çek
        const projectRef = ref(db, `projects/${id}`);
        const projectSnapshot = await get(projectRef);
        
        if (projectSnapshot.exists()) {
          const projectData = projectSnapshot.val();
          setProject({ id, ...projectData });
          
          // Proje sahibi bilgilerini çek
          if (projectData.userId) {
            const ownerRef = ref(db, `users/${projectData.userId}`);
            const ownerSnapshot = await get(ownerRef);
            if (ownerSnapshot.exists()) {
              setProjectOwner({ id: projectData.userId, ...ownerSnapshot.val() });
            }
          }

          // Seçili oyuncuları çek
          if (projectData.selectedActors) {
            const actorPromises = Object.keys(projectData.selectedActors).map(async (actorId) => {
              const actorRef = ref(db, `users/${actorId}`);
              const actorSnapshot = await get(actorRef);
              if (actorSnapshot.exists()) {
                // Oyuncunun başvuru durumunu da çek
                const applicationRef = ref(db, `applications/${id}/${actorId}`);
                const applicationSnapshot = await get(applicationRef);
                return { 
                  id: actorId, 
                  ...actorSnapshot.val(),
                  applicationStatus: applicationSnapshot.exists() ? applicationSnapshot.val().status : null
                };
              }
              return null;
            });
            
            const actors = (await Promise.all(actorPromises)).filter(actor => actor !== null);
            setSelectedActors(actors);
          }

          // Başvuruları çek
          const applicationsRef = ref(db, `applications/${id}`);
          const applicationsSnapshot = await get(applicationsRef);
          
          if (applicationsSnapshot.exists()) {
            const applicationsData = applicationsSnapshot.val();
            setApplications(Object.entries(applicationsData).map(([userId, data]) => ({
              userId,
              ...data
            })));

            // Başvuran oyuncuların bilgilerini çek
            const applicantPromises = Object.keys(applicationsData).map(async (applicantId) => {
              const applicantRef = ref(db, `users/${applicantId}`);
              const applicantSnapshot = await get(applicantRef);
              if (applicantSnapshot.exists()) {
                return {
                  id: applicantId,
                  ...applicantSnapshot.val(),
                  applicationStatus: applicationsData[applicantId].status,
                  applicationDate: applicationsData[applicantId].date
                };
              }
              return null;
            });

            const applicantsData = (await Promise.all(applicantPromises)).filter(applicant => applicant !== null);
            setApplicants(applicantsData);
          }

          // Kullanıcının başvuru durumunu kontrol et
          const applicationRef = ref(db, `applications/${id}/${user.uid}`);
          const applicationSnapshot = await get(applicationRef);
          setHasApplied(applicationSnapshot.exists());
        }
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [id, user, navigate]);

  const handleApply = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      setIsApplying(true);
      const applicationRef = ref(db, `applications/${id}/${user.uid}`);
      await set(applicationRef, {
        date: new Date().toISOString(),
        status: 'pending',
        userId: user.uid
      });
      setHasApplied(true);
    } catch (error) {
      console.error('Başvuru yapılırken hata oluştu:', error);
    } finally {
      setIsApplying(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-kirik-beyaz">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  if (!project) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-kirik-beyaz">
        <h2 className="text-2xl font-bold text-yogun-antrasit mb-4">Proje bulunamadı</h2>
        <button
          onClick={() => navigate('/projects')}
          className="px-6 py-2.5 bg-yogun-antrasit text-beyaz rounded-xl hover:bg-black transition-colors"
        >
          Projelere Dön
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Hero Section */}
      <div className="bg-beyaz border-b border-kirik-beyaz">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <button 
            onClick={() => navigate(-1)}
            className="flex items-center text-grafit-gri hover:text-yogun-antrasit mb-6 transition-colors"
          >
            <FaArrowLeft className="w-4 h-4 mr-2" />
            <span>Geri Dön</span>
          </button>

          <div className="flex items-start justify-between">
            <div className="flex-1">
              <div className="flex items-center gap-3 mb-4">
                {getProjectTypeIcon(project?.projectType)}
                <span className="text-grafit-gri font-medium capitalize">
                  {project?.projectType}
                </span>
                <span className="w-1.5 h-1.5 rounded-full bg-grafit-gri/30" />
                <span className="text-grafit-gri">
                  {formatDate(project?.date)}
                </span>
              </div>

              <h1 className="text-3xl font-bold text-yogun-antrasit mb-4">
                {project?.title}
              </h1>

              <div className="flex flex-wrap items-center gap-6 mb-6">
                {project?.location && (
                  <div className="flex items-center text-grafit-gri">
                    <FaMapMarkerAlt className="w-4 h-4 mr-2" />
                    <span>{project.location}</span>
                  </div>
                )}
                {project?.city && (
                  <div className="flex items-center text-grafit-gri">
                    <FaMapMarkerAlt className="w-4 h-4 mr-2" />
                    <span>{project.city}</span>
                  </div>
                )}
                {project?.budget && (
                  <div className="flex items-center text-grafit-gri">
                    <FaMoneyBillWave className="w-4 h-4 mr-2" />
                    <span>{project.budget} TL</span>
                  </div>
                )}
                {(project?.startTime || project?.endTime) && (
                  <div className="flex items-center text-grafit-gri">
                    <FaClock className="w-4 h-4 mr-2" />
                    <span>{project.startTime} - {project.endTime}</span>
                  </div>
                )}
              </div>

              {project?.userId !== user?.uid && (
                <button
                  onClick={handleApply}
                  disabled={hasApplied || isApplying}
                  className={`px-6 py-2.5 rounded-xl font-medium transition-all ${
                    hasApplied
                      ? 'bg-yesil/10 text-yesil cursor-default'
                      : isApplying
                      ? 'bg-mavi/10 text-mavi cursor-wait'
                      : 'bg-mavi text-beyaz hover:bg-mavi/90 active:scale-98'
                  }`}
                >
                  {hasApplied 
                    ? 'Başvuru Yapıldı' 
                    : isApplying 
                    ? 'Başvuru Yapılıyor...' 
                    : 'Projeye Başvur'}
                </button>
              )}
            </div>

            <div className="ml-8">
              <img
                src={project?.images?.[0] || '/placeholder-project.jpg'}
                alt={project?.title}
                className="w-48 h-48 rounded-2xl object-cover shadow-sm"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/placeholder-project.jpg';
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Sol Kolon */}
          <div className="lg:col-span-8 space-y-8">
            {/* Aranan Oyuncular */}
            {project?.actorDetails && (
              <div className="bg-beyaz rounded-2xl p-8 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">
                  Aranan Oyuncular
                </h2>
                <div className="grid grid-cols-4 gap-4">
                  {project.actorDetails.men > 0 && (
                    <div className="flex flex-col items-center p-6 bg-kirik-beyaz rounded-xl">
                      <FaMale className="w-8 h-8 text-yogun-antrasit mb-2" />
                      <span className="text-2xl font-bold text-yogun-antrasit">
                        {project.actorDetails.men}
                      </span>
                      <span className="text-grafit-gri">Erkek</span>
                    </div>
                  )}
                  {project.actorDetails.women > 0 && (
                    <div className="flex flex-col items-center p-6 bg-kirik-beyaz rounded-xl">
                      <FaFemale className="w-8 h-8 text-yogun-antrasit mb-2" />
                      <span className="text-2xl font-bold text-yogun-antrasit">
                        {project.actorDetails.women}
                      </span>
                      <span className="text-grafit-gri">Kadın</span>
                    </div>
                  )}
                  {project.actorDetails.children > 0 && (
                    <div className="flex flex-col items-center p-6 bg-kirik-beyaz rounded-xl">
                      <FaChild className="w-8 h-8 text-yogun-antrasit mb-2" />
                      <span className="text-2xl font-bold text-yogun-antrasit">
                        {project.actorDetails.children}
                      </span>
                      <span className="text-grafit-gri">Çocuk</span>
                    </div>
                  )}
                  {project.actorDetails.extras > 0 && (
                    <div className="flex flex-col items-center p-6 bg-kirik-beyaz rounded-xl">
                      <FaUsers className="w-8 h-8 text-yogun-antrasit mb-2" />
                      <span className="text-2xl font-bold text-yogun-antrasit">
                        {project.actorDetails.extras}
                      </span>
                      <span className="text-grafit-gri">Ekstra</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Gereksinimler */}
            {project?.requirements && (
              <div className="bg-beyaz rounded-2xl p-8 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">
                  Oyuncu Gereksinimleri
                </h2>
                <div className="grid grid-cols-2 gap-6">
                  {project.requirements.ageMin && (
                    <div className="flex items-center gap-3">
                      <FaUsers className="w-5 h-5 text-yogun-antrasit" />
                      <div>
                        <span className="text-sm text-grafit-gri">Yaş Aralığı</span>
                        <p className="text-yogun-antrasit font-medium">
                          {project.requirements.ageMin} - {project.requirements.ageMax || '+'} yaş
                        </p>
                      </div>
                    </div>
                  )}
                  {project.requirements.height && (
                    <div className="flex items-center gap-3">
                      <FaRulerVertical className="w-5 h-5 text-yogun-antrasit" />
                      <div>
                        <span className="text-sm text-grafit-gri">Boy</span>
                        <p className="text-yogun-antrasit font-medium">{project.requirements.height} cm</p>
                      </div>
                    </div>
                  )}
                  {project.requirements.weight && (
                    <div className="flex items-center gap-3">
                      <FaWeight className="w-5 h-5 text-yogun-antrasit" />
                      <div>
                        <span className="text-sm text-grafit-gri">Kilo</span>
                        <p className="text-yogun-antrasit font-medium">{project.requirements.weight} kg</p>
                      </div>
                    </div>
                  )}
                  {project.requirements.experience && (
                    <div className="flex items-center gap-3">
                      <FaGraduationCap className="w-5 h-5 text-yogun-antrasit" />
                      <div>
                        <span className="text-sm text-grafit-gri">Deneyim</span>
                        <p className="text-yogun-antrasit font-medium capitalize">
                          {project.requirements.experience === 'beginner' ? 'Başlangıç' : 
                           project.requirements.experience === 'intermediate' ? 'Orta Seviye' : 
                           project.requirements.experience === 'advanced' ? 'İleri Seviye' : 
                           project.requirements.experience}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Proje Açıklaması */}
            {project?.description && (
              <div className="bg-beyaz rounded-2xl p-8 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">
                  Proje Açıklaması
                </h2>
                <p className="text-grafit-gri leading-relaxed whitespace-pre-line">
                  {project.description}
                </p>
              </div>
            )}
          </div>

          {/* Sağ Kolon */}
          <div className="lg:col-span-4 space-y-8">
            {/* İletişim Bilgileri */}
            {project?.contact && (
              <div className="bg-beyaz rounded-2xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">
                  İletişim Bilgileri
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center gap-3 text-grafit-gri">
                    <FaUsers className="w-5 h-5" />
                    <span>{project.contact.name}</span>
                  </div>
                  <div className="flex items-center gap-3 text-grafit-gri">
                    <FaEnvelope className="w-5 h-5" />
                    <span>{project.contact.email}</span>
                  </div>
                  <div className="flex items-center gap-3 text-grafit-gri">
                    <FaPhone className="w-5 h-5" />
                    <span>{project.contact.phone}</span>
                  </div>
                </div>
              </div>
            )}

            {/* Ek Hizmetler */}
            {project?.additionalServices && Object.values(project.additionalServices).some(service => service) && (
              <div className="bg-beyaz rounded-2xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">
                  Ek Hizmetler
                </h2>
                <div className="space-y-4">
                  {project.additionalServices.accommodation && (
                    <div className="flex items-center gap-3 text-grafit-gri">
                      <span>• Konaklama: {project.additionalServices.accommodation}</span>
                    </div>
                  )}
                  {project.additionalServices.meals && (
                    <div className="flex items-center gap-3 text-grafit-gri">
                      <span>• Yemek: {project.additionalServices.meals}</span>
                    </div>
                  )}
                  {project.additionalServices.transportation && (
                    <div className="flex items-center gap-3 text-grafit-gri">
                      <span>• Ulaşım: {project.additionalServices.transportation}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Ödeme Detayları */}
            {project?.paymentDetails && (
              <div className="bg-beyaz rounded-2xl p-6 shadow-sm">
                <h2 className="text-xl font-bold text-yogun-antrasit mb-6">
                  Ödeme Detayları
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center gap-3 text-grafit-gri">
                    <span>Ödeme Yöntemi: {
                      project.paymentDetails.method === 'cash' ? 'Nakit' :
                      project.paymentDetails.method === 'bank' ? 'Banka Transferi' :
                      project.paymentDetails.method
                    }</span>
                  </div>
                  <div className="flex items-center gap-3 text-grafit-gri">
                    <span>Ödeme Vadesi: {
                      project.paymentDetails.term === 'immediate' ? 'Peşin' :
                      project.paymentDetails.term === '30days' ? '30 Gün' :
                      project.paymentDetails.term === '60days' ? '60 Gün' :
                      project.paymentDetails.term
                    }</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailPage;
