import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, remove } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaUser, FaTrash, FaFileExcel, FaSearch } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import * as XLSX from 'xlsx';

const db = getDatabase();

const calculateAge = (birthDate) => {
  if (!birthDate) return null;
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
};

function SelectedUsersDesktop() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchSelectedUsers = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const selectedPlayersRef = ref(db, `users/${user.uid}/selectedPlayers`);
        const snapshot = await get(selectedPlayersRef);
        
        if (snapshot.exists()) {
          const selectedPlayers = Object.values(snapshot.val());
          setSelectedUsers(selectedPlayers);
        }
      } catch (error) {
        console.error("Veri çekerken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSelectedUsers();
  }, [user, navigate]);

  const handleDeleteUser = async (userId, e) => {
    e.stopPropagation();
    try {
      await remove(ref(db, `users/${user.uid}/selectedPlayers/${userId}`));
      setSelectedUsers(prev => prev.filter(user => user.id !== userId));
    } catch (error) {
      console.error("Silme işlemi başarısız:", error);
    }
  };

  const handleClearAll = async () => {
    if (window.confirm('Tüm seçili oyuncuları silmek istediğinize emin misiniz?')) {
      try {
        await remove(ref(db, `users/${user.uid}/selectedPlayers`));
        setSelectedUsers([]);
      } catch (error) {
        console.error("Toplu silme işlemi başarısız:", error);
      }
    }
  };

  const handleExportToExcel = () => {
    const excelData = selectedUsers.map((user, index) => ({
      'Sıra No': index + 1,
      'Ad Soyad': `${user.firstName} ${user.lastName}`,
      'TC Kimlik': user.tcno || 'Belirtilmemiş',
      'Pozisyon': user.position || 'Belirtilmemiş',
      'Yaş': user.birthDate ? calculateAge(user.birthDate) : 'Belirtilmemiş',
      'Şehir': user.city || 'Belirtilmemiş',
      'Telefon': user.phone || 'Belirtilmemiş'
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Seçili Oyuncular");
    XLSX.writeFile(wb, `secili_oyuncular_${new Date().toLocaleDateString('tr-TR')}.xlsx`);
  };

  const filteredUsers = selectedUsers.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-kirik-beyaz via-beyaz to-kirik-beyaz">
        <FaSpinner className="animate-spin text-5xl text-grafit-gri" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-kirik-beyaz via-beyaz to-kirik-beyaz">
      {/* Üst Bar */}
      <div className="bg-beyaz/80 backdrop-blur-lg border-b border-gray-200/50">
        <div className="max-w-7xl mx-auto px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-6">
              <button
                onClick={() => navigate(-1)}
                className="w-12 h-12 flex items-center justify-center rounded-xl hover:bg-kirik-beyaz active:bg-kirik-beyaz/70 transition-all"
              >
                <FaArrowLeft className="text-yogun-antrasit" size={20} />
              </button>

              <h1 className="text-2xl font-semibold text-yogun-antrasit">
                Seçilen Oyuncular ({filteredUsers.length})
              </h1>
            </div>

            <div className="flex items-center gap-4">
              {selectedUsers.length > 0 && (
                <>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Oyuncu ara..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-80 py-3 px-5 pl-12 rounded-xl bg-kirik-beyaz/50 text-sm focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/10 transition-all placeholder:text-grafit-gri/50"
                    />
                    <FaSearch className="absolute left-4 top-1/2 -translate-y-1/2 text-grafit-gri/50" size={16} />
                  </div>

                  <button
                    onClick={handleExportToExcel}
                    className="flex items-center gap-2 px-6 py-3 rounded-xl bg-yogun-antrasit text-beyaz hover:bg-grafit-gri transition-all"
                  >
                    <FaFileExcel size={18} />
                    <span>Excel'e Aktar</span>
                  </button>

                  <button
                    onClick={handleClearAll}
                    className="flex items-center gap-2 px-6 py-3 rounded-xl border border-grafit-gri/20 text-grafit-gri hover:bg-kirik-beyaz transition-all"
                  >
                    <FaTrash size={16} />
                    <span>Tümünü Sil</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="max-w-7xl mx-auto px-8 py-8">
        {filteredUsers.length === 0 ? (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-grafit-gri">
            <div className="w-24 h-24 rounded-full bg-beyaz shadow-lg flex items-center justify-center mb-6">
              <FaUser size={40} className="opacity-30" />
            </div>
            <p className="text-xl font-medium">
              {searchTerm ? 'Aranan oyuncu bulunamadı.' : 'Henüz seçili oyuncu bulunmuyor.'}
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {filteredUsers.map((user) => (
              <div
                key={user.id}
                onClick={() => navigate(`/players/${user.id}`)}
                className="bg-beyaz/70 backdrop-blur-sm rounded-2xl p-6 shadow-sm hover:shadow-md active:bg-kirik-beyaz/50 transition-all relative group cursor-pointer"
              >
                <div className="flex items-center gap-6">
                  {user.profileImage ? (
                    <img
                      src={user.profileImage}
                      alt={user.firstName}
                      className="w-20 h-20 rounded-xl object-cover shadow-sm"
                    />
                  ) : (
                    <div className="w-20 h-20 rounded-xl bg-kirik-beyaz flex items-center justify-center shadow-sm">
                      <FaUser className="text-grafit-gri/30" size={32} />
                    </div>
                  )}
                  
                  <div className="flex-1 min-w-0">
                    <h3 className="text-lg font-semibold text-yogun-antrasit truncate">
                      {user.firstName} {user.lastName}
                    </h3>
                    {user.position && (
                      <p className="text-grafit-gri truncate mt-1">
                        {user.position}
                      </p>
                    )}
                    <div className="flex items-center gap-3 mt-2 text-sm text-grafit-gri/70">
                      {user.birthDate && (
                        <span>{calculateAge(user.birthDate)} yaş</span>
                      )}
                      {user.city && (
                        <>
                          <span className="w-1 h-1 rounded-full bg-grafit-gri/30" />
                          <span>{user.city}</span>
                        </>
                      )}
                    </div>
                  </div>

                  <button
                    onClick={(e) => handleDeleteUser(user.id, e)}
                    className="w-12 h-12 flex items-center justify-center rounded-xl hover:bg-kirik-beyaz/70 active:bg-kirik-beyaz transition-all opacity-0 group-hover:opacity-100"
                  >
                    <FaTrash className="text-grafit-gri" size={18} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectedUsersDesktop; 