import React from 'react';

const AlertModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-yogun-antrasit bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-kirik-beyaz rounded-2xl p-6 max-w-sm w-full mx-4 shadow-lg border border-grafit-gri transform transition-all duration-200 ease-in-out"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex flex-col items-center text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yogun-antrasit mb-4">
            <svg 
              className="h-6 w-6 text-kirik-beyaz" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
              />
            </svg>
          </div>
          <h3 className="text-lg font-medium text-yogun-antrasit mb-2">
            Bilgi
          </h3>
          <p className="text-sm text-grafit-gri mb-6">
            {message}
          </p>
          <button
            onClick={onClose}
            className="w-full inline-flex justify-center rounded-xl border border-grafit-gri px-4 py-2.5 bg-yogun-antrasit text-sm font-medium text-kirik-beyaz hover:bg-grafit-gri focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grafit-gri transition-colors duration-200"
          >
            Anladım
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
