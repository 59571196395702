import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaSpinner, FaUser, FaEye, FaArrowLeft } from 'react-icons/fa';

const db = getDatabase();

function AdminApprovalMobile() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectingUserId, setRejectingUserId] = useState(null);

  useEffect(() => {
    const fetchPendingUsers = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const usersRef = ref(db, 'users');
        const snapshot = await get(usersRef);
        
        if (snapshot.exists()) {
          const users = [];
          snapshot.forEach((childSnapshot) => {
            const userData = childSnapshot.val();
            if (userData.profilecv?.approvalStatus === 'pending') {
              users.push({
                id: childSnapshot.key,
                ...userData
              });
            }
          });
          setPendingUsers(users);
        }
      } catch (error) {
        setError("Kullanıcılar yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchPendingUsers();
  }, [user, navigate]);

  const handleApproval = async (userId, isApproved) => {
    try {
      const userRef = ref(db, `users/${userId}/profilecv`);
      await update(userRef, {
        approvalStatus: isApproved ? 'approved' : 'rejected',
        approvalDate: new Date().toISOString()
      });
      setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    } catch (error) {
      setError("Onay işlemi sırasında bir hata oluştu.");
    }
  };

  const handleRejectClick = (userId) => {
    setRejectingUserId(userId);
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async () => {
    if (!rejectReason.trim()) {
      setError("Lütfen red sebebini belirtin");
      return;
    }

    try {
      const userRef = ref(db, `users/${rejectingUserId}/profilecv`);
      await update(userRef, {
        approvalStatus: 'rejected',
        approvalDate: new Date().toISOString(),
        rejectionReason: rejectReason
      });
      setPendingUsers(pendingUsers.filter(user => user.id !== rejectingUserId));
      setShowRejectModal(false);
      setRejectReason('');
      setRejectingUserId(null);
    } catch (error) {
      setError("Red işlemi sırasında bir hata oluştu.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-kirik-beyaz to-beyaz">
        <FaSpinner className="animate-spin text-4xl text-yogun-antrasit" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-kirik-beyaz to-beyaz">
      {/* Header */}
      <div className="bg-beyaz/80 backdrop-blur-lg border-b border-grafit-gri/10">
        <div className="flex items-center px-4 h-16">
          <button
            onClick={() => navigate(-1)}
            className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-kirik-beyaz active:bg-kirik-beyaz/70 transition-all"
          >
            <FaArrowLeft className="text-yogun-antrasit" size={20} />
          </button>
          <h1 className="flex-1 text-center text-lg font-semibold text-yogun-antrasit -ml-10">
            Hesap Onaylama ({pendingUsers.length})
          </h1>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="p-4 space-y-4">
        {error && (
          <div className="bg-kirmizi/10 text-kirmizi px-4 py-3 rounded-xl text-sm font-medium animate-fade-in">
            {error}
          </div>
        )}

        {pendingUsers.length === 0 ? (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-grafit-gri animate-fade-in">
            <div className="w-20 h-20 rounded-full bg-beyaz shadow-sm flex items-center justify-center mb-4">
              <FaUser size={32} className="opacity-30" />
            </div>
            <p className="text-center font-medium">Onay bekleyen kullanıcı bulunmamaktadır.</p>
          </div>
        ) : (
          <div className="space-y-4 animate-fade-in">
            {pendingUsers.map((user) => (
              <div
                key={user.id}
                className="bg-beyaz/70 backdrop-blur-sm rounded-2xl p-4 shadow-sm transition-all"
              >
                <div className="flex items-center gap-4">
                  {user.profileImage ? (
                    <img
                      src={user.profileImage}
                      alt={user.firstName}
                      className="w-16 h-16 rounded-xl object-cover shadow-sm"
                    />
                  ) : (
                    <div className="w-16 h-16 rounded-xl bg-kirik-beyaz flex items-center justify-center shadow-sm">
                      <FaUser className="text-grafit-gri/30" size={24} />
                    </div>
                  )}
                  
                  <div className="flex-1 min-w-0">
                    <h3 className="font-semibold text-yogun-antrasit truncate">
                      {user.firstName} {user.lastName}
                    </h3>
                    <p className="text-sm text-grafit-gri truncate mt-0.5">
                      {user.email}
                    </p>
                    <div className="flex items-center gap-2 mt-2">
                      <button
                        onClick={() => navigate(`/players/${user.id}`)}
                        className="text-xs px-3 py-1.5 rounded-lg bg-kirik-beyaz text-grafit-gri hover:bg-grafit-gri/10 active:bg-grafit-gri/20 transition-all"
                      >
                        Profili Görüntüle
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex gap-2 mt-4 pt-4 border-t border-grafit-gri/10">
                  <button
                    onClick={() => handleApproval(user.id, true)}
                    className="flex-1 py-3 rounded-xl bg-yesil/10 text-yesil text-sm font-medium hover:bg-yesil/20 active:bg-yesil/30 transition-all"
                  >
                    Onayla
                  </button>
                  <button
                    onClick={() => handleRejectClick(user.id)}
                    className="flex-1 py-3 rounded-xl bg-kirmizi/10 text-kirmizi text-sm font-medium hover:bg-kirmizi/20 active:bg-kirmizi/30 transition-all"
                  >
                    Reddet
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Red Modalı */}
      {showRejectModal && (
        <div className="fixed inset-0 bg-yogun-antrasit/50 flex items-end z-50 animate-fade-in">
          <div className="bg-beyaz w-full rounded-t-3xl p-6 animate-slide-up">
            <h3 className="text-lg font-semibold text-yogun-antrasit mb-4">
              Red Sebebi
            </h3>
            <textarea
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              placeholder="Lütfen red sebebini belirtin..."
              className="w-full p-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit placeholder:text-grafit-gri/50 min-h-[120px] mb-4 resize-none focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/10 transition-all"
            />
            <div className="flex gap-3">
              <button
                onClick={() => {
                  setShowRejectModal(false);
                  setRejectReason('');
                  setRejectingUserId(null);
                }}
                className="flex-1 py-3 rounded-xl bg-kirik-beyaz text-yogun-antrasit font-medium hover:bg-grafit-gri/10 active:bg-grafit-gri/20 transition-all"
              >
                İptal
              </button>
              <button
                onClick={handleRejectConfirm}
                className="flex-1 py-3 rounded-xl bg-kirmizi text-beyaz font-medium hover:bg-kirmizi/90 active:bg-kirmizi/80 transition-all"
              >
                Reddet
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminApprovalMobile; 