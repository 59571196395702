import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaBullhorn, 
  FaChevronRight, 
  FaExclamationCircle, 
  FaCog,
  FaUsers,
  FaUserTie,
  FaTheaterMasks,
  FaFilter
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const DuyurularPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('all');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.uid) {
      navigate('/login');
      return;
    }

    const announcementsRef = ref(db, 'announcements');
    const unsubscribe = onValue(announcementsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const announcementsList = Object.entries(data)
          .map(([id, announcement]) => ({
            id,
            ...announcement,
            read: announcement.readers ? announcement.readers.includes(user.uid) : false
          }))
          .filter(announcement => announcement.status === 'active')
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setAnnouncements(announcementsList);
      } else {
        setAnnouncements([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user, navigate]);

  const getTypeIcon = (type) => {
    switch (type) {
      case 'urgent':
        return <FaExclamationCircle className="w-5 h-5 text-kirmizi" />;
      case 'maintenance':
        return <FaCog className="w-5 h-5 text-turuncu" />;
      default:
        return <FaBullhorn className="w-5 h-5 text-yesil" />;
    }
  };

  const getAudienceIcon = (audience) => {
    switch (audience) {
      case 'actors':
        return <FaTheaterMasks className="w-4 h-4" />;
      case 'clients':
        return <FaUserTie className="w-4 h-4" />;
      default:
        return <FaUsers className="w-4 h-4" />;
    }
  };

  const filteredAnnouncements = selectedType === 'all' 
    ? announcements 
    : announcements.filter(a => a.type === selectedType);

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-20">
      {/* Header */}
      <div className="sticky top-0 bg-beyaz z-10 shadow-sm">
        <div className="flex items-center justify-between p-4">
          <h1 className="text-xl font-semibold text-yogun-antrasit flex items-center gap-2">
            <FaBullhorn className="w-5 h-5" />
            Duyurular
          </h1>
          <div className="flex items-center gap-2">
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="px-3 py-2 text-sm bg-kirik-beyaz rounded-xl border-none focus:ring-0"
            >
              <option value="all">Tümü</option>
              <option value="urgent">Acil</option>
              <option value="maintenance">Bakım</option>
              <option value="general">Genel</option>
            </select>
          </div>
        </div>
      </div>

      {/* Duyuru Listesi */}
      <div className="px-4 py-4 space-y-3">
        {filteredAnnouncements.length > 0 ? (
          filteredAnnouncements.map((announcement) => (
            <div
              key={announcement.id}
              onClick={() => navigate(`/announcement/${announcement.id}`)}
              className="bg-beyaz rounded-xl p-4 shadow-sm active:scale-98 transition-transform"
            >
              <div className="flex items-start gap-4">
                <div className="shrink-0 bg-kirik-beyaz p-3 rounded-xl">
                  {getTypeIcon(announcement.type)}
                </div>
                
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2 mb-1">
                    <h3 className="font-medium text-yogun-antrasit truncate">
                      {announcement.title}
                    </h3>
                    {!announcement.read && (
                      <span className="w-2 h-2 bg-mavi rounded-full shrink-0" />
                    )}
                  </div>

                  <p className="text-sm text-grafit-gri line-clamp-2 mb-2">
                    {announcement.description}
                  </p>

                  <div className="flex items-center justify-between text-xs text-grafit-gri">
                    <div className="flex items-center gap-4">
                      <span>
                        {format(new Date(announcement.createdAt), 'd MMM yyyy', { locale: tr })}
                      </span>
                      <div className="flex items-center gap-1">
                        {getAudienceIcon(announcement.targetAudience)}
                        <span>
                          {announcement.targetAudience === 'actors' 
                            ? 'Oyuncular' 
                            : announcement.targetAudience === 'clients' 
                              ? 'İşverenler' 
                              : 'Herkes'}
                        </span>
                      </div>
                    </div>
                    <FaChevronRight className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center py-12">
            <FaBullhorn className="text-grafit-gri w-12 h-12 mb-4" />
            <p className="text-grafit-gri text-center">
              {selectedType === 'all' 
                ? 'Henüz duyuru bulunmuyor.'
                : 'Bu kategoride duyuru bulunmuyor.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DuyurularPage;
