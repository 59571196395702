import React from 'react';
import FormField from '../common/FormField';
import { formConfig } from '../../config/formConfig';

const PhysicalInfoForm = ({ formData, handleInputChange, errors }) => {
  return (
    <div className="space-y-6">
      {formConfig.physical.fields.map(field => (
        <FormField
          key={field.name}
          {...field}
          value={formData.physical[field.name]}
          onChange={(value) => handleInputChange('physical', field.name, value)}
          error={errors[`physical.${field.name}`]}
        />
      ))}
    </div>
  );
};

export default PhysicalInfoForm; 