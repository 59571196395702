import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaCheckCircle, 
  FaTimesCircle, 
  FaSpinner, 
  FaArrowLeft, 
  FaClock, 
  FaExclamationCircle 
} from 'react-icons/fa';

const db = getDatabase();

function ProfileApproval() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}/profilecv`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setProfileData(snapshot.val());
          }
        } catch (error) {
          console.error("Profil verisi çekilirken hata oluştu:", error);
          setError("Profil bilgileri yüklenirken bir hata oluştu.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfileData();
  }, [user]);

  const isProfileComplete = () => {
    return profileData && 
           profileData.globalcv && 
           profileData.kolaj && 
           profileData.introVideo;
  };

  const handleSubmitForApproval = async () => {
    if (!isProfileComplete()) {
      setError("Lütfen tüm zorunlu alanları doldurun.");
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      const userRef = ref(db, `users/${user.uid}/profilecv`);
      await update(userRef, {
        approvalStatus: 'review',
        approvalRequestDate: new Date().toISOString()
      });
      alert("Profiliniz onay için gönderildi. Yönetici incelemesinden sonra size bilgi verilecektir.");
      navigate('/profile');
    } catch (error) {
      console.error("Onay gönderilirken hata oluştu:", error);
      setError("Onay gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    } finally {
      setSubmitting(false);
    }
  };

  const Header = () => (
    <div className="bg-beyaz border-b border-gray-200 mb-8">
      <div className="max-w-7xl mx-auto px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-4">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-kirik-beyaz rounded-lg transition-colors"
            >
              <FaArrowLeft className="w-5 h-5 text-yogun-antrasit" />
            </button>
            <h1 className="text-2xl font-semibold text-yogun-antrasit">
              Profil Onayı
            </h1>
          </div>
        </div>
      </div>
    </div>
  );

  const StatusCard = ({ status, date, reason }) => {
    const statusConfig = {
      approved: {
        icon: <FaCheckCircle className="text-yesil w-6 h-6" />,
        title: 'Hesabınız onaylanmıştır!',
        color: 'text-yesil'
      },
      review: {
        icon: <FaClock className="text-turuncu w-6 h-6" />,
        title: 'Hesabınız inceleniyor',
        color: 'text-turuncu'
      },
      rejected: {
        icon: <FaExclamationCircle className="text-kirmizi w-6 h-6" />,
        title: 'Hesabınız onaylanmadı',
        color: 'text-kirmizi'
      }
    };

    const config = statusConfig[status];
    if (!config) return null;

    return (
      <div className="bg-beyaz rounded-2xl p-6 shadow-sm mb-6">
        <div className="flex items-start gap-4">
          {config.icon}
          <div>
            <h3 className={`text-lg font-medium ${config.color}`}>{config.title}</h3>
            {date && (
              <p className="text-grafit-gri mt-2">
                {status === 'review' ? 'Başvuru' : status === 'approved' ? 'Onaylanma' : 'Red'} 
                Tarihi: {new Date(date).toLocaleDateString('tr-TR')}
              </p>
            )}
            {reason && (
              <p className="text-grafit-gri mt-2">
                Red Sebebi: {reason}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ProfileItem = ({ label, completed }) => (
    <div className="flex items-center justify-between p-5 bg-kirik-beyaz rounded-xl hover:bg-gray-100 transition-colors">
      <span className="text-lg text-yogun-antrasit font-medium">{label}</span>
      {completed ? (
        <FaCheckCircle className="text-yesil w-6 h-6" />
      ) : (
        <FaTimesCircle className="text-grafit-gri w-6 h-6" />
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-beyaz">
        <FaSpinner className="animate-spin w-10 h-10 text-yogun-antrasit" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      <Header />
      
      <div className="max-w-3xl mx-auto px-8 pb-20">
        {profileData?.approvalStatus && profileData.approvalStatus !== 'pending' && (
          <StatusCard 
            status={profileData.approvalStatus}
            date={profileData.approvalStatus === 'review' 
              ? profileData.approvalRequestDate 
              : profileData.approvalDate}
            reason={profileData.rejectionReason}
          />
        )}

        <div className="bg-beyaz rounded-2xl p-6 shadow-sm">
          <div className="space-y-4 mb-8">
            <ProfileItem
              label="CV"
              completed={!!profileData?.globalcv}
            />
            <ProfileItem
              label="Kolaj"
              completed={!!profileData?.kolaj}
            />
            <ProfileItem
              label="Tanıtım Videosu"
              completed={!!profileData?.introVideo}
            />
          </div>

          {error && (
            <p className="text-kirmizi mb-6">{error}</p>
          )}

          <button
            onClick={handleSubmitForApproval}
            disabled={!isProfileComplete() || submitting || 
              ['approved', 'review'].includes(profileData?.approvalStatus)}
            className={`w-full py-4 px-6 rounded-xl font-medium text-base
              ${isProfileComplete() && !submitting && 
                !['approved', 'review'].includes(profileData?.approvalStatus)
                ? 'bg-yogun-antrasit text-beyaz hover:bg-grafit-gri'
                : 'bg-kirik-beyaz text-grafit-gri cursor-not-allowed'
              } transition-colors duration-200`}
          >
            {submitting && <FaSpinner className="animate-spin inline mr-3 w-5 h-5" />}
            {profileData?.approvalStatus === 'approved' 
              ? 'Hesap Onaylandı' 
              : profileData?.approvalStatus === 'review'
              ? 'İnceleniyor'
              : profileData?.approvalStatus === 'rejected'
              ? 'Tekrar Başvur'
              : 'Onaya Gönder'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileApproval; 