import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { ref, onValue, remove, update } from 'firebase/database';
import { HiOutlinePencil, HiOutlineTrash, HiOutlinePlus, HiChevronRight, HiChevronLeft } from 'react-icons/hi';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

// Tarih formatlama fonksiyonunu ayrı tanımlayalım
const formatDate = (dateString) => {
  try {
    if (!dateString) return 'Tarih belirtilmemiş';
    
    const date = new Date(dateString);
    // Geçerli bir tarih mi kontrol edelim
    if (isNaN(date.getTime())) return 'Geçersiz tarih';
    
    return format(date, 'd MMMM yyyy', { locale: tr });
  } catch (error) {
    console.error('Tarih formatlama hatası:', error);
    return 'Tarih formatlanamadı';
  }
};

const ProjectManageMobile = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const projectsRef = ref(db, 'projects');
    const unsubscribe = onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const projectsList = Object.entries(data).map(([id, project]) => ({
          id,
          ...project
        }));
        setProjects(projectsList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      } else {
        setProjects([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleStatusToggle = async (project) => {
    try {
      const projectRef = ref(db, `projects/${project.id}`);
      await update(projectRef, {
        status: project.status === 'active' ? 'inactive' : 'active',
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Durum güncelleme hatası:', error);
      alert('Durum güncellenirken bir hata oluştu');
    }
  };

  const handleDelete = async () => {
    if (!selectedProject) return;

    try {
      const projectRef = ref(db, `projects/${selectedProject.id}`);
      await remove(projectRef);
      setShowDeleteModal(false);
      setSelectedProject(null);
    } catch (error) {
      console.error('Proje silme hatası:', error);
      alert('Proje silinirken bir hata oluştu');
    }
  };

  const renderHeader = () => (
    <div className="fixed top-0 left-0 right-0 bg-beyaz border-b border-kirik-beyaz z-40">
      <div className="flex items-center justify-between px-4 h-16">
        <div className="flex items-center gap-3">
          <button
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 text-yogun-antrasit rounded-lg"
          >
            <HiChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-semibold text-yogun-antrasit">Projeler</h1>
        </div>
        <button
          onClick={() => navigate('/admin/projects/add')}
          className="flex items-center gap-2 px-4 py-2 bg-yogun-antrasit text-beyaz rounded-xl text-sm font-medium"
        >
          <HiOutlinePlus className="w-5 h-5" />
          Yeni Proje
        </button>
      </div>
    </div>
  );

  const renderProjectCard = (project) => (
    <div 
      key={project.id}
      className="bg-beyaz rounded-xl shadow-sm overflow-hidden"
    >
      <div className="p-4">
        <div className="flex items-start justify-between gap-4">
          <div className="flex-1 min-w-0">
            <h3 className="font-medium text-yogun-antrasit truncate">
              {project.title}
            </h3>
            <p className="text-sm text-grafit-gri mt-1">
              {formatDate(project.date)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => navigate(`/admin/projects/edit/${project.id}`)}
              className="p-2 text-grafit-gri hover:text-yogun-antrasit rounded-lg"
            >
              <HiOutlinePencil className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                setSelectedProject(project);
                setShowDeleteModal(true);
              }}
              className="p-2 text-grafit-gri hover:text-kirmizi rounded-lg"
            >
              <HiOutlineTrash className="w-5 h-5" />
            </button>
          </div>
        </div>
        
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className={`w-2 h-2 rounded-full ${
              project.status === 'active' ? 'bg-yesil' : 'bg-grafit-gri'
            }`} />
            <span className="text-sm text-grafit-gri">
              {project.status === 'active' ? 'Aktif' : 'Pasif'}
            </span>
          </div>
          <button
            onClick={() => handleStatusToggle(project)}
            className={`px-3 py-1.5 rounded-lg text-sm font-medium ${
              project.status === 'active'
                ? 'bg-kirmizi/10 text-kirmizi'
                : 'bg-yesil/10 text-yesil'
            }`}
          >
            {project.status === 'active' ? 'Pasife Al' : 'Aktife Al'}
          </button>
        </div>
      </div>
      
      <div 
        onClick={() => navigate(`/admin/projects/${project.id}`)}
        className="flex items-center justify-between px-4 py-3 border-t border-kirik-beyaz bg-kirik-beyaz/50"
      >
        <span className="text-sm font-medium text-grafit-gri">
          Detayları Görüntüle
        </span>
        <HiChevronRight className="w-5 h-5 text-grafit-gri" />
      </div>
    </div>
  );

  const renderDeleteModal = () => {
    if (!showDeleteModal) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-end z-50">
        <div className="bg-beyaz w-full rounded-t-2xl p-6 animate-slide-up">
          <h3 className="text-lg font-semibold text-yogun-antrasit mb-2">
            Projeyi Sil
          </h3>
          <p className="text-grafit-gri mb-6">
            "{selectedProject?.title}" projesini silmek istediğinizden emin misiniz?
          </p>
          <div className="flex gap-3">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedProject(null);
              }}
              className="flex-1 py-3.5 border border-kirik-beyaz rounded-xl font-medium text-yogun-antrasit"
            >
              Vazgeç
            </button>
            <button
              onClick={handleDelete}
              className="flex-1 py-3.5 bg-kirmizi text-beyaz rounded-xl font-medium"
            >
              Sil
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-24">
      {renderHeader()}
      {renderDeleteModal()}
      
      <div className="pt-20 px-4">
        <div className="space-y-4">
          {projects.length > 0 ? (
            projects.map(project => renderProjectCard(project))
          ) : (
            <div className="bg-beyaz rounded-xl p-6 text-center">
              <p className="text-grafit-gri">Henüz proje eklenmemiş</p>
              <button
                onClick={() => navigate('/admin/projects/add')}
                className="mt-4 px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl text-sm font-medium"
              >
                İlk Projeyi Ekle
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectManageMobile;
