import React, { useEffect, useState } from 'react';
import { getDatabase, ref, query, orderByChild, equalTo, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaSearch, FaFilter, FaSpinner } from 'react-icons/fa';
import AlertModal from '../../components/mobile/AlertModal';
import FilterModal from '../../components/mobile/FilterModal';

const db = getDatabase();

const calculateAge = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  
  return age;
};

const maskName = (name, isAuthorized) => {
  if (isAuthorized) return name;
  
  const names = name.split(' ');
  return names.map(n => {
    if (n.length <= 2) return n;
    return n.slice(0, 2) + '*'.repeat(n.length - 2);
  }).join(' ');
};

const PlayersPage = () => {
  const [originalPlayers, setOriginalPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showFilterAlert, setShowFilterAlert] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (user?.uid) {
      const userRef = ref(db, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setUserRole(userData.role);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const playersRef = ref(db, 'users');
    const approvedPlayersQuery = query(
      playersRef,
      orderByChild('profilecv/approvalStatus'),
      equalTo('approved')
    );

    onValue(approvedPlayersQuery, (snapshot) => {
      const data = snapshot.val();
      const loadedPlayers = [];
      for (let id in data) {
        const player = data[id];
        if (player.profilecv?.approvalStatus === 'approved' && player.profilecv?.globalcv) {
          const globalcv = player.profilecv.globalcv;
          const personal = globalcv.personal;
          const physical = globalcv.physical;
          
          const age = calculateAge(personal.birthDate);

          loadedPlayers.push({
            id,
            name: personal.fullName,
            positions: personal.positions.map(pos => {
              switch(pos) {
                case 'actor': return 'Oyuncu';
                case 'model': return 'Model';
                case 'supporting_actor': return 'Yardımcı Oyuncu';
                default: return pos;
              }
            }).join(', '),
            imageUrl: player.profileImage || 'default_image_url',
            age,
            city: personal.city || 'Belirtilmemiş',
            gender: personal.gender,
            hairColor: physical.hairColor,
            height: physical.height,
            weight: physical.weight,
            bodySize: physical.bodySize,
            eyeColor: physical.eyeColor,
            shoeSize: physical.shoeSize
          });
        }
      }
      setOriginalPlayers(loadedPlayers);
      setFilteredPlayers(loadedPlayers);
      setLoading(false);
    });
  }, []);

  const handlePlayerClick = (id) => {
    if (!user) {
      setShowAlert(true);
      return;
    }

    const allowedRoles = ['admin', 'production', 'casting', 'agency'];
    
    if (!allowedRoles.includes(userRole)) {
      setShowAlert(true);
      return;
    }

    navigate(`/players/${id}`);
  };

  const handleApplyFilters = (filters) => {
    let result = [...originalPlayers];

    if (filters.ageRange.min || filters.ageRange.max) {
      result = result.filter(player => {
        if (filters.ageRange.min && player.age < parseInt(filters.ageRange.min)) return false;
        if (filters.ageRange.max && player.age > parseInt(filters.ageRange.max)) return false;
        return true;
      });
    }

    if (filters.gender) {
      result = result.filter(player => player.gender === filters.gender);
    }

    if (filters.hairColor) {
      result = result.filter(player => player.hairColor === filters.hairColor);
    }

    if (filters.height.min || filters.height.max) {
      result = result.filter(player => {
        const height = parseInt(player.height);
        if (filters.height.min && height < parseInt(filters.height.min)) return false;
        if (filters.height.max && height > parseInt(filters.height.max)) return false;
        return true;
      });
    }

    if (filters.city) {
      result = result.filter(player => 
        player.city.toLowerCase().includes(filters.city.toLowerCase())
      );
    }

    setFilteredPlayers(result);
  };

  const handleFilterClick = () => {
    if (!user || !['admin', 'production', 'casting', 'agency'].includes(userRole)) {
      setShowFilterAlert(true);
      return;
    }
    setShowFilterModal(true);
  };

  const searchedPlayers = filteredPlayers.filter(player =>
    player.name.toLowerCase().includes(searchText.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-kirik-beyaz">
        <FaSpinner className="animate-spin text-4xl text-yogun-antrasit" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="sticky top-0 bg-beyaz z-10 shadow-sm">
        <div className="flex justify-between items-center p-3">
          <h1 className="text-lg font-bold text-yogun-antrasit">
            Tüm Oyuncular
          </h1>
          <button 
            onClick={handleFilterClick}
            className={`p-1.5 rounded-full ${
              (!user || !['admin', 'production', 'casting', 'agency'].includes(userRole)) 
                ? 'text-grafit-gri'
                : 'text-yogun-antrasit'
            }`}
          >
            <FaFilter size={18} />
          </button>
        </div>

        {/* Search Bar */}
        <div className="px-3 pb-3">
          <div className="flex items-center bg-kirik-beyaz rounded-lg px-2">
            <FaSearch className="text-grafit-gri" size={14} />
            <input
              type="text"
              placeholder="Oyuncu ara"
              className="flex-1 bg-transparent border-none focus:ring-0 h-10 px-2 text-sm"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Players Grid */}
      <div className="grid grid-cols-3 gap-2 p-2">
        {searchedPlayers.map((player) => (
          <div
            key={player.id}
            onClick={() => handlePlayerClick(player.id)}
            className="bg-beyaz active:scale-98 transition-transform"
          >
            <div className="aspect-square p-1">
              <img
                src={player.imageUrl}
                alt={player.name}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <div className="p-1 text-center">
              <h3 className="font-medium text-yogun-antrasit truncate text-xs">
                {maskName(player.name, user?.role === 'admin' || user?.role === 'production')}
              </h3>
              <p className="text-[10px] text-grafit-gri">
                {player.age} yaş • {player.city}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Modals */}
      <FilterModal
        isOpen={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onApplyFilters={handleApplyFilters}
      />

      <AlertModal
        isOpen={showFilterAlert}
        onClose={() => setShowFilterAlert(false)}
        message="Filtreleme özelliğini kullanmak için yetkiniz bulunmamaktadır."
      />

      <AlertModal
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
        message={!user 
          ? "Oyuncu detaylarını görüntülemek için giriş yapmalısınız."
          : "Bu içeriği görüntülemek için casting, yapım veya ajans yetkisine sahip olmalısınız."}
      />
    </div>
  );
};

export default PlayersPage;
