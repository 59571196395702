import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import MobileRoutes from "./routes/mobileRoute";
import DesktopRoutes from "./routes/desktopRoute";
import { AuthProvider } from "./context/AuthContext";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="min-h-screen bg-gray-100">
          <main className="mx-auto ">
            {isMobile ? <MobileRoutes /> : <DesktopRoutes />}
          </main>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
