import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase/config';
import { ref, push } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { HiOutlineCamera, HiOutlineX, HiChevronLeft } from 'react-icons/hi';

const ProjectAddMobile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 8;
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    startTime: '',
    endTime: '',
    location: '',
    city: '',
    budget: '',
    projectType: '',
    status: 'active',
    duration: '',
    images: [],
    requirements: {
      gender: '',
      ageMin: '',
      ageMax: '',
      height: '',
      weight: '',
      hairColor: '',
      eyeColor: '',
      shoeSize: '',
      experience: ''
    },
    actorDetails: {
      men: 0,
      women: 0,
      children: 0,
      extras: 0
    },
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    paymentDetails: {
      method: '',
      term: ''
    },
    additionalServices: {
      transportation: '',
      accommodation: '',
      meals: ''
    },
    dresscode: '',
    categories: [],
    skills: []
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageUpload = async (e) => {
    try {
      setLoading(true);
      const files = Array.from(e.target.files);
      
      if (files.length + formData.images.length > 5) {
        alert('En fazla 5 görsel yükleyebilirsiniz');
        return;
      }

      const previewUrls = files.map(file => URL.createObjectURL(file));
      setFormData(prev => ({
        ...prev,
        images: [...prev.images, ...previewUrls]
      }));

      const uploadPromises = files.map(async (file, index) => {
        if (file.size > 5 * 1024 * 1024) {
          throw new Error('Her görsel en fazla 5MB olabilir');
        }

        const fileExtension = file.name.split('.').pop().toLowerCase();
        const validExtensions = ['jpg', 'jpeg', 'png', 'webp'];
        
        if (!validExtensions.includes(fileExtension)) {
          throw new Error('Sadece JPG, PNG ve WEBP formatları desteklenir');
        }

        const fileName = `projects/${Date.now()}_${Math.random().toString(36).substring(7)}.${fileExtension}`;
        const imageRef = storageRef(storage, fileName);
        
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);

        setFormData(prev => ({
          ...prev,
          images: prev.images.map((prevUrl, i) => 
            i === prev.images.length - files.length + index ? url : prevUrl
          )
        }));

        URL.revokeObjectURL(previewUrls[index]);
        return url;
      });

      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Görsel yükleme hatası:', error);
      alert(error.message || 'Görsel yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep < totalSteps) {
      setCurrentStep(prev => prev + 1);
      return;
    }

    setLoading(true);
    try {
      const projectRef = ref(db, 'projects');
      await push(projectRef, {
        ...formData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      setShowSuccess(true);
      setTimeout(() => {
        navigate('/admin/projects');
      }, 2000);
    } catch (error) {
      console.error('Proje ekleme hatası:', error);
      alert('Proje eklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const renderProgressBar = () => (
    <div className="fixed top-16 left-0 right-0 h-1 bg-kirik-beyaz">
      <div 
        className="h-full bg-yogun-antrasit transition-all duration-300"
        style={{ width: `${(currentStep / totalSteps) * 100}%` }}
      />
    </div>
  );

  const renderHeader = () => (
    <div className="fixed top-0 left-0 right-0 bg-beyaz z-50 border-b border-kirik-beyaz">
      <div className="flex items-center justify-between p-4">
        <button
          onClick={() => currentStep > 1 ? setCurrentStep(prev => prev - 1) : navigate(-1)}
          className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-kirik-beyaz"
        >
          <HiChevronLeft className="w-6 h-6 text-yogun-antrasit" />
        </button>
        <h1 className="text-lg font-bold text-yogun-antrasit">
          Yeni Proje ({currentStep}/{totalSteps})
        </h1>
        <div className="w-10" /> {/* Denge için boş div */}
      </div>
      {renderProgressBar()}
    </div>
  );

  const renderStepContent = () => {
    switch(currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Temel Bilgiler
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Proje Başlığı
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="Proje başlığını girin"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Proje Tipi
                </label>
                <select
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  required
                >
                  <option value="">Seçiniz</option>
                  <option value="film">Film</option>
                  <option value="dizi">Dizi</option>
                  <option value="reklam">Reklam</option>
                  <option value="katalog">Katalog</option>
                  <option value="diger">Diğer</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Proje Açıklaması
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows="4"
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="Proje detaylarını girin"
                  required
                />
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Zaman ve Lokasyon
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Tarih
                </label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Başlangıç Saati
                  </label>
                  <input
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Bitiş Saati
                  </label>
                  <input
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Şehir
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="Şehir"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Adres
                </label>
                <textarea
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  rows="3"
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="Tam adresi girin"
                  required
                />
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Oyuncu Gereksinimleri
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Cinsiyet
                </label>
                <select
                  name="requirements.gender"
                  value={formData.requirements.gender}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="male">Erkek</option>
                  <option value="female">Kadın</option>
                  <option value="both">Farketmez</option>
                </select>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Minimum Yaş
                  </label>
                  <input
                    type="number"
                    name="requirements.ageMin"
                    value={formData.requirements.ageMin}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="18"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Maksimum Yaş
                  </label>
                  <input
                    type="number"
                    name="requirements.ageMax"
                    value={formData.requirements.ageMax}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="65"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Deneyim
                </label>
                <select
                  name="requirements.experience"
                  value={formData.requirements.experience}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="beginner">Başlangıç</option>
                  <option value="intermediate">Orta</option>
                  <option value="advanced">İleri</option>
                  <option value="professional">Profesyonel</option>
                </select>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Oyuncu Sayıları
            </h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Erkek
                  </label>
                  <input
                    type="number"
                    name="actorDetails.men"
                    value={formData.actorDetails.men}
                    onChange={handleInputChange}
                    min="0"
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Kadın
                  </label>
                  <input
                    type="number"
                    name="actorDetails.women"
                    value={formData.actorDetails.women}
                    onChange={handleInputChange}
                    min="0"
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Çocuk
                  </label>
                  <input
                    type="number"
                    name="actorDetails.children"
                    value={formData.actorDetails.children}
                    onChange={handleInputChange}
                    min="0"
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Ekstra
                  </label>
                  <input
                    type="number"
                    name="actorDetails.extras"
                    value={formData.actorDetails.extras}
                    onChange={handleInputChange}
                    min="0"
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Proje Görselleri
            </h2>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-4">
                {formData.images.map((url, index) => (
                  <div key={index} className="relative group">
                    <img 
                      src={url} 
                      alt="" 
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          images: prev.images.filter((_, i) => i !== index)
                        }))
                      }}
                      className="absolute -top-2 -right-2 p-1 bg-black text-white rounded-full 
                        opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <HiOutlineX size={16} />
                    </button>
                  </div>
                ))}
                {formData.images.length < 5 && !loading && (
                  <label className="w-24 h-24 flex items-center justify-center border-2 border-dashed 
                    border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors">
                    <input
                      type="file"
                      accept="image/jpeg,image/png,image/webp"
                      multiple
                      onChange={handleImageUpload}
                      className="hidden"
                    />
                    <HiOutlineCamera size={24} className="text-gray-400" />
                  </label>
                )}
              </div>
            </div>
          </div>
        );

      case 6:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Ödeme Detayları
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Bütçe (TL)
                </label>
                <input
                  type="number"
                  name="budget"
                  value={formData.budget}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="0"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Ödeme Yöntemi
                </label>
                <select
                  name="paymentDetails.method"
                  value={formData.paymentDetails.method}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="cash">Nakit</option>
                  <option value="bank">Banka Transferi</option>
                  <option value="both">Karma</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Ödeme Vadesi
                </label>
                <select
                  name="paymentDetails.term"
                  value={formData.paymentDetails.term}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="immediate">Çekim Günü</option>
                  <option value="15days">15 Gün</option>
                  <option value="30days">30 Gün</option>
                  <option value="45days">45 Gün</option>
                </select>
              </div>
            </div>
          </div>
        );

      case 7:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              Ek Hizmetler
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Ulaşım
                </label>
                <select
                  name="additionalServices.transportation"
                  value={formData.additionalServices.transportation}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="provided">Sağlanıyor</option>
                  <option value="notProvided">Sağlanmıyor</option>
                  <option value="partial">Kısmi Destek</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Konaklama
                </label>
                <select
                  name="additionalServices.accommodation"
                  value={formData.additionalServices.accommodation}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="provided">Sağlanıyor</option>
                  <option value="notProvided">Sağlanmıyor</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Yemek
                </label>
                <select
                  name="additionalServices.meals"
                  value={formData.additionalServices.meals}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                >
                  <option value="">Seçiniz</option>
                  <option value="provided">Sağlanıyor</option>
                  <option value="notProvided">Sağlanmıyor</option>
                  <option value="partial">Kısmi Destek</option>
                </select>
              </div>
            </div>
          </div>
        );

      case 8:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              İletişim Bilgileri
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  İletişim Kişisi
                </label>
                <input
                  type="text"
                  name="contact.name"
                  value={formData.contact.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="Ad Soyad"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  E-posta
                </label>
                <input
                  type="email"
                  name="contact.email"
                  value={formData.contact.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="ornek@email.com"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Telefon
                </label>
                <input
                  type="tel"
                  name="contact.phone"
                  value={formData.contact.phone}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                  placeholder="05XX XXX XX XX"
                />
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const renderSuccessModal = () => {
    if (!showSuccess) return null;
    
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-beyaz rounded-2xl p-8 max-w-sm w-full mx-4 animate-scale-up">
          <div className="w-20 h-20 mx-auto mb-6 rounded-full bg-yesil/10 flex items-center justify-center">
            <svg 
              className="w-10 h-10 text-yesil animate-check" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 13l4 4L19 7" 
              />
            </svg>
          </div>
          <p className="text-xl font-medium text-yogun-antrasit text-center">
            Proje başarıyla yayınlandı
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-[144px]">
      {renderHeader()}
      {renderProgressBar()}
      {renderSuccessModal()}
      
      <div className="pt-20 px-4">
        <form onSubmit={handleSubmit} className="space-y-6">
          {renderStepContent()}
          
          <div className="fixed bottom-[72px] left-0 right-0 bg-beyaz border-t border-kirik-beyaz p-4 z-50">
            <div className="flex gap-3">
              {currentStep < totalSteps ? (
                <button
                  type="button"
                  onClick={() => setCurrentStep(prev => prev + 1)}
                  className="flex-1 py-3.5 bg-yogun-antrasit text-beyaz rounded-xl font-medium active:scale-98 transition-all"
                >
                  Devam Et
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={loading}
                  className="flex-1 py-3.5 bg-yogun-antrasit text-beyaz rounded-xl font-medium active:scale-98 transition-all disabled:opacity-50"
                >
                  {loading ? 'Kaydediliyor...' : 'Projeyi Yayınla'}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectAddMobile;
