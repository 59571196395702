import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { MdEmail, MdLock, MdError, MdVisibility, MdVisibilityOff, MdPerson, MdPhone } from 'react-icons/md';
import { FcGoogle } from 'react-icons/fc';
import logo from '../../assets/logos.svg';
import { 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail 
} from 'firebase/auth';
import { 
  getDatabase, 
  ref, 
  set, 
  serverTimestamp 
} from 'firebase/database';
import { auth } from '../../firebase/config';

const db = getDatabase();

const AuthPage = () => {
  const [activeTab, setActiveTab] = useState('login'); // 'login' veya 'register'
  const navigate = useNavigate();
  const { login, googleLogin, sendPasswordResetEmail } = useAuth();

  // Login state'leri
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  // Register state'leri
  const [registerData, setRegisterData] = useState({
    fullName: '',
    email: '',
    phone: '',
    password: '',
    isUnder18: false,
    guardianName: '',
    guardianPhone: '',
    termsAccepted: false,
    privacyAccepted: false
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Diğer state'lerin yanına ekleyelim
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  // State'lere ekleyelim
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);

  // Handle fonksiyonlarını ekleyelim
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await login(loginData.email, loginData.password);
      navigate('/');
    } catch (error) {
      setError('E-posta veya şifre hatalı');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Form validasyonu
    if (!registerData.termsAccepted || !registerData.privacyAccepted) {
      setError('Lütfen kullanım koşullarını ve gizlilik sözleşmesini kabul edin.');
      return;
    }

    if (registerData.isUnder18 && (!registerData.guardianName || !registerData.guardianPhone)) {
      setError('18 yaşından küçükseniz veli bilgilerini doldurmak zorunludur');
      return;
    }

    setIsLoading(true);

    try {
      // Firebase Authentication ile kullanıcı oluştur
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerData.email,
        registerData.password
      );

      // Realtime Database'e kullanıcı bilgilerini kaydet
      const userRef = ref(db, `users/${userCredential.user.uid}`);
      await set(userRef, {
        fullName: registerData.fullName,
        email: registerData.email,
        phone: registerData.phone,
        isUnder18: registerData.isUnder18,
        guardianName: registerData.isUnder18 ? registerData.guardianName : null,
        guardianPhone: registerData.isUnder18 ? registerData.guardianPhone : null,
        createdAt: serverTimestamp(),
        role: 'user',
        profilecv: {
          approvalStatus: 'pending',
          createdAt: serverTimestamp()
        }
      });

      // Başarılı kayıt sonrası profil sayfasına yönlendir
      navigate('/profile');
    } catch (error) {
      console.error('Kayıt hatası:', error);
      
      // Hata mesajlarını Türkçeleştir
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('Bu e-posta adresi zaten kullanımda');
          break;
        case 'auth/invalid-email':
          setError('Geçersiz e-posta adresi');
          break;
        case 'auth/weak-password':
          setError('Şifre en az 6 karakter olmalıdır');
          break;
        case 'auth/operation-not-allowed':
          setError('E-posta/şifre girişi devre dışı bırakılmış');
          break;
        default:
          setError('Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRegisterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRegisterData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    if (input.startsWith('90')) {
      input = input.slice(2);
    }
    input = input.slice(0, 10);
    
    let formatted = '';
    if (input.length > 0) {
      formatted = '+90 ';
      if (input.length > 0) formatted += input.slice(0, 3);
      if (input.length > 3) formatted += ' ' + input.slice(3, 6);
      if (input.length > 6) formatted += ' ' + input.slice(6, 8);
      if (input.length > 8) formatted += ' ' + input.slice(8);
    }
    
    setRegisterData(prev => ({ ...prev, phone: formatted }));
  };

  const handleGoogleLogin = async () => {
    try {
      await googleLogin();
      navigate('/');
    } catch (error) {
      setError('Google ile giriş yapılırken bir hata oluştu');
    }
  };

  // Handler'ı ekleyelim
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(forgotEmail);
      setResetSuccess(true);
    } catch (error) {
      setError('Şifre sıfırlama e-postası gönderilemedi');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-6 bg-kirik-beyaz relative overflow-hidden">
      {/* Dekoratif Desenler */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Sol alt köşe deseni */}
        <div className="absolute -bottom-20 -left-20 w-64 h-64 bg-yogun-antrasit/10 rounded-full blur-3xl" />
        <div className="absolute -bottom-10 -left-10 w-48 h-48 bg-grafit-gri/10 rounded-full blur-2xl" />
        
        {/* Sağ üst köşe deseni */}
        <div className="absolute -top-20 -right-20 w-64 h-64 bg-yogun-antrasit/10 rounded-full blur-3xl" />
        <div className="absolute -top-10 -right-10 w-48 h-48 bg-grafit-gri/10 rounded-full blur-2xl" />
        
        {/* Orta kısım desenleri */}
        <div className="absolute top-1/2 left-0 w-32 h-32 bg-yogun-antrasit/5 rounded-full blur-2xl" />
        <div className="absolute top-1/3 right-0 w-32 h-32 bg-grafit-gri/5 rounded-full blur-2xl" />
        
        {/* Diagonal çizgiler */}
        <div className="absolute inset-0" style={{
          background: `repeating-linear-gradient(
            45deg,
            transparent,
            transparent 100px,
            rgba(74, 73, 74, 0.03) 100px,
            rgba(74, 73, 74, 0.03) 102px
          )`
        }} />
      </div>

      {/* Mevcut form içeriği */}
      <div className="w-full max-w-md relative"> {/* relative ekledik */}
        {/* Logo */}
        <div className="text-center mb-8">
          <img src={logo} alt="Klaket Logo" className="w-40 h-10 mx-auto" />
        </div>

        <div className="bg-beyaz/95 backdrop-blur-sm p-6 rounded-2xl shadow-lg"> {/* bg-beyaz/95 ve backdrop-blur-sm ekledik */}
          {/* Tab Başlıkları */}
          <div className="flex border-b border-gray-200 mb-6">
            <button
              onClick={() => setActiveTab('login')}
              className={`flex-1 py-3 text-sm font-medium transition-colors ${
                activeTab === 'login'
                  ? 'text-yogun-antrasit border-b-2 border-yogun-antrasit'
                  : 'text-grafit-gri hover:text-yogun-antrasit'
              }`}
            >
              Giriş Yap
            </button>
            <button
              onClick={() => setActiveTab('register')}
              className={`flex-1 py-3 text-sm font-medium transition-colors ${
                activeTab === 'register'
                  ? 'text-yogun-antrasit border-b-2 border-yogun-antrasit'
                  : 'text-grafit-gri hover:text-yogun-antrasit'
              }`}
            >
              Hesap Oluştur
            </button>
          </div>

          {/* Hata Mesajı */}
          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg mb-4">
              <div className="flex items-center">
                <MdError className="text-red-500 w-5 h-5 mr-2 flex-shrink-0" />
                <p className="text-red-700 text-sm">{error}</p>
              </div>
            </div>
          )}

          {/* Login Form */}
          {activeTab === 'login' && (
            <form onSubmit={handleLoginSubmit} className="space-y-4">
              {/* Email */}
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={loginData.email}
                  onChange={handleLoginChange}
                  className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                  placeholder="E-posta"
                  required
                />
                <MdEmail className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
              </div>

              {/* Şifre */}
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={loginData.password}
                  onChange={handleLoginChange}
                  className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                  placeholder="Şifre"
                  required
                />
                <MdLock className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-4 text-grafit-gri hover:text-yogun-antrasit transition-colors"
                >
                  {showPassword ? 
                    <MdVisibilityOff className="w-5 h-5" /> : 
                    <MdVisibility className="w-5 h-5" />
                  }
                </button>
              </div>

              {/* Şifremi Unuttum */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(true)}
                  className="text-sm text-yogun-antrasit hover:text-grafit-gri transition-colors"
                >
                  Şifremi unuttum
                </button>
              </div>

              {/* Giriş Yap Butonu */}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-3.5 px-4 rounded-xl text-beyaz bg-yogun-antrasit hover:bg-grafit-gri transition-all duration-200 disabled:opacity-50 font-medium text-sm shadow-lg shadow-yogun-antrasit/10"
              >
                {isLoading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
              </button>

              {/* Google ile Giriş */}
              <div className="relative my-4">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-beyaz text-grafit-gri">veya</span>
                </div>
              </div>

              <button
                type="button"
                onClick={handleGoogleLogin}
                className="w-full py-3.5 px-4 rounded-xl bg-beyaz border border-gray-200 text-grafit-gri hover:bg-kirik-beyaz transition-all duration-200 flex items-center justify-center font-medium text-sm"
              >
                <FcGoogle className="w-5 h-5 mr-2" />
                Google ile devam et
              </button>
            </form>
          )}

          {/* Register Form */}
          {activeTab === 'register' && (
            <form onSubmit={handleRegisterSubmit} className="space-y-4">
              {/* Ad Soyad */}
              <div className="relative">
                <input
                  type="text"
                  name="fullName"
                  value={registerData.fullName}
                  onChange={handleRegisterChange}
                  className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                  placeholder="Ad Soyad"
                  required
                />
                <MdPerson className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
              </div>

              {/* Email */}
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={registerData.email}
                  onChange={handleRegisterChange}
                  className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                  placeholder="E-posta"
                  required
                />
                <MdEmail className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
              </div>

              {/* Telefon */}
              <div className="relative">
                <input
                  type="tel"
                  name="phone"
                  value={registerData.phone}
                  onChange={handlePhoneChange}
                  className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                  placeholder="Telefon"
                  required
                />
                <MdPhone className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
              </div>

              {/* Şifre */}
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={registerData.password}
                  onChange={handleRegisterChange}
                  className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                  placeholder="Şifre"
                  required
                />
                <MdLock className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-4 text-grafit-gri hover:text-yogun-antrasit transition-colors"
                >
                  {showPassword ? 
                    <MdVisibilityOff className="w-5 h-5" /> : 
                    <MdVisibility className="w-5 h-5" />
                  }
                </button>
              </div>

              {/* 18 yaş altı checkbox */}
              <div className="space-y-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="isUnder18"
                    checked={registerData.isUnder18}
                    onChange={handleRegisterChange}
                    className="w-4 h-4 rounded border-gray-300 text-yogun-antrasit focus:ring-yogun-antrasit/20"
                  />
                  <span className="text-sm text-grafit-gri">
                    18 yaşından küçüğüm
                  </span>
                </label>

                {/* Veli bilgileri */}
                {registerData.isUnder18 && (
                  <div className="space-y-4 pl-4 border-l-2 border-gray-200">
                    <div className="relative">
                      <input
                        type="text"
                        name="guardianName"
                        value={registerData.guardianName}
                        onChange={handleRegisterChange}
                        className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                        placeholder="Veli Ad Soyad"
                        required={registerData.isUnder18}
                      />
                      <MdPerson className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
                    </div>

                    <div className="relative">
                      <input
                        type="tel"
                        name="guardianPhone"
                        value={registerData.guardianPhone}
                        onChange={handlePhoneChange}
                        className="pl-12 w-full py-3.5 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all duration-200 bg-beyaz"
                        placeholder="Veli Telefon"
                        required={registerData.isUnder18}
                      />
                      <MdPhone className="absolute left-4 top-4 text-grafit-gri w-5 h-5" />
                    </div>
                  </div>
                )}

                {/* Sözleşmeler */}
                <div className="space-y-3">
                  <label className="flex items-start gap-2">
                    <input
                      type="checkbox"
                      name="termsAccepted"
                      checked={registerData.termsAccepted}
                      onChange={handleRegisterChange}
                      className="mt-1 w-4 h-4 rounded border-gray-300 text-yogun-antrasit focus:ring-yogun-antrasit/20"
                    />
                    <span className="text-sm text-grafit-gri">
                      <a 
                        href="https://klaket.tr/kullanim-kosullari"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-yogun-antrasit underline"
                      >
                        Kullanım koşullarını
                      </a>
                      {' '}okudum ve kabul ediyorum
                    </span>
                  </label>

                  <label className="flex items-start gap-2">
                    <input
                      type="checkbox"
                      name="privacyAccepted"
                      checked={registerData.privacyAccepted}
                      onChange={handleRegisterChange}
                      className="mt-1 w-4 h-4 rounded border-gray-300 text-yogun-antrasit focus:ring-yogun-antrasit/20"
                    />
                    <span className="text-sm text-grafit-gri">
                      <a 
                        href="https://klaket.tr/gizlilik-sozlesmesi"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-yogun-antrasit underline"
                      >
                        Gizlilik sözleşmesini
                      </a>
                      {' '}okudum ve kabul ediyorum
                    </span>
                  </label>
                </div>
              </div>

              {/* Kayıt Ol Butonu */}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-3.5 px-4 rounded-xl text-beyaz bg-yogun-antrasit hover:bg-grafit-gri transition-all duration-200 disabled:opacity-50 font-medium text-sm shadow-lg shadow-yogun-antrasit/10"
              >
                {isLoading ? 'Kayıt yapılıyor...' : 'Kayıt Ol'}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
