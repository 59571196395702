import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { ref, onValue, remove, update, push } from 'firebase/database';
import { HiOutlinePencil, HiOutlineTrash, HiOutlinePlus, HiX, HiOutlineSearch } from 'react-icons/hi';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const AnnouncementManage = () => {
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: 'general',
    status: 'active',
    startDate: '',
    endDate: '',
    link: '',
    targetAudience: 'all'
  });

  useEffect(() => {
    const announcementRef = ref(db, 'announcements');
    onValue(announcementRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const announcementsData = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setAnnouncements(announcementsData.sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        ));
      } else {
        setAnnouncements([]);
      }
      setLoading(false);
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleStatusToggle = async (announcement) => {
    try {
      const announcementRef = ref(db, `announcements/${announcement.id}`);
      await update(announcementRef, {
        status: announcement.status === 'active' ? 'inactive' : 'active',
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Durum güncelleme hatası:', error);
      alert('Durum güncellenirken bir hata oluştu');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (selectedAnnouncement) {
        // Güncelleme
        const announcementRef = ref(db, `announcements/${selectedAnnouncement.id}`);
        await update(announcementRef, {
          ...formData,
          updatedAt: new Date().toISOString()
        });
      } else {
        // Yeni ekleme
        const announcementRef = ref(db, 'announcements');
        await push(announcementRef, {
          ...formData,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      }
      setShowAddForm(false);
      setSelectedAnnouncement(null);
      resetForm();
    } catch (error) {
      console.error('Duyuru işlem hatası:', error);
      alert('İşlem sırasında bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedAnnouncement) return;

    try {
      const announcementRef = ref(db, `announcements/${selectedAnnouncement.id}`);
      await remove(announcementRef);
      setShowDeleteModal(false);
      setSelectedAnnouncement(null);
    } catch (error) {
      console.error('Duyuru silme hatası:', error);
      alert('Duyuru silinirken bir hata oluştu');
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      type: 'general',
      status: 'active',
      startDate: '',
      endDate: '',
      link: '',
      targetAudience: 'all'
    });
  };

  const filteredAnnouncements = announcements.filter(announcement => {
    const matchesSearch = announcement.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || announcement.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  const renderAddForm = () => {
    if (!showAddForm) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-beyaz rounded-2xl p-8 max-w-3xl w-full mx-4 max-h-[90vh] overflow-y-auto">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-yogun-antrasit">
              {selectedAnnouncement ? 'Duyuru Düzenle' : 'Yeni Duyuru'}
            </h2>
            <button
              onClick={() => {
                setShowAddForm(false);
                setSelectedAnnouncement(null);
                resetForm();
              }}
              className="p-2 text-yogun-antrasit rounded-lg hover:bg-kirik-beyaz transition-colors"
            >
              <HiX className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Duyuru Başlığı
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Başlık giriniz"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Duyuru İçeriği
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit min-h-[180px] resize-none"
                    placeholder="İçerik giriniz"
                    required
                  />
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Duyuru Tipi
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  >
                    <option value="general">Genel</option>
                    <option value="urgent">Acil</option>
                    <option value="maintenance">Bakım/Güncelleme</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Hedef Kitle
                  </label>
                  <select
                    name="targetAudience"
                    value={formData.targetAudience}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  >
                    <option value="all">Herkes</option>
                    <option value="actors">Sadece Oyuncular</option>
                    <option value="clients">Sadece İşverenler</option>
                  </select>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Başlangıç Tarihi
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Bitiş Tarihi
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Bağlantı (Opsiyonel)
                  </label>
                  <input
                    type="url"
                    name="link"
                    value={formData.link}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="https://"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-3 pt-4">
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setSelectedAnnouncement(null);
                  resetForm();
                }}
                className="px-6 py-3 border border-kirik-beyaz rounded-xl font-medium text-yogun-antrasit hover:bg-kirik-beyaz transition-colors"
              >
                Vazgeç
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl font-medium hover:bg-black transition-colors disabled:opacity-50 disabled:hover:bg-yogun-antrasit"
              >
                {loading ? 'Kaydediliyor...' : selectedAnnouncement ? 'Güncelle' : 'Yayınla'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const renderDeleteModal = () => {
    if (!showDeleteModal) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-beyaz rounded-2xl p-8 max-w-md w-full mx-4">
          <h3 className="text-xl font-semibold text-yogun-antrasit mb-2">
            Duyuruyu Sil
          </h3>
          <p className="text-grafit-gri mb-6">
            "{selectedAnnouncement?.title}" duyurusunu silmek istediğinizden emin misiniz?
          </p>
          <div className="flex gap-3">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedAnnouncement(null);
              }}
              className="flex-1 py-3 border border-kirik-beyaz rounded-xl font-medium text-yogun-antrasit hover:bg-kirik-beyaz"
            >
              Vazgeç
            </button>
            <button
              onClick={handleDelete}
              className="flex-1 py-3 bg-kirmizi text-beyaz rounded-xl font-medium hover:bg-kirmizi/90"
            >
              Sil
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz p-8">
      {renderAddForm()}
      {renderDeleteModal()}

      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-semibold text-yogun-antrasit">
            Duyurular
          </h1>
          <button
            onClick={() => {
              resetForm();
              setShowAddForm(true);
            }}
            className="flex items-center gap-2 px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl font-medium hover:bg-black transition-colors"
          >
            <HiOutlinePlus className="w-5 h-5" />
            Yeni Duyuru
          </button>
        </div>

        <div className="bg-beyaz rounded-2xl shadow-sm overflow-hidden">
          <div className="p-6 border-b border-kirik-beyaz">
            <div className="flex items-center gap-4">
              <div className="flex-1 relative">
                <input
                  type="text"
                  placeholder="Duyuru ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-12 pr-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                />
                <HiOutlineSearch className="w-5 h-5 text-grafit-gri absolute left-4 top-1/2 -translate-y-1/2" />
              </div>
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="px-4 py-3 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
              >
                <option value="all">Tüm Durumlar</option>
                <option value="active">Aktif</option>
                <option value="inactive">Pasif</option>
              </select>
            </div>
          </div>

          <div className="divide-y divide-kirik-beyaz">
            {filteredAnnouncements.length > 0 ? (
              filteredAnnouncements.map(announcement => (
                <div key={announcement.id} className="p-6">
                  <div className="flex items-start justify-between gap-4">
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-3 mb-2">
                        <h3 className="font-medium text-yogun-antrasit truncate">
                          {announcement.title}
                        </h3>
                        <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                          announcement.status === 'active' 
                            ? 'bg-yesil/10 text-yesil' 
                            : 'bg-grafit-gri/10 text-grafit-gri'
                        }`}>
                          {announcement.status === 'active' ? 'Aktif' : 'Pasif'}
                        </span>
                      </div>
                      <p className="text-grafit-gri line-clamp-2">
                        {announcement.description}
                      </p>
                      <div className="flex items-center gap-6 mt-3 text-sm text-grafit-gri">
                        <span>Oluşturulma: {format(new Date(announcement.createdAt), 'd MMM yyyy', { locale: tr })}</span>
                        {announcement.updatedAt && (
                          <span>Güncelleme: {format(new Date(announcement.updatedAt), 'd MMM yyyy', { locale: tr })}</span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleStatusToggle(announcement)}
                        className={`px-4 py-2 rounded-lg text-sm font-medium ${
                          announcement.status === 'active'
                            ? 'bg-kirmizi/10 text-kirmizi hover:bg-kirmizi/20'
                            : 'bg-yesil/10 text-yesil hover:bg-yesil/20'
                        }`}
                      >
                        {announcement.status === 'active' ? 'Pasife Al' : 'Aktife Al'}
                      </button>
                      <button
                        onClick={() => {
                          setSelectedAnnouncement(announcement);
                          setFormData(announcement);
                          setShowAddForm(true);
                        }}
                        className="p-2 text-grafit-gri hover:text-yogun-antrasit rounded-lg"
                      >
                        <HiOutlinePencil className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => {
                          setSelectedAnnouncement(announcement);
                          setShowDeleteModal(true);
                        }}
                        className="p-2 text-grafit-gri hover:text-kirmizi rounded-lg"
                      >
                        <HiOutlineTrash className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-8 text-center">
                <p className="text-grafit-gri">
                  {searchTerm || filterStatus !== 'all' 
                    ? 'Arama kriterlerine uygun duyuru bulunamadı' 
                    : 'Henüz duyuru eklenmemiş'}
                </p>
                {!searchTerm && filterStatus === 'all' && (
                  <button
                    onClick={() => setShowAddForm(true)}
                    className="mt-4 px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl text-sm font-medium hover:bg-black transition-colors"
                  >
                    İlk Duyuruyu Ekle
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementManage;
