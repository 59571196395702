import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaArrowLeft, 
  FaBullhorn, 
  FaExclamationCircle, 
  FaCog,
  FaUsers,
  FaUserTie,
  FaTheaterMasks,
  FaCalendar,
  FaClock,
  FaExternalLinkAlt,
  FaEye
} from 'react-icons/fa';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const db = getDatabase();

const DuyuruDetailPage = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.uid) {
      navigate('/login');
      return;
    }

    const fetchAnnouncement = async () => {
      try {
        const announcementRef = ref(db, `announcements/${id}`);
        const snapshot = await get(announcementRef);
        
        if (snapshot.exists()) {
          const data = snapshot.val();
          setAnnouncement({ id, ...data });
          
          // Görüntülenme ve okuma durumunu güncelle
          const updates = {};
          if (!data.readers?.includes(user.uid)) {
            updates.readers = [...(data.readers || []), user.uid];
          }
          updates.viewCount = (data.viewCount || 0) + 1;
          
          if (Object.keys(updates).length > 0) {
            await update(announcementRef, updates);
          }
        } else {
          navigate('/duyurular');
        }
      } catch (error) {
        console.error("Duyuru yükleme hatası:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncement();
  }, [id, user, navigate]);

  const getTypeIcon = (type) => {
    switch (type) {
      case 'urgent':
        return <FaExclamationCircle className="w-5 h-5" />;
      case 'maintenance':
        return <FaCog className="w-5 h-5" />;
      default:
        return <FaBullhorn className="w-5 h-5" />;
    }
  };

  const getTypeColor = (type) => {
    switch (type) {
      case 'urgent':
        return 'bg-kirmizi/10 text-kirmizi';
      case 'maintenance':
        return 'bg-turuncu/10 text-turuncu';
      default:
        return 'bg-yesil/10 text-yesil';
    }
  };

  const getTypeText = (type) => {
    switch (type) {
      case 'urgent':
        return 'Acil';
      case 'maintenance':
        return 'Bakım/Güncelleme';
      default:
        return 'Genel';
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  if (!announcement) return null;

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-20">
      {/* Header */}
      <div className="sticky top-0 bg-beyaz z-10 shadow-sm">
        <div className="flex items-center p-4">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 hover:bg-kirik-beyaz rounded-full transition-colors"
          >
            <FaArrowLeft className="w-5 h-5 text-yogun-antrasit" />
          </button>
          <h1 className="text-lg font-semibold text-yogun-antrasit flex-1 text-center pr-8">
            Duyuru Detayı
          </h1>
        </div>
      </div>

      <div className="p-4">
        <div className="bg-beyaz rounded-xl shadow-sm">
          <div className="p-4">
            {/* Başlık ve Tip */}
            <div className="flex items-start gap-4 mb-4">
              <div className={`shrink-0 p-3 rounded-xl ${getTypeColor(announcement.type)}`}>
                {getTypeIcon(announcement.type)}
              </div>
              
              <div className="flex-1 min-w-0">
                <h2 className="text-xl font-semibold text-yogun-antrasit mb-2">
                  {announcement.title}
                </h2>
                <span className={`inline-flex items-center gap-2 px-3 py-1 rounded-full text-xs font-medium ${getTypeColor(announcement.type)}`}>
                  {getTypeText(announcement.type)}
                </span>
              </div>
            </div>

            {/* Meta Bilgiler */}
            <div className="flex flex-wrap gap-4 text-sm text-grafit-gri mb-6">
              <div className="flex items-center gap-2">
                <FaCalendar className="w-4 h-4" />
                {format(new Date(announcement.createdAt), 'd MMM yyyy', { locale: tr })}
              </div>
              {announcement.updatedAt && (
                <div className="flex items-center gap-2">
                  <FaClock className="w-4 h-4" />
                  Güncelleme: {format(new Date(announcement.updatedAt), 'd MMM yyyy', { locale: tr })}
                </div>
              )}
              <div className="flex items-center gap-2">
                <FaEye className="w-4 h-4" />
                {announcement.viewCount || 1} görüntülenme
              </div>
            </div>

            {/* İçerik */}
            <div className="prose prose-sm max-w-none mb-6">
              <p className="text-grafit-gri whitespace-pre-wrap">
                {announcement.description}
              </p>
            </div>

            {/* Alt Bilgiler */}
            <div className="flex items-center justify-between pt-4 border-t border-kirik-beyaz">
              <div className="flex items-center gap-2 text-sm text-grafit-gri">
                {announcement.targetAudience === 'actors' ? (
                  <FaTheaterMasks className="w-4 h-4" />
                ) : announcement.targetAudience === 'clients' ? (
                  <FaUserTie className="w-4 h-4" />
                ) : (
                  <FaUsers className="w-4 h-4" />
                )}
                <span>
                  {announcement.targetAudience === 'actors' 
                    ? 'Oyuncular' 
                    : announcement.targetAudience === 'clients' 
                      ? 'İşverenler' 
                      : 'Herkes'}
                </span>
              </div>

              {announcement.link && (
                <a
                  href={announcement.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-sm text-mavi"
                >
                  <span>Detaylı Bilgi</span>
                  <FaExternalLinkAlt className="w-3 h-3" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuyuruDetailPage;
