import React, { useState, useEffect } from "react";
import { storage, db } from '../../firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, set, get } from 'firebase/database';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaInfoCircle } from 'react-icons/fa';

function IntroVideoUpload() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [introVideo, setIntroVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const fetchVideoURL = async () => {
      const dbReference = dbRef(db, `users/${user.uid}/profilecv/introVideo`);
      const snapshot = await get(dbReference);
      if (snapshot.exists()) {
        setVideoPreview(snapshot.val());
      }
    };
    fetchVideoURL();
  }, [user?.uid]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Video boyutu kontrolü (50MB)
    if (file.size > 50 * 1024 * 1024) {
      setError('Video boyutu 50MB\'dan küçük olmalıdır.');
      return;
    }

    setIntroVideo(file);
    setVideoPreview(URL.createObjectURL(file));
    setError(null);
  };

  const handleSubmit = async () => {
    if (!introVideo) {
      setError('Lütfen bir video seçin.');
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    setError(null);

    try {
      const storageRef = ref(storage, `introVideos/${user.uid}/${Date.now()}_${introVideo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, introVideo);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        }, 
        (error) => {
          console.error('Upload error:', error);
          setError('Video yüklenirken bir hata oluştu.');
          setUploading(false);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const dbReference = dbRef(db, `users/${user.uid}/profilecv/introVideo`);
          await set(dbReference, downloadURL);
          setVideoPreview(downloadURL);
          setUploading(false);
          navigate('/profile');
        }
      );
    } catch (error) {
      console.error('Error:', error);
      setError('Bir hata oluştu. Lütfen tekrar deneyin.');
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="bg-beyaz border-b border-gray-200">
        <div className="max-w-5xl mx-auto flex items-center justify-between px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-kirik-beyaz rounded-lg transition-colors"
          >
            <FaArrowLeft className="w-5 h-5 text-yogun-antrasit" />
          </button>
          <h1 className="text-lg font-medium text-yogun-antrasit">
            Tanıtım Videosu
          </h1>
          <button 
            onClick={handleSubmit} 
            disabled={uploading || !introVideo}
            className="text-yogun-antrasit font-medium disabled:opacity-50 
              disabled:cursor-not-allowed hover:opacity-80 transition-opacity"
          >
            {uploading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="max-w-2xl mx-auto px-4 py-6">
        {/* Video Önizleme */}
        <div
          className="relative aspect-video w-full rounded-2xl overflow-hidden bg-yogun-antrasit mb-6 
            hover:opacity-95 active:opacity-90 transition-opacity cursor-pointer"
          onClick={() => !uploading && document.getElementById('video-upload').click()}
        >
          {uploading ? (
            <div className="absolute inset-0 flex flex-col items-center justify-center text-beyaz">
              <FaSpinner className="animate-spin w-8 h-8 mb-3" />
              <p className="text-sm font-medium">Video Yükleniyor</p>
              <p className="text-xs text-beyaz/80 mt-1">
                %{uploadProgress.toFixed(0)}
              </p>
            </div>
          ) : videoPreview ? (
            <video 
              src={videoPreview} 
              className="w-full h-full object-cover"
              controls
            />
          ) : (
            <div className="absolute inset-0 flex flex-col items-center justify-center text-beyaz/80">
              <div className="w-16 h-16 rounded-full bg-beyaz/10 flex items-center justify-center mb-3">
                <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 8v8m4-4H8m8 0a8 8 0 11-16 0 8 8 0 0116 0z" />
                </svg>
              </div>
              <p className="text-sm font-medium">Video Yükle</p>
              <p className="text-xs mt-1">veya sürükle bırak</p>
            </div>
          )}
        </div>

        {/* Hata Mesajı */}
        {error && (
          <div className="mb-6 p-4 bg-kirmizi/10 text-kirmizi rounded-xl text-sm">
            {error}
          </div>
        )}

        {/* Video Seçme Butonu */}
        <input
          type="file"
          id="video-upload"
          accept="video/*"
          onChange={handleVideoChange}
          className="hidden"
        />
        <button
          onClick={() => !uploading && document.getElementById('video-upload').click()}
          disabled={uploading}
          className="w-full h-12 rounded-xl bg-kirik-beyaz text-yogun-antrasit 
            font-medium transition-all hover:bg-gray-200 active:bg-gray-300 
            disabled:opacity-50 disabled:hover:bg-kirik-beyaz"
        >
          Video Seç
        </button>
      </div>

      {/* Info İkonu */}
      <div className="fixed bottom-20 right-6 z-[60] group">
        <div className="absolute bottom-full right-0 mb-2 opacity-0 group-hover:opacity-100 
          transition-opacity duration-200 ease-in-out">
          <div className="bg-[#292929] text-white px-4 py-2 rounded-xl text-sm font-medium 
            shadow-lg relative min-w-[180px]">
            Nasıl olmalı?
            <div className="absolute -bottom-1 right-6 w-2 h-2 bg-[#292929] rotate-45"></div>
          </div>
        </div>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="bg-gradient-to-br from-[#292929] to-[#1a1a1a] text-white p-3 rounded-xl 
            shadow-lg hover:shadow-xl hover:-translate-y-0.5 active:translate-y-0 
            transition-all duration-200 ease-in-out hover:from-[#333333] hover:to-[#1f1f1f]"
        >
          <FaInfoCircle size={20} className="filter drop-shadow-md" />
        </button>
      </div>

      {/* Info Modal */}
      {showInfo && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[70] p-6">
          <div className="bg-beyaz rounded-xl w-full max-w-lg p-6 animate-fade-in">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-medium text-yogun-antrasit">
                Tanıtım Videosu Nasıl Olmalı?
              </h3>
              <button 
                onClick={() => setShowInfo(false)}
                className="text-grafit-gri hover:text-yogun-antrasit transition-colors"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="space-y-4 text-grafit-gri">
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Kendinizi kısaca tanıtın ve yeteneklerinizden bahsedin</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Profesyonel ve sade bir arka plan tercih edin</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>İyi aydınlatılmış bir ortamda çekim yapın</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Net ve anlaşılır bir ses kaydı olmasına dikkat edin</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>30-60 saniye arasında bir video hazırlayın</span>
              </p>
              <p className="flex items-start gap-3">
                <span className="text-yogun-antrasit text-lg">•</span>
                <span>Yatay formatta çekim yapmanız önerilir</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IntroVideoUpload; 