import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { 
  FaCalendar, 
  FaTag, 
  FaCog, 
  FaBullhorn,
  FaUsers,
  FaUserTie,
  FaTheaterMasks,
  FaExternalLinkAlt,
  FaExclamationCircle,
  FaArrowLeft,
  FaClock,
  FaEye
} from 'react-icons/fa';

const db = getDatabase();

const DuyuruDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const announcementRef = ref(db, `announcements/${id}`);
        const snapshot = await get(announcementRef);
        
        if (snapshot.exists()) {
          const data = snapshot.val();
          setAnnouncement({ id, ...data });
          
          // Görüntülenme sayısını artır
          if (data.viewCount) {
            await update(announcementRef, {
              viewCount: data.viewCount + 1
            });
          } else {
            await update(announcementRef, {
              viewCount: 1
            });
          }
        } else {
          navigate('/duyurular');
        }
      } catch (error) {
        console.error('Duyuru yükleme hatası:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncement();
  }, [id, navigate]);

  const getTypeIcon = (type) => {
    switch (type) {
      case 'urgent':
        return <FaExclamationCircle className="w-5 h-5" />;
      case 'maintenance':
        return <FaCog className="w-5 h-5" />;
      default:
        return <FaBullhorn className="w-5 h-5" />;
    }
  };

  const getTypeColor = (type) => {
    switch (type) {
      case 'urgent':
        return 'bg-kirmizi/10 text-kirmizi';
      case 'maintenance':
        return 'bg-turuncu/10 text-turuncu';
      default:
        return 'bg-yesil/10 text-yesil';
    }
  };

  const getTypeText = (type) => {
    switch (type) {
      case 'urgent':
        return 'Acil';
      case 'maintenance':
        return 'Bakım/Güncelleme';
      default:
        return 'Genel';
    }
  };

  const getAudienceIcon = (audience) => {
    switch (audience) {
      case 'actors':
        return <FaTheaterMasks className="w-5 h-5" />;
      case 'clients':
        return <FaUserTie className="w-5 h-5" />;
      default:
        return <FaUsers className="w-5 h-5" />;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-yogun-antrasit/20 border-t-yogun-antrasit rounded-full animate-spin" />
      </div>
    );
  }

  if (!announcement) {
    return null;
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz py-8">
      <div className="max-w-7xl mx-auto px-4">
        <button
          onClick={() => navigate('/duyurular')}
          className="flex items-center gap-2 text-grafit-gri hover:text-yogun-antrasit mb-6 group"
        >
          <FaArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
          <span>Duyurulara Dön</span>
        </button>

        <div className="bg-beyaz rounded-2xl shadow-sm">
          <div className="p-8">
            <div className="flex items-start justify-between gap-8 mb-6">
              <div className="flex-1">
                <div className="flex items-center gap-3 mb-4">
                  <h1 className="text-2xl font-semibold text-yogun-antrasit">
                    {announcement.title}
                  </h1>
                  <span className={`flex items-center gap-2 px-4 py-1.5 rounded-full text-sm font-medium ${getTypeColor(announcement.type)}`}>
                    {getTypeIcon(announcement.type)}
                    {getTypeText(announcement.type)}
                  </span>
                </div>

                <div className="flex items-center gap-6 text-grafit-gri">
                  <div className="flex items-center gap-2">
                    <FaCalendar className="w-4 h-4" />
                    {format(new Date(announcement.createdAt), 'd MMM yyyy', { locale: tr })}
                  </div>
                  {announcement.updatedAt && (
                    <div className="flex items-center gap-2">
                      <FaClock className="w-4 h-4" />
                      Son güncelleme: {format(new Date(announcement.updatedAt), 'd MMM yyyy', { locale: tr })}
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <FaEye className="w-4 h-4" />
                    {announcement.viewCount || 1} görüntülenme
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <span className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm ${
                  announcement.status === 'active' 
                    ? 'bg-yesil/10 text-yesil' 
                    : 'bg-grafit-gri/10 text-grafit-gri'
                }`}>
                  {announcement.status === 'active' ? 'Aktif' : 'Pasif'}
                </span>
              </div>
            </div>

            <div className="prose prose-lg max-w-none mb-8">
              <p className="text-grafit-gri whitespace-pre-wrap">
                {announcement.description}
              </p>
            </div>

            <div className="flex items-center justify-between pt-6 border-t border-kirik-beyaz">
              <div className="flex items-center gap-2 text-grafit-gri">
                {getAudienceIcon(announcement.targetAudience)}
                <span>
                  Hedef Kitle: {' '}
                  {announcement.targetAudience === 'actors' 
                    ? 'Oyuncular' 
                    : announcement.targetAudience === 'clients' 
                      ? 'İşverenler' 
                      : 'Herkes'}
                </span>
              </div>

              {announcement.link && (
                <a
                  href={announcement.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-mavi/10 text-mavi rounded-xl hover:bg-mavi/20 transition-colors"
                >
                  <span>Detaylı Bilgi</span>
                  <FaExternalLinkAlt className="w-4 h-4" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuyuruDetailPage;
