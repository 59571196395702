import React, { useEffect, useState } from 'react';
import { getDatabase, ref, query, orderByChild, limitToLast, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { MdAccessTime, MdArrowForward, MdPeople, MdMovie, MdAnnouncement, MdLocationOn, MdCalendarToday, MdAttachMoney } from 'react-icons/md';
import { FaTheaterMasks, FaFilm, FaTv, FaPhotoVideo, FaMale, FaFemale, FaChild } from 'react-icons/fa';

const db = getDatabase();

const HomePage = () => {
  const [projects, setProjects] = useState([]);
  const [recentAnnouncements, setRecentAnnouncements] = useState([]);
  const [stats, setStats] = useState({ projects: 0, players: 0, announcements: 0 });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Projeleri dinle
    const projectsRef = ref(db, 'projects');
    const projectsQuery = query(projectsRef, orderByChild('createdAt'), limitToLast(4));
    
    const unsubProjects = onValue(projectsQuery, (snapshot) => {
      if (snapshot.exists()) {
        const projectsData = Object.entries(snapshot.val()).map(([id, data]) => ({
          id,
          ...data
        })).sort((a, b) => b.createdAt - a.createdAt);
        setProjects(projectsData);
        setStats(prev => ({ ...prev, projects: snapshot.size }));
      }
      setLoading(false);
    });

    // Duyuruları dinle
    const announcementsRef = ref(db, 'announcements');
    const announcementsQuery = query(announcementsRef, orderByChild('timestamp'), limitToLast(3));
    
    const unsubAnnouncements = onValue(announcementsQuery, (snapshot) => {
      if (snapshot.exists()) {
        const announcementsData = Object.entries(snapshot.val()).map(([id, data]) => ({
          id,
          ...data
        })).sort((a, b) => b.timestamp - a.timestamp);
        setRecentAnnouncements(announcementsData);
        setStats(prev => ({ ...prev, announcements: snapshot.size }));
      }
    });

    // Oyuncu sayısını dinle
    const playersRef = ref(db, 'users');
    const unsubPlayers = onValue(playersRef, (snapshot) => {
      if (snapshot.exists()) {
        setStats(prev => ({ ...prev, players: snapshot.size }));
      }
    });

    return () => {
      unsubProjects();
      unsubAnnouncements();
      unsubPlayers();
    };
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) return 'Bugün';
    if (diffDays === 1) return 'Dün';
    if (diffDays < 7) return `${diffDays} gün önce`;
    return date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long' });
  };

  const getProjectTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'film':
        return <FaFilm className="w-5 h-5" />;
      case 'dizi':
        return <FaTv className="w-5 h-5" />;
      case 'tiyatro':
        return <FaTheaterMasks className="w-5 h-5" />;
      case 'reklam':
        return <FaPhotoVideo className="w-5 h-5" />;
      default:
        return <FaFilm className="w-5 h-5" />;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-kirik-beyaz flex items-center justify-center">
        <div className="animate-pulse text-grafit-gri">Yükleniyor...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz">
 

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-12 gap-8">
          {/* Sol Bölüm - Projeler */}
          <div className="col-span-12 lg:col-span-8">
            <div className="bg-beyaz rounded-2xl shadow-sm p-8">
              <div className="flex justify-between items-center mb-8">
                <h2 className="text-2xl font-bold text-yogun-antrasit">Son Projeler</h2>
                <button 
                  onClick={() => navigate('/projects')}
                  className="flex items-center gap-2 text-grafit-gri hover:text-yogun-antrasit transition-colors"
                >
                  Tümünü Gör
                  <MdArrowForward className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-4">
                {projects.map((project) => (
                  <div 
                    key={project.id} 
                    onClick={() => navigate(`/project/${project.id}`)}
                    className="bg-kirik-beyaz rounded-2xl overflow-hidden hover:shadow-md transition-all cursor-pointer group"
                  >
                    <div className="flex items-center p-4">
                      {/* Proje Resmi */}
                      <div className="w-48 h-28 bg-yogun-antrasit rounded-xl overflow-hidden shrink-0">
                        <img 
                          src={project.images?.[0] || '/placeholder-project.jpg'} 
                          alt={project.title}
                          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/placeholder-project.jpg';
                          }}
                        />
                      </div>

                      {/* Proje Bilgileri */}
                      <div className="flex-1 ml-6">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="flex items-center gap-2 text-grafit-gri">
                            {getProjectTypeIcon(project.projectType)}
                            <span className="text-sm capitalize">{project.projectType}</span>
                          </div>
                          {project.status === 'active' && (
                            <span className="text-xs px-2 py-1 bg-yesil/10 text-yesil rounded-full">
                              Aktif
                            </span>
                          )}
                        </div>

                        <h3 className="text-xl font-semibold text-yogun-antrasit mb-3 group-hover:text-mavi transition-colors">
                          {project.title}
                        </h3>

                        <div className="flex items-center gap-6">
                          {project.city && (
                            <div className="flex items-center text-grafit-gri">
                              <MdLocationOn className="w-4 h-4 mr-2 shrink-0" />
                              <span className="text-sm">{project.city}</span>
                            </div>
                          )}
                          {project.date && (
                            <div className="flex items-center text-grafit-gri">
                              <MdCalendarToday className="w-4 h-4 mr-2 shrink-0" />
                              <span className="text-sm">{formatDate(project.date)}</span>
                            </div>
                          )}
                          {project.budget && (
                            <div className="flex items-center text-grafit-gri">
                              <MdAttachMoney className="w-4 h-4 mr-2 shrink-0" />
                              <span className="text-sm font-medium">{project.budget} TL</span>
                            </div>
                          )}
                          {project.actorDetails && (
                            <div className="flex items-center gap-3 text-grafit-gri">
                              <MdPeople className="w-4 h-4 shrink-0" />
                              <div className="flex items-center gap-2 text-sm">
                                {project.actorDetails.men > 0 && (
                                  <span className="flex items-center gap-1">
                                    <FaMale className="w-3 h-3" />
                                    {project.actorDetails.men}
                                  </span>
                                )}
                                {project.actorDetails.women > 0 && (
                                  <span className="flex items-center gap-1">
                                    <FaFemale className="w-3 h-3" />
                                    {project.actorDetails.women}
                                  </span>
                                )}
                                {project.actorDetails.children > 0 && (
                                  <span className="flex items-center gap-1">
                                    <FaChild className="w-3 h-3" />
                                    {project.actorDetails.children}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Sağ Bölüm */}
          <div className="col-span-12 lg:col-span-4 space-y-8">
            {/* İstatistikler */}
            <div className="bg-beyaz rounded-2xl shadow-sm p-8">
              <h2 className="text-2xl font-bold text-yogun-antrasit mb-6">
                Genel Bakış
              </h2>
              <div className="space-y-4">
                <div className="flex items-center gap-4 p-4 bg-kirik-beyaz rounded-xl">
                  <div className="p-3 bg-yogun-antrasit rounded-xl">
                    <MdMovie className="w-6 h-6 text-beyaz" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-yogun-antrasit">
                      {stats.projects}
                    </div>
                    <div className="text-sm text-grafit-gri">Aktif Proje</div>
                  </div>
                </div>
                <div className="bg-kirik-beyaz rounded-xl p-4 flex items-center gap-4">
                  <div className="p-3 bg-yogun-antrasit rounded-xl">
                    <MdPeople className="w-6 h-6 text-beyaz" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-yogun-antrasit">{stats.players}</div>
                    <div className="text-sm text-grafit-gri">Kayıtlı Oyuncu</div>
                  </div>
                </div>
                <div className="bg-kirik-beyaz rounded-xl p-4 flex items-center gap-4">
                  <div className="p-3 bg-yogun-antrasit rounded-xl">
                    <MdAnnouncement className="w-6 h-6 text-beyaz" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-yogun-antrasit">{stats.announcements}</div>
                    <div className="text-sm text-grafit-gri">Toplam Duyuru</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Duyurular */}
            <div className="bg-beyaz rounded-2xl shadow-sm p-8">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-yogun-antrasit">
                  Son Duyurular
                </h2>
                <button 
                  onClick={() => navigate('/announcements')}
                  className="flex items-center gap-2 text-grafit-gri hover:text-yogun-antrasit transition-colors"
                >
                  Tümünü Gör
                  <MdArrowForward className="w-5 h-5" />
                </button>
              </div>
              <div className="space-y-6">
                {recentAnnouncements.map((announcement) => (
                  <div 
                    key={announcement.id}
                    onClick={() => navigate(`/announcement/${announcement.id}`)}
                    className="group cursor-pointer"
                  >
                    <div className="flex items-center text-sm text-grafit-gri mb-2">
                      <MdAccessTime className="w-4 h-4 mr-2" />
                      {formatDate(announcement.timestamp)}
                    </div>
                    <h3 className="font-medium text-yogun-antrasit group-hover:text-yogun-antrasit/80 transition-colors mb-1">
                      {announcement.title}
                    </h3>
                    <p className="text-sm text-grafit-gri line-clamp-2">
                      {announcement.content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
