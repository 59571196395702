import React from 'react';

const FormField = ({ label, type, value, onChange, error, required, options, description, disabled, ...props }) => {
  const renderField = () => {
    switch (type) {
      case 'multiSelect':
        return (
          <div className="grid grid-cols-3 gap-3">
            {options.map(option => (
              <button
                key={option.id}
                type="button"
                onClick={() => {
                  const newValue = Array.isArray(value) 
                    ? value.includes(option.id)
                      ? value.filter(v => v !== option.id)
                      : [...value, option.id]
                    : [option.id];
                  onChange(newValue);
                }}
                className={`py-3 px-4 rounded-xl text-sm font-medium transition-colors
                  ${Array.isArray(value) && value.includes(option.id)
                    ? 'bg-yogun-antrasit text-beyaz'
                    : 'bg-kirik-beyaz text-grafit-gri hover:bg-[#E5E5E5]'}`}
              >
                {option.label}
              </button>
            ))}
          </div>
        );
      case 'text':
      case 'tel':
      case 'email':
      case 'date':
        return (
          <input
            type={type}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            className="w-full h-12 px-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit"
            disabled={disabled}
            required={required}
            {...props}
          />
        );
      case 'select':
        return (
          <select
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            className="w-full h-12 px-4 rounded-xl bg-kirik-beyaz text-yogun-antrasit appearance-none"
            disabled={disabled}
            required={required}
          >
            <option value="">Seçiniz</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-4">
      <label className="block mb-2">
        <span className="text-yogun-antrasit font-medium">
          {label}
          {required && <span className="text-kirmizi ml-1">*</span>}
        </span>
        {description && (
          <span className="block text-sm text-grafit-gri mt-1">
            {description}
          </span>
        )}
      </label>
      {renderField()}
      {error && (
        <p className="mt-1 text-sm text-kirmizi">{error}</p>
      )}
    </div>
  );
};

export default FormField; 