import React, { useEffect, useState } from "react";
import { storage, db } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { update, ref as dbRef, onValue } from "firebase/database";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaCamera, FaTimes, FaCheck, FaChevronLeft } from "react-icons/fa";

const ProfileEditPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    fullName: "",
    profileImage: null,
    currentProfileImage: null
  });
  
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  // Gerçek zamanlı veri dinleme
  useEffect(() => {
    if (!user?.uid) return;

    const userRef = dbRef(db, `users/${user.uid}`);
    const unsubscribe = onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setFormData(prev => ({
          ...prev,
          fullName: data.fullName || "",
          currentProfileImage: data.profileImage || null
        }));
      }
    });

    return () => unsubscribe();
  }, [user]);

  // Önizleme temizleme
  useEffect(() => {
    return () => {
      if (imagePreview) URL.revokeObjectURL(imagePreview);
    };
  }, [imagePreview]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, profileImage: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleUpload = async (file) => {
    const storageRef = ref(storage, `profileImages/${user.uid}_${Date.now()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        reject,
        () => getDownloadURL(uploadTask.snapshot.ref).then(resolve)
      );
    });
  };

  const handleSubmit = async () => {
    if (!user) return;
    
    setLoading(true);
    try {
      let profileImageUrl = formData.currentProfileImage;

      if (formData.profileImage instanceof File) {
        profileImageUrl = await handleUpload(formData.profileImage);
      }

      await update(dbRef(db, `users/${user.uid}`), {
        fullName: formData.fullName,
        profileImage: profileImageUrl,
        updatedAt: Date.now()
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate(-1);
      }, 1500);

    } catch (error) {
      console.error("Güncelleme hatası:", error);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-beyaz z-50 shadow-sm">
        <div className="flex items-center justify-between px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="w-10 h-10 flex items-center justify-center text-yogun-antrasit"
          >
            <FaChevronLeft className="w-5 h-5" />
          </button>
          
          <h1 className="text-lg font-bold text-yogun-antrasit">
            Profili Düzenle
          </h1>
          
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="w-10 h-10 flex items-center justify-center text-yogun-antrasit"
          >
            <FaCheck className="w-5 h-5" />
          </button>
        </div>
      </header>

      {/* Ana İçerik */}
      <main className="pt-14 pb-6 px-4">
        {/* Profil Fotoğrafı */}
        <div className="flex flex-col items-center mt-8 mb-6">
          <div className="relative">
            <div className="w-32 h-32 rounded-full overflow-hidden bg-kirik-beyaz border-4 border-beyaz shadow-md">
              {(imagePreview || formData.currentProfileImage) ? (
                <img
                  src={imagePreview || formData.currentProfileImage}
                  alt="Profil"
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-grafit-gri">
                  <FaCamera className="w-8 h-8 text-beyaz opacity-75" />
                </div>
              )}
            </div>
            
            <label className="absolute bottom-0 right-0">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
              <div className="w-10 h-10 bg-yogun-antrasit rounded-full flex items-center justify-center cursor-pointer shadow-lg border-2 border-beyaz">
                <FaCamera className="w-4 h-4 text-beyaz" />
              </div>
            </label>
          </div>
        </div>

        {/* Form */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-grafit-gri mb-2">
              Ad Soyad
            </label>
            <input
              type="text"
              value={formData.fullName}
              onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
              className="w-full h-12 px-4 rounded-xl border border-kirik-beyaz bg-beyaz focus:outline-none focus:border-yogun-antrasit transition-colors"
              placeholder="Ad Soyad"
            />
          </div>
        </div>
      </main>

      {/* Yükleme Göstergesi */}
      {loading && progress > 0 && progress < 100 && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-2xl p-6 mx-4 w-full max-w-sm">
            <div className="w-full bg-kirik-beyaz rounded-full h-2">
              <div 
                className="h-2 rounded-full bg-yogun-antrasit transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center mt-2 text-sm text-grafit-gri">
              Yükleniyor... %{progress}
            </p>
          </div>
        </div>
      )}

      {/* Başarı Mesajı */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-2xl p-6 mx-4 w-full max-w-sm text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-yesil/10 flex items-center justify-center">
              <FaCheck className="w-8 h-8 text-yesil" />
            </div>
            <p className="text-lg font-medium text-yogun-antrasit">
              Profil güncellendi
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileEditPage;
