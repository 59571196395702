import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { ref, onValue, remove, update, push } from 'firebase/database';
import { HiOutlinePencil, HiOutlineTrash, HiOutlinePlus, HiChevronLeft, HiX } from 'react-icons/hi';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const AnnouncementManageMobile = () => {
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: 'general',
    status: 'active',
    startDate: '',
    endDate: '',
    link: '',
    targetAudience: 'all'
  });

  useEffect(() => {
    const announcementRef = ref(db, 'announcements');
    onValue(announcementRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const announcementsData = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setAnnouncements(announcementsData);
      } else {
        setAnnouncements([]);
      }
      setLoading(false);
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const announcementRef = ref(db, 'announcements');
      await push(announcementRef, {
        ...formData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      setShowAddForm(false);
      setFormData({
        title: '',
        description: '',
        type: 'general',
        status: 'active',
        startDate: '',
        endDate: '',
        link: '',
        targetAudience: 'all'
      });
    } catch (error) {
      console.error('Duyuru ekleme hatası:', error);
      alert('Duyuru eklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const renderAddForm = () => {
    if (!showAddForm) return null;

    return (
      <div className="fixed inset-0 bg-beyaz z-50 overflow-y-auto">
        <div className="fixed top-0 left-0 right-0 bg-beyaz border-b border-kirik-beyaz z-40">
          <div className="flex items-center justify-between px-4 h-16">
            <h2 className="text-lg font-semibold text-yogun-antrasit">
              Yeni Duyuru
            </h2>
            <button
              onClick={() => setShowAddForm(false)}
              className="p-2 text-yogun-antrasit rounded-lg"
            >
              <HiX className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="pt-20 px-4 pb-32">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="bg-beyaz rounded-xl">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Duyuru Başlığı
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="Başlık giriniz"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Duyuru İçeriği
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit min-h-[120px]"
                    placeholder="İçerik giriniz"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Duyuru Tipi
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  >
                    <option value="general">Genel</option>
                    <option value="urgent">Acil</option>
                    <option value="maintenance">Bakım/Güncelleme</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Hedef Kitle
                  </label>
                  <select
                    name="targetAudience"
                    value={formData.targetAudience}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    required
                  >
                    <option value="all">Herkes</option>
                    <option value="actors">Sadece Oyuncular</option>
                    <option value="clients">Sadece İşverenler</option>
                  </select>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Başlangıç Tarihi
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-grafit-gri mb-2">
                      Bitiş Tarihi
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-grafit-gri mb-2">
                    Bağlantı (Opsiyonel)
                  </label>
                  <input
                    type="url"
                    name="link"
                    value={formData.link}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3.5 border border-kirik-beyaz rounded-xl focus:outline-none focus:border-yogun-antrasit"
                    placeholder="https://"
                  />
                </div>
              </div>
            </div>

            <div className="fixed bottom-8 left-4 right-4">
              <button
                type="submit"
                disabled={loading}
                className="w-full py-4 bg-yogun-antrasit text-beyaz rounded-xl font-medium disabled:opacity-50"
              >
                {loading ? 'Kaydediliyor...' : 'Duyuruyu Yayınla'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const renderHeader = () => (
    <div className="fixed top-0 left-0 right-0 bg-beyaz border-b border-kirik-beyaz z-40">
      <div className="flex items-center justify-between px-4 h-16">
        <div className="flex items-center gap-3">
          <button
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 text-yogun-antrasit rounded-lg"
          >
            <HiChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-semibold text-yogun-antrasit">Duyurular</h1>
        </div>
        <button
          onClick={() => setShowAddForm(true)}
          className="flex items-center gap-2 px-4 py-2 bg-yogun-antrasit text-beyaz rounded-xl text-sm font-medium"
        >
          <HiOutlinePlus className="w-5 h-5" />
          Yeni Duyuru
        </button>
      </div>
    </div>
  );

  const handleDelete = async () => {
    if (!selectedAnnouncement) return;

    try {
      const announcementRef = ref(db, `announcements/${selectedAnnouncement.id}`);
      await remove(announcementRef);
      setShowDeleteModal(false);
      setSelectedAnnouncement(null);
    } catch (error) {
      console.error('Duyuru silme hatası:', error);
      alert('Duyuru silinirken bir hata oluştu');
    }
  };

  const renderAnnouncementCard = (announcement) => (
    <div 
      key={announcement.id}
      className="bg-beyaz rounded-xl shadow-sm overflow-hidden"
    >
      <div className="p-4">
        <div className="flex items-start justify-between gap-4">
          <div className="flex-1 min-w-0">
            <h3 className="font-medium text-yogun-antrasit truncate">
              {announcement.title}
            </h3>
            <p className="text-sm text-grafit-gri mt-1 line-clamp-2">
              {announcement.description}
            </p>
            <div className="flex items-center gap-4 mt-2">
              <div className="flex items-center gap-2">
                <span className={`w-2 h-2 rounded-full ${
                  announcement.status === 'active' ? 'bg-yesil' : 'bg-grafit-gri'
                }`} />
                <span className="text-sm text-grafit-gri">
                  {announcement.status === 'active' ? 'Aktif' : 'Pasif'}
                </span>
              </div>
              <span className="text-sm text-grafit-gri">
                {format(new Date(announcement.createdAt), 'd MMM yyyy', { locale: tr })}
              </span>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <button
              onClick={() => {
                setFormData(announcement);
                setShowAddForm(true);
              }}
              className="p-2 text-grafit-gri hover:text-yogun-antrasit rounded-lg"
            >
              <HiOutlinePencil className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                setSelectedAnnouncement(announcement);
                setShowDeleteModal(true);
              }}
              className="p-2 text-grafit-gri hover:text-kirmizi rounded-lg"
            >
              <HiOutlineTrash className="w-5 h-5" />
            </button>
          </div>
        </div>
        
        <div className="mt-4">
          <button
            onClick={() => handleStatusToggle(announcement)}
            className={`w-full py-2 rounded-lg text-sm font-medium ${
              announcement.status === 'active'
                ? 'bg-kirmizi/10 text-kirmizi'
                : 'bg-yesil/10 text-yesil'
            }`}
          >
            {announcement.status === 'active' ? 'Pasife Al' : 'Aktife Al'}
          </button>
        </div>
      </div>
    </div>
  );

  const renderDeleteModal = () => {
    if (!showDeleteModal) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-end z-50">
        <div className="bg-beyaz w-full rounded-t-2xl p-6 animate-slide-up">
          <h3 className="text-lg font-semibold text-yogun-antrasit mb-2">
            Duyuruyu Sil
          </h3>
          <p className="text-grafit-gri mb-6">
            "{selectedAnnouncement?.title}" duyurusunu silmek istediğinizden emin misiniz?
          </p>
          <div className="flex gap-3">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedAnnouncement(null);
              }}
              className="flex-1 py-3.5 border border-kirik-beyaz rounded-xl font-medium text-yogun-antrasit"
            >
              Vazgeç
            </button>
            <button
              onClick={handleDelete}
              className="flex-1 py-3.5 bg-kirmizi text-beyaz rounded-xl font-medium"
            >
              Sil
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleStatusToggle = async (announcement) => {
    try {
      const announcementRef = ref(db, `announcements/${announcement.id}`);
      await update(announcementRef, {
        status: announcement.status === 'active' ? 'inactive' : 'active',
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Durum güncelleme hatası:', error);
      alert('Durum güncellenirken bir hata oluştu');
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-24">
      {renderHeader()}
      {renderDeleteModal()}
      {renderAddForm()}
      
      <div className="pt-20 px-4">
        <div className="space-y-4">
          {announcements.length > 0 ? (
            announcements.map(announcement => renderAnnouncementCard(announcement))
          ) : (
            <div className="bg-beyaz rounded-xl p-6 text-center">
              <p className="text-grafit-gri">Henüz duyuru eklenmemiş</p>
              <button
                onClick={() => setShowAddForm(true)}
                className="mt-4 px-6 py-3 bg-yogun-antrasit text-beyaz rounded-xl text-sm font-medium"
              >
                İlk Duyuruyu Ekle
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementManageMobile;
