import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HomePage from '../pages/desktop/HomePage';
import AuthPage from '../pages/desktop/AuthPage';
import ProfilePage from '../pages/desktop/ProfilePage';
import DesktopLayout from '../layouts/DesktopLayout';
import ProjectsPage from '../pages/desktop/ProjectsPage';
import ProjectDetailPage from '../pages/desktop/ProjectDetailPage';
import PlayersPage from '../pages/desktop/PlayersPage';
import PlayerDetailPage from '../pages/desktop/PlayerDetailPage';
import AnnouncementsPage from '../pages/desktop/DuyurularPage';
import DuyuruDetailPage from '../pages/desktop/DuyuruDetailPage';
import ProfileEditPage from '../pages/desktop/ProfileEditPage';
import ProjectAdd from '../pages/desktop/ProjectAdd';
import ProjectManage from '../pages/desktop/ProjectManage';
import AnnouncementManage from '../pages/desktop/AnnouncementManage';
import SelectedUsersDesktop from '../pages/desktop/SelectedUsersDesktop';
import AdminApprovalDesktop from '../pages/desktop/AdminApprovalDesktop';
import ProfileApproval from '../pages/desktop/ProfileApproval';
import Kolaj from '../pages/desktop/Kolaj';
import IntroVideoUpload from '../pages/desktop/IntroVideoUpload';
import ShowreelPageDesktop from '../pages/desktop/ShowreelPage';
import MimicVideoUpload from '../pages/desktop/MimicVideoUpload';
import CvForm from '../pages/desktop/CvForm';
// Korumalı Route bileşeni
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

// Public Route bileşeni (giriş yapmış kullanıcıları home'a yönlendirir)
const PublicRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (user) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

const DesktopRoutes = () => {
  return (
    <Routes>
      {/* Auth sayfası için public route */}
      <Route path="/auth" element={
        <PublicRoute>
          <AuthPage />
        </PublicRoute>
      } />

      {/* Korumalı rotalar */}
      <Route element={
        <ProtectedRoute>
          <DesktopLayout />
        </ProtectedRoute>
      }> 
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile-edit" element={<ProfileEditPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/project-add" element={<ProjectAdd />} />
        <Route path="/project-manage" element={<ProjectManage />} />
        <Route path="/project/:id" element={<ProjectDetailPage />} />
        <Route path="/players" element={<PlayersPage />} />
        <Route path="/players/:id" element={<PlayerDetailPage />} />
        <Route path="/announcements" element={<AnnouncementsPage />} />
        <Route path="/announcement/:id" element={<DuyuruDetailPage />} />
        <Route path="/announcement-manage" element={<AnnouncementManage />} />
        <Route path="/selected-users" element={<SelectedUsersDesktop />} />
        <Route path="/admin-approval" element={<AdminApprovalDesktop />} />
        <Route path="/profile-approval" element={<ProfileApproval />} />
        <Route path="/kolaj" element={<Kolaj />} />
        <Route path="/intro-video-upload" element={<IntroVideoUpload />} />
        <Route path="/showreel" element={<ShowreelPageDesktop />} />
        <Route path="/mimic-video-upload" element={<MimicVideoUpload />} />
        <Route path="/cv-form" element={<CvForm />} />
      </Route>

      {/* Bilinmeyen rotaları yönlendir */}
      <Route path="*" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default DesktopRoutes;
