import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaBell, FaCog, FaLock, FaImage, FaFileAlt, FaVideo, FaUser, FaPlus, FaGift, FaProjectDiagram, FaMoneyBill, FaSignOutAlt, FaEdit, FaChevronRight, FaQuestionCircle } from 'react-icons/fa';
import KlaketLogo from '../../assets/logo.svg';

const db = getDatabase();

const translateRole = (role) => {
  const roles = {
    admin: 'Yönetici',
    user: 'Kullanıcı',
    production: 'Yapım'
  };
  return roles[role] || role;
};

function MobileProfile() {
  const { user, logout } = useAuth();
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = ref(db, `users/${user.uid}`);
    const unsubscribe = onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        setProfileData(snapshot.val());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  if (loading) {
    return (
      <div className="fixed inset-0 bg-beyaz flex items-center justify-center">
        <div className="w-16 h-16 relative">
          <div className="w-full h-full border-4 border-kirik-beyaz rounded-full animate-spin border-t-yogun-antrasit" />
        </div>
      </div>
    );
  }

  const getProfileImage = () => {
    if (profileData.profileImage) {
      return { type: 'image', src: profileData.profileImage };
    } else if (profileData.role === 'production') {
      return { type: 'logo', src: KlaketLogo };
    }
    return { type: 'icon', Icon: FaUser };
  };

  const getMenuItems = () => {
    const baseMenuItems = [
      { label: 'Ayarlar', icon: <FaCog />, path: '/settings' },
    ];

    const userMenuItems = [
      { label: 'Kolaj Yap', icon: <FaImage />, path: '/kolaj', description: 'Zorunlu alan*' },
      { label: 'CV Düzenle', icon: <FaFileAlt />, path: '/cv-form', description: 'Zorunlu alan*' },
      { label: 'Tanıtım Videosu', icon: <FaVideo />, path: '/intro-video-upload', description: 'Zorunlu alan*' },
      { label: 'Showreel Videosu', icon: <FaVideo />, path: '/showreel', description: 'Zorunlu değil' },
      { label: 'Mimik Videosu', icon: <FaVideo />, path: '/mimic-video-upload', description: 'Zorunlu değil' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/profile-approval' },
      { label: 'Banka Detayı', icon: <FaMoneyBill />, path: '/bank-detail' }
    ];

    const adminMenuItems = [
      { label: 'Proje Ekle', icon: <FaPlus />, path: '/project/add' },
      { label: 'Proje Yönetimi', icon: <FaProjectDiagram />, path: '/project/manage' },
      { label: 'Seçilmiş Kullanıcılar', icon: <FaUser />, path: '/selected-users' },
      { label: 'Duyuru Yönetimi', icon: <FaBell />, path: '/announcement/manage' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/admin-approval' },
    ];

    const productionMenuItems = [
      { label: 'Duyuru Yönetimi', icon: <FaBell />, path: '/announcement-page' },
      { label: 'Seçilmiş Kullanıcılar', icon: <FaUser />, path: '/selected-users' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/admin-approval' },
    ];

    const roleMenuItems = {
      admin: [...baseMenuItems, ...adminMenuItems],
      production: [...baseMenuItems, ...productionMenuItems],
      user: [...userMenuItems, ...baseMenuItems]
    };

    return roleMenuItems[profileData.role] || [...userMenuItems, ...baseMenuItems];
  };

  const profileImageData = getProfileImage();
  const menuItems = getMenuItems();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Çıkış yapılırken hata oluştu:", error);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header - Sabit Başlık */}
      <div className="fixed top-0 left-0 right-0 bg-beyaz z-10">
        <div className="flex items-center justify-between p-4 max-w-md mx-auto">
          <div 
            className="w-10 h-10 rounded-full bg-kirik-beyaz flex items-center justify-center"
            onClick={() => navigate('/campaigns')}
          >
            <FaGift className="w-5 h-5 text-yogun-antrasit" />
          </div>
          <h1 className="text-lg font-bold text-yogun-antrasit">Profil</h1>
          <button
            onClick={() => setShowLogoutModal(true)}
            className="w-10 h-10 rounded-full bg-kirik-beyaz flex items-center justify-center"
          >
            <FaSignOutAlt className="w-5 h-5 text-yogun-antrasit" />
          </button>
        </div>
      </div>

      {/* Ana İçerik - Header'dan sonra başlar */}
      <div className="pt-16 pb-20"> {/* Header ve Bottom Nav için padding */}
        {/* Profil Kartı */}
        <div className="bg-beyaz shadow-sm">
          <div className="px-6 py-5">
            {/* Üst Kısım - Profil Bilgileri */}
            <div className="flex items-center space-x-4">
              {/* Profil Fotoğrafı */}
              <div className="relative">
                <div
                  className="w-16 h-16 rounded-full bg-kirik-beyaz flex items-center justify-center overflow-hidden border-2 border-kirik-beyaz"
                  onClick={() => navigate('/profile/edit')}
                >
                  {profileImageData.type === 'image' && (
                    <img 
                      src={profileImageData.src} 
                      alt="Profil" 
                      className="w-full h-full object-cover"
                    />
                  )}
                  {profileImageData.type === 'logo' && (
                    <img 
                      src={profileImageData.src} 
                      alt="Logo" 
                      className="w-3/4 h-3/4 object-contain opacity-75"
                    />
                  )}
                  {profileImageData.type === 'icon' && (
                    <profileImageData.Icon size={24} className="text-grafit-gri opacity-75" />
                  )}
                </div>
                
                {/* Düzenleme Butonu */}
                <button 
                  className="absolute -bottom-1 -right-1 w-6 h-6 bg-yogun-antrasit rounded-full flex items-center justify-center border-2 border-beyaz"
                  onClick={() => navigate('/profile/edit')}
                >
                  <FaEdit className="w-2.5 h-2.5 text-beyaz" />
                </button>
              </div>

              {/* İsim ve Rol Bilgisi */}
              <div className="flex-1">
                <h2 className="text-base font-semibold text-yogun-antrasit mb-1">
                  {profileData.fullName}
                </h2>
                <div className="inline-flex items-center px-2 py-0.5 bg-kirik-beyaz rounded-full">
                  <span className="text-xs font-medium text-grafit-gri">
                    {profileData.title || translateRole(profileData.role)}
                  </span>
                </div>
              </div>
            </div>

            {/* Alt Kısım - Buton */}
            <div className="mt-4">
              <button
                onClick={() => navigate('/profile/edit')}
                className="w-full py-2.5 px-4 bg-kirik-beyaz rounded-xl text-sm font-medium text-yogun-antrasit
                         flex items-center justify-center gap-2 hover:bg-kirik-beyaz/80 active:scale-[0.98] transition-all"
              >
                <FaUser className="w-3.5 h-3.5" />
                Profili Düzenle
              </button>
            </div>
          </div>
        </div>

        {/* Menü Listesi */}
        <div className="mt-4 bg-beyaz">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              {...item}
              isLast={index === menuItems.length - 1}
            />
          ))}
        </div>
      </div>

      {/* Çıkış Modal - Güncellendi */}
      <LogoutModal 
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onLogout={handleLogout}
      />
    </div>
  );
}

// Yardımcı Bileşenler
const StatItem = ({ label, value, icon: Icon }) => (
  <div className="text-center">
    <div className="flex justify-center mb-1">
      <Icon className="w-4 h-4 text-grafit-gri" />
    </div>
    <div className="text-xl font-bold text-yogun-antrasit">{value}</div>
    <div className="text-xs text-grafit-gri">{label}</div>
  </div>
);

const ActionButton = ({ label, icon: Icon, onClick, primary }) => (
  <button
    onClick={onClick}
    className={`
      w-full py-2.5 rounded-xl text-sm font-medium
      flex items-center justify-center gap-2
      transition-all duration-200 active:scale-98
      ${primary 
        ? 'bg-yogun-antrasit text-beyaz shadow-sm hover:bg-grafit-gri' 
        : 'bg-kirik-beyaz text-yogun-antrasit hover:bg-kirik-beyaz/80'
      }
    `}
  >
    <Icon className="w-4 h-4" />
    {label}
  </button>
);

const MenuItem = ({ icon, label, description, path, isLast }) => {
  const navigate = useNavigate();
  
  return (
    <div
      onClick={() => navigate(path)}
      className={`
        flex items-center px-4 py-3.5
        hover:bg-kirik-beyaz active:bg-kirik-beyaz/80
        transition-colors cursor-pointer
        ${!isLast ? 'border-b border-kirik-beyaz' : ''}
      `}
    >
      <div className="w-10 h-10 rounded-xl bg-kirik-beyaz flex items-center justify-center mr-3">
        {React.cloneElement(icon, { className: "w-5 h-5 text-yogun-antrasit" })}
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-yogun-antrasit truncate">
          {label}
        </p>
        {description && (
          <p className="text-xs text-grafit-gri mt-0.5 truncate">
            {description}
          </p>
        )}
      </div>
      <FaChevronRight className="w-4 h-4 text-grafit-gri ml-2" />
    </div>
  );
};

const LogoutModal = ({ isOpen, onClose, onLogout }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-end justify-center p-4 z-50 animate-fadeIn">
      <div className="bg-beyaz rounded-3xl p-6 w-full max-w-sm animate-slideUp">
        <h3 className="text-lg font-bold text-yogun-antrasit mb-2">
          Çıkış Yap
        </h3>
        <p className="text-grafit-gri mb-6">
          Çıkış yapmak istediğinizden emin misiniz?
        </p>
        <div className="space-y-3">
          <button
            onClick={onLogout}
            className="w-full py-3.5 bg-yogun-antrasit text-beyaz rounded-2xl font-medium active:scale-98 transition-all"
          >
            Evet, Çıkış Yap
          </button>
          <button
            onClick={onClose}
            className="w-full py-3.5 bg-kirik-beyaz text-yogun-antrasit rounded-2xl font-medium active:scale-98 transition-all"
          >
            İptal
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileProfile;
