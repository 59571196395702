import React, { useState } from 'react';
import { getDatabase, ref, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { FaUniversity, FaCreditCard, FaGlobe } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';

const db = getDatabase();

const FORM_FIELDS = [
  { 
    name: "accountHolder", 
    label: "Ad Soyad", 
    icon: FaCreditCard, 
    required: true 
  },
  { 
    name: "bankName", 
    label: "Banka Adı", 
    icon: FaUniversity, 
    required: true 
  },
  { 
    name: "iban", 
    label: "IBAN", 
    icon: FaGlobe, 
    required: true, 
    format: "iban" 
  }
];

function BankDetailMobile() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState({
    accountHolder: '',
    bankName: '',
    iban: ''
  });

  const formatIBAN = (value) => {
    const cleaned = value.replace(/\s/g, '');
    const match = cleaned.match(/.{1,4}/g);
    return match ? match.join(' ') : value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'iban') {
      const formatted = formatIBAN(value.toUpperCase());
      setBankData(prev => ({ ...prev, [name]: formatted }));
    } else {
      setBankData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    if (!user) return;
    setLoading(true);
    try {
      await update(ref(db, `users/${user.uid}/bankDetails`), bankData);
      navigate(-1);
    } catch (error) {
      console.error('Kayıt hatası:', error);
      alert('Banka bilgileri kaydedilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden">
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">
          Banka Bilgileri
        </h2>
        <div className="flex w-12 items-center justify-end">
          <button 
            onClick={handleSubmit} 
            disabled={loading}
            className="text-neutral-500 text-base font-bold leading-normal tracking-[0.015em] shrink-0"
          >
            Kaydet
          </button>
        </div>
      </div>

      <div className="p-4">
        {FORM_FIELDS.map((field) => (
          <div key={field.name} className="mb-4">
            <label className="block text-sm font-medium text-[#141414] mb-1.5">
              {field.label}
            </label>
            <div className="relative">
              <input
                type="text"
                name={field.name}
                value={bankData[field.name]}
                onChange={handleChange}
                className="w-full h-12 px-4 pl-11 rounded-xl bg-[#f0f0f0] text-[#141414]"
                placeholder={field.label}
                required={field.required}
              />
              <field.icon className="absolute left-4 top-1/2 -translate-y-1/2 text-neutral-500" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BankDetailMobile; 