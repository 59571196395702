import { useState, useEffect } from 'react';
import { db, storage } from '../../firebase/config';
import { set, ref as dbRef, onValue } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaCheck, FaInfoCircle } from 'react-icons/fa';

function Kolaj() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([null, null, null]);
  const [photoURLs, setPhotoURLs] = useState([null, null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    current: 0,
    total: 0,
    currentFile: 0
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const kolajRef = dbRef(db, `users/${user.uid}/profilecv/kolaj`);
    onValue(kolajRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPhotoURLs([data.photo1, data.photo2, data.photo3]);
        setPhotos([data.photo1, data.photo2, data.photo3]);
      }
    });
  }, [user.uid]);

  const handlePhotoChange = (index) => async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Dosya boyutu kontrolü (örn: 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError('Dosya boyutu 5MB\'dan küçük olmalıdır.');
        return;
      }

      const newPhotos = [...photos];
      newPhotos[index] = file;
      setPhotos(newPhotos);

      const newPhotoURLs = [...photoURLs];
      newPhotoURLs[index] = URL.createObjectURL(file);
      setPhotoURLs(newPhotoURLs);
      setError(null);
    }
  };

  const uploadPhoto = async (file) => {
    const storageReference = storageRef(storage, `photos/${user.uid}/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageReference, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(prev => ({
            ...prev,
            current: progress
          }));
        },
        (error) => {
          console.error('Yükleme başarısız:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadProgress(prev => ({
              ...prev,
              currentFile: prev.currentFile + 1
            }));
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const handleSavePhotos = async () => {
    if (photos.filter(photo => photo).length < 3) {
      setError('Lütfen 3 adet fotoğraf yükleyin.');
      return;
    }

    setIsLoading(true);
    const newPhotosCount = photos.filter(photo => photo instanceof File).length;
    setUploadProgress({ current: 0, total: newPhotosCount, currentFile: 0 });

    try {
      const updatedPhotoURLs = await Promise.all(
        photos.map(async (photo, index) => {
          if (photo instanceof File) {
            return await uploadPhoto(photo);
          } else if (typeof photo === 'string') {
            return photo;
          } else {
            return photoURLs[index] || null;
          }
        })
      );

      if (updatedPhotoURLs.some(url => !url)) {
        throw new Error('Bazı fotoğraflar yüklenemedi');
      }

      await set(dbRef(db, `users/${user.uid}/profilecv/kolaj`), {
        photo1: updatedPhotoURLs[0],
        photo2: updatedPhotoURLs[1],
        photo3: updatedPhotoURLs[2],
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/profile');
      }, 2000);

    } catch (error) {
      console.error('Hata:', error);
      setError('Fotoğraflar kaydedilirken bir hata oluştu.');
    } finally {
      setIsLoading(false);
      setUploadProgress({ current: 0, total: 0, currentFile: 0 });
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-beyaz border-b border-gray-200">
        <div className="flex items-center justify-between px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2"
          >
            <FaArrowLeft className="w-6 h-6 text-yogun-antrasit" />
          </button>
          <h1 className="text-lg font-medium text-yogun-antrasit">
            Kolaj Fotoğrafları
          </h1>
          <button 
            onClick={handleSavePhotos} 
            disabled={isLoading || photos.filter(photo => photo).length < 3}
            className={`text-sm font-medium px-3 py-1.5 rounded-lg
              ${isLoading || photos.filter(photo => photo).length < 3 
                ? 'text-grafit-gri bg-kirik-beyaz' 
                : 'text-beyaz bg-yogun-antrasit active:bg-grafit-gri'}`}
          >
            {isLoading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="pt-16 px-4 pb-20">
        {/* Fotoğraf Yükleme Alanları */}
        <div className="flex gap-3 mb-6">
          {/* Sol taraf - Uzun fotoğraf */}
          <div className="w-1/2">
            <div 
              onClick={() => document.getElementById('photo-upload-0').click()}
              className="relative aspect-[3/4] w-full rounded-xl border border-gray-200 overflow-hidden bg-beyaz h-[400px]"
            >
              {photoURLs[0] ? (
                <img
                  src={photoURLs[0]}
                  alt="Boy fotoğrafı"
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="flex items-center justify-center h-full text-grafit-gri">
                  Boy Fotoğrafı Yükle
                </div>
              )}
              <input
                type="file"
                id="photo-upload-0"
                accept="image/*"
                onChange={handlePhotoChange(0)}
                className="hidden"
              />
            </div>
          </div>

          {/* Sağ taraf - İki kare fotoğraf */}
          <div className="w-1/2 flex flex-col gap-3">
            {[1, 2].map((index) => (
              <div
                key={index}
                onClick={() => document.getElementById(`photo-upload-${index}`).click()}
                className="relative aspect-square w-full rounded-xl border border-gray-200 overflow-hidden bg-beyaz h-[196px]"
              >
                {photoURLs[index] ? (
                  <img
                    src={photoURLs[index]}
                    alt={`Portre ${index}`}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="flex items-center justify-center h-full text-grafit-gri">
                    Portre Fotoğrafı
                  </div>
                )}
                <input
                  type="file"
                  id={`photo-upload-${index}`}
                  accept="image/*"
                  onChange={handlePhotoChange(index)}
                  className="hidden"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Hata Mesajı */}
        {error && (
          <div className="mb-4 p-3 bg-kirmizi/10 text-kirmizi rounded-lg text-sm">
            {error}
          </div>
        )}

        {/* Info İkonu */}
        <div className="fixed bottom-20 right-6 z-[60] group">
          <div className="absolute bottom-full right-0 mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out">
            <div className="bg-[#292929] text-white px-4 py-2 rounded-2xl text-sm font-medium shadow-lg relative">
              Nasıl olmalı?
              <div className="absolute -bottom-1 right-6 w-2 h-2 bg-[#292929] rotate-45"></div>
            </div>
          </div>
          <button
            onClick={() => setShowInfo(!showInfo)}
            className="bg-gradient-to-br from-[#292929] to-[#1a1a1a] text-white p-3.5 rounded-2xl shadow-lg 
            hover:shadow-xl hover:-translate-y-0.5 active:translate-y-0 transition-all duration-200 ease-in-out
            hover:from-[#333333] hover:to-[#1f1f1f]"
          >
            <FaInfoCircle size={22} className="filter drop-shadow-md" />
          </button>
        </div>

        {/* Bilgilendirme Modal */}
        {showInfo && (
          <div 
            className="fixed inset-0 bg-black/50 flex items-end justify-center z-[70] p-4 pb-20"
            onClick={() => setShowInfo(false)}
          >
            <div 
              className="bg-beyaz rounded-t-xl w-full max-w-lg p-5 animate-slide-up"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium text-yogun-antrasit">
                  Nasıl Fotoğraf Yüklemeliyim?
                </h3>
                <button 
                  onClick={() => setShowInfo(false)}
                  className="text-grafit-gri"
                >
                  ✕
                </button>
              </div>
              <ul className="text-sm text-grafit-gri space-y-3">
                <li className="flex items-start gap-2">
                  <span className="text-yogun-antrasit">•</span>
                  <span>Sol alan: Boydan çekilmiş, net ve kaliteli fotoğrafınız</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-yogun-antrasit">•</span>
                  <span>Sağ alanlar: Yüzünüzün net göründüğü portre fotoğraflarınız</span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>

      {/* Yükleme Göstergesi */}
      {isLoading && uploadProgress.total > 0 && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-beyaz rounded-xl p-6 w-full max-w-sm">
            <div className="space-y-4">
              <div className="w-full bg-kirik-beyaz rounded-full h-2">
                <div 
                  className="bg-yogun-antrasit h-2 rounded-full transition-all duration-300"
                  style={{ width: `${uploadProgress.current}%` }}
                />
              </div>
              <div className="text-center space-y-1">
                <p className="text-yogun-antrasit">
                  Fotoğraf Yükleniyor... ({uploadProgress.currentFile + 1}/{uploadProgress.total})
                </p>
                <p className="text-grafit-gri text-sm">
                  %{uploadProgress.current}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Başarı Mesajı */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-beyaz rounded-xl p-6 w-full max-w-sm text-center">
            <FaCheck className="w-12 h-12 text-yesil mx-auto mb-4" />
            <p className="text-yogun-antrasit font-medium">
              Fotoğraflar başarıyla kaydedildi
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Kolaj;