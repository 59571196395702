import React, { useEffect, useState } from "react";
import { storage, db } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { update, ref as dbRef, onValue } from "firebase/database";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaCamera, FaTimes, FaCheck, FaChevronLeft } from "react-icons/fa";

const ProfileEditPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    fullName: "",
    profileImage: null,
    currentProfileImage: null
  });
  
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = dbRef(db, `users/${user.uid}`);
    const unsubscribe = onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setFormData(prev => ({
          ...prev,
          fullName: data.fullName || "",
          currentProfileImage: data.profileImage || null
        }));
      }
    });

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    return () => {
      if (imagePreview) URL.revokeObjectURL(imagePreview);
    };
  }, [imagePreview]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, profileImage: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleUpload = async (file) => {
    const storageRef = ref(storage, `profileImages/${user.uid}_${Date.now()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        reject,
        () => getDownloadURL(uploadTask.snapshot.ref).then(resolve)
      );
    });
  };

  const handleSubmit = async () => {
    if (!user) return;
    
    setLoading(true);
    try {
      let profileImageUrl = formData.currentProfileImage;

      if (formData.profileImage instanceof File) {
        profileImageUrl = await handleUpload(formData.profileImage);
      }

      await update(dbRef(db, `users/${user.uid}`), {
        fullName: formData.fullName,
        profileImage: profileImageUrl,
        updatedAt: Date.now()
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate(-1);
      }, 1500);

    } catch (error) {
      console.error("Güncelleme hatası:", error);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <div className="min-h-screen bg-kirik-beyaz py-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="bg-beyaz rounded-t-2xl shadow-sm px-8 py-6 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button 
              onClick={() => navigate(-1)}
              className="w-10 h-10 rounded-xl bg-kirik-beyaz flex items-center justify-center hover:bg-kirik-beyaz/80 transition-colors"
            >
              <FaChevronLeft className="w-5 h-5 text-yogun-antrasit" />
            </button>
            <h1 className="text-2xl font-bold text-yogun-antrasit">
              Profili Düzenle
            </h1>
          </div>
          
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="px-6 py-2.5 bg-yogun-antrasit text-beyaz rounded-xl font-medium hover:bg-grafit-gri transition-colors flex items-center gap-2"
          >
            <FaCheck className="w-4 h-4" />
            Kaydet
          </button>
        </div>

        {/* Ana İçerik */}
        <div className="bg-beyaz shadow-sm px-8 py-10 rounded-b-2xl">
          <div className="max-w-md mx-auto">
            {/* Profil Fotoğrafı */}
            <div className="flex flex-col items-center mb-12">
              <div className="relative group">
                <div className="w-40 h-40 rounded-full overflow-hidden bg-kirik-beyaz border-4 border-beyaz shadow-lg">
                  {(imagePreview || formData.currentProfileImage) ? (
                    <img
                      src={imagePreview || formData.currentProfileImage}
                      alt="Profil"
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-grafit-gri">
                      <FaCamera className="w-12 h-12 text-beyaz opacity-75" />
                    </div>
                  )}
                </div>
                
                <label className="absolute bottom-0 right-0 cursor-pointer group">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                  <div className="w-12 h-12 bg-yogun-antrasit rounded-full flex items-center justify-center shadow-lg border-2 border-beyaz group-hover:bg-grafit-gri transition-colors">
                    <FaCamera className="w-5 h-5 text-beyaz" />
                  </div>
                </label>
              </div>
            </div>

            {/* Form */}
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-grafit-gri mb-2">
                  Ad Soyad
                </label>
                <input
                  type="text"
                  value={formData.fullName}
                  onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
                  className="w-full h-12 px-4 rounded-xl border border-kirik-beyaz bg-beyaz focus:outline-none focus:ring-2 focus:ring-yogun-antrasit/20 focus:border-yogun-antrasit transition-all"
                  placeholder="Ad Soyad"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Yükleme Göstergesi */}
      {loading && progress > 0 && progress < 100 && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-2xl p-8 max-w-sm w-full mx-4">
            <div className="w-full bg-kirik-beyaz rounded-full h-2 mb-4">
              <div 
                className="h-2 rounded-full bg-yogun-antrasit transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-center text-grafit-gri">
              Yükleniyor... %{progress}
            </p>
          </div>
        </div>
      )}

      {/* Başarı Mesajı */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-beyaz rounded-2xl p-8 max-w-sm w-full mx-4 text-center">
            <div className="w-20 h-20 mx-auto mb-6 rounded-full bg-yesil/10 flex items-center justify-center">
              <FaCheck className="w-10 h-10 text-yesil" />
            </div>
            <p className="text-xl font-medium text-yogun-antrasit">
              Profil başarıyla güncellendi
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileEditPage;
