export const formConfig = {
  personal: {
    title: "Kişisel Bilgiler",
    fields: [
      { 
        name: "positions", 
        label: "Pozisyonlar", 
        type: "multiSelect",
        options: [
          { id: 'actor', label: 'Oyuncu' },
          { id: 'model', label: 'Model' },
          { id: 'supporting_actor', label: 'Yardımcı Oyuncu' },
        ],
        required: true,
        description: "Başvurmak istediğiniz pozisyonları seçin (birden fazla seçebilirsiniz)"
      },
      { 
        name: "birthDate", 
        label: "Doğum Tarihi", 
        type: "date", 
        required: true,
        description: "GG/AA/YYYY formatında giriniz"
      },
      { 
        name: "tcNo", 
        label: "TC Kimlik No", 
        type: "text", 
        required: true,
        maxLength: 11,
        minLength: 11,
        description: "11 haneli TC Kimlik numaranızı giriniz"
      },
      { 
        name: "gender", 
        label: "Cinsiyet", 
        type: "select", 
        required: true,
        options: ["Kadın", "Erkek", "Belirtmek İstemiyorum"]
      },
      { 
        name: "city", 
        label: "Şehir", 
        type: "select", 
        required: true,
        options: [
          "İstanbul", "Ankara", "İzmir", "Bursa", "Antalya", 
          "Adana", "Konya", "Gaziantep", "Mersin", "Eskişehir",
          "Kayseri", "Diyarbakır", "Trabzon", "Samsun"
        ]
      }
    ]
  },
  physical: {
    title: "Fiziksel Özellikler",
    fields: [
      { 
        name: "height", 
        label: "Boy", 
        type: "select", 
        required: true,
        options: Array.from({length: 61}, (_, i) => (140 + i).toString()),
        description: "cm cinsinden"
      },
      { 
        name: "weight", 
        label: "Kilo", 
        type: "select", 
        required: true,
        options: Array.from({length: 81}, (_, i) => (40 + i).toString()),
        description: "kg cinsinden"
      },
      { 
        name: "bodySize", 
        label: "Beden", 
        type: "select", 
        required: true,
        options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"] 
      },
      { 
        name: "shoeSize", 
        label: "Ayakkabı Numarası", 
        type: "select", 
        required: true,
        options: Array.from({length: 20}, (_, i) => (34 + i).toString()) 
      },
      {
        name: "eyeColor",
        label: "Göz Rengi",
        type: "select",
        required: true,
        options: ["Kahverengi", "Ela", "Yeşil", "Mavi", "Siyah", "Gri", "Renkli"]
      },
      {
        name: "hairColor",
        label: "Saç Rengi",
        type: "select",
        required: true,
        options: ["Siyah", "Koyu Kahve", "Kahverengi", "Açık Kahve", "Sarı", "Kumral", "Kızıl", "Beyaz", "Gri"]
      }
    ]
  },
  education: {
    title: "Eğitim Bilgileri",
    multiple: true,
    fields: [
      { name: "institution", label: "Kurum / Okul", type: "text", required: true },
      { name: "department", label: "Bölüm", type: "text", required: true },
      { 
        name: "startYear", 
        label: "Başlangıç Yılı", 
        type: "select", 
        required: true,
        options: Array.from({length: 50}, (_, i) => (new Date().getFullYear() - 49 + i).toString())
      },
      { 
        name: "endYear", 
        label: "Bitiş Yılı", 
        type: "select",
        options: Array.from({length: 50}, (_, i) => (new Date().getFullYear() - 49 + i).toString())
      }
    ]
  },
  skills: {
    title: "Yetenekler",
    options: [
      // Müzik
      'Şan', 'Piyano', 'Gitar', 'Bateri', 'Keman', 'Bağlama', 'Ney', 'Ud', 'Kanun',
      // Dans
      'Bale', 'Modern Dans', 'Hip Hop', 'Halk Dansları', 'Latin Dansları', 'Çağdaş Dans',
      // Tiyatro & Drama
      'Doğaçlama', 'Diksiyon', 'Mimik', 'Pandomim', 'Tiyatro', 'Monolog', 'Stand-up',
      // Spor
      'Yüzme', 'Binicilik', 'Eskrim', 'Yoga', 'Pilates', 'Jimnastik', 'Dövüş Sanatları',
      // Dil
      'İngilizce', 'Almanca', 'Fransızca', 'İspanyolca', 'İtalyanca', 'Rusça', 'Arapça',
      // Diğer
      'Dublaj', 'Seslendirme', 'Sunuculuk', 'Modellik', 'Akrobasi', 'Jonglörlük'
    ]
  }
}; 