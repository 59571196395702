import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  FaSpinner, 
  FaArrowLeft, 
  FaPhone, 
  FaEnvelope, 
  FaMapMarkerAlt 
} from 'react-icons/fa';

const db = getDatabase();

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

const translateKey = (key) => {
  const translations = {
    'beardStyle': 'Sakal Stili',
    'birthDate': 'Doğum Tarihi',
    'bodySize': 'Beden',
    'city': 'Şehir',
    // ... diğer çeviriler aynı ...
  };
  return translations[key] || key;
};

const formatValue = (key, value) => {
  if (key === 'height') return `${value} cm`;
  if (key === 'weight') return `${value} kg`;
  if (key === 'birthDate') {
    return new Date(value).toLocaleDateString('tr-TR');
  }
  return value;
};

const PlayerDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const playerRef = ref(db, `users/${id}`);
        const selectedRef = ref(db, `users/${user.uid}/selectedPlayers/${id}`);
        
        const [playerSnapshot, selectedSnapshot] = await Promise.all([
          get(playerRef),
          get(selectedRef)
        ]);
        
        if (playerSnapshot.exists()) {
          setPlayer(playerSnapshot.val());
          setIsSelected(selectedSnapshot.exists());
        }
      } catch (error) {
        console.error("Veri çekilirken hata:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [id, user, navigate]);

  const handleSelectPlayer = async () => {
    if (!user || isUpdating) return;

    setIsUpdating(true);
    try {
      const selectedRef = ref(db, `users/${user.uid}/selectedPlayers/${id}`);
      
      if (isSelected) {
        await remove(selectedRef);
        setIsSelected(false);
      } else {
        const playerData = {
          id,
          firstName: player.firstName,
          lastName: player.lastName,
          profileImage: player.profileImage || player.profilecv?.profileImage,
          position: player.profilecv?.globalcv?.position || 'Belirtilmemiş',
          birthDate: player.profilecv?.globalcv?.birthDate,
          city: player.profilecv?.globalcv?.city,
          tcno: player.profilecv?.globalcv?.tcNo || player.tcno || 'Belirtilmemiş',
          phone: player.phone || player.profilecv?.globalcv?.phone || 'Belirtilmemiş'
        };
        await update(selectedRef, playerData);
        setIsSelected(true);
      }
    } catch (error) {
      console.error("İşlem hatası:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <FaSpinner className="animate-spin text-4xl text-yogun-antrasit" />
      </div>
    );
  }

  if (!user || !player || (user.role !== 'admin' && user.role !== 'production')) {
    return null;
  }

  return (
    <div className="min-h-screen bg-kirik-beyaz pb-16">
      {/* Header */}
      <div className="sticky top-0 bg-beyaz z-10 shadow-sm">
        <div className="flex items-center p-4">
          <button 
            onClick={() => navigate(-1)}
            className="text-yogun-antrasit p-2 -ml-2"
          >
            <FaArrowLeft size={20} />
          </button>
          <h1 className="text-xl font-bold text-yogun-antrasit flex-1 text-center -ml-8">
            {player.firstName} {player.lastName}
          </h1>
        </div>
      </div>

      {/* Profile Section */}
      <div className="p-4">
        <div className="bg-beyaz rounded-xl shadow-sm p-4">
          <div className="flex gap-4 items-start">
            <img
              src={player.profileImage || player.profilecv?.profileImage || 'default_image_url'}
              alt={`${player.firstName} ${player.lastName}`}
              className="w-24 h-24 rounded-full object-cover"
            />
            <div className="flex-1">
              <h2 className="text-lg font-bold text-yogun-antrasit">
                {player.firstName} {player.lastName}
              </h2>
              <p className="text-grafit-gri">
                {player.profilecv?.globalcv?.position || 'Pozisyon belirtilmemiş'}
              </p>
              
              {user.role === 'admin' && (
                <div className="mt-2 space-y-1">
                  {player.phone && (
                    <a 
                      href={`tel:${player.phone}`}
                      className="flex items-center text-sm text-grafit-gri"
                    >
                      <FaPhone className="mr-2" size={14} />
                      {formatPhoneNumber(player.phone)}
                    </a>
                  )}
                  {player.email && (
                    <a 
                      href={`mailto:${player.email}`}
                      className="flex items-center text-sm text-grafit-gri"
                    >
                      <FaEnvelope className="mr-2" size={14} />
                      {player.email}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          <button 
            onClick={handleSelectPlayer}
            disabled={isUpdating}
            className={`w-full mt-4 py-3 rounded-xl font-medium transition-colors ${
              isSelected 
                ? 'bg-yogun-antrasit text-beyaz' 
                : 'bg-kirik-beyaz text-yogun-antrasit'
            } ${isUpdating ? 'opacity-50' : ''}`}
          >
            {isUpdating ? (
              <FaSpinner className="animate-spin mx-auto" />
            ) : (
              isSelected ? 'Seçimi Kaldır' : 'Oyuncuyu Seç'
            )}
          </button>
        </div>
      </div>

      {/* CV Section */}
      {player.profilecv?.globalcv && (
        <div className="p-4">
          <div className="bg-beyaz rounded-xl shadow-sm p-4">
            <h3 className="text-lg font-bold text-yogun-antrasit mb-4">
              CV Bilgileri
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(player.profilecv.globalcv).map(([key, value]) => {
                if (key === 'bio' || key === 'description' || typeof value === 'object') {
                  return null;
                }
                return (
                  <div key={key} className="space-y-1">
                    <p className="text-sm text-grafit-gri">
                      {translateKey(key)}
                    </p>
                    <p className="font-medium text-yogun-antrasit">
                      {formatValue(key, value) || 'Belirtilmemiş'}
                    </p>
                  </div>
                );
              })}
            </div>
            {(player.profilecv.globalcv.bio || player.profilecv.globalcv.description) && (
              <div className="mt-6">
                <p className="text-sm text-grafit-gri">Biyografi</p>
                <p className="mt-2 text-yogun-antrasit">
                  {player.profilecv.globalcv.bio || player.profilecv.globalcv.description}
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Photos Section */}
      <div className="p-4">
        <h3 className="text-lg font-bold text-yogun-antrasit mb-4">
          Fotoğraflar
        </h3>
        <div className="grid grid-cols-2 gap-4">
          {player.profilecv?.kolaj?.photo1 && (
            <div className="aspect-square rounded-xl overflow-hidden">
              <img
                src={player.profilecv.kolaj.photo1}
                alt="Fotoğraf 1"
                className="w-full h-full object-cover"
              />
            </div>
          )}
          {player.profilecv?.kolaj?.photo2 && (
            <div className="aspect-square rounded-xl overflow-hidden">
              <img
                src={player.profilecv.kolaj.photo2}
                alt="Fotoğraf 2"
                className="w-full h-full object-cover"
              />
            </div>
          )}
          {player.profilecv?.kolaj?.photo3 && (
            <div className="aspect-square rounded-xl overflow-hidden">
              <img
                src={player.profilecv.kolaj.photo3}
                alt="Fotoğraf 3"
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>
      </div>

      {/* Video Section */}
      {player.profilecv?.introVideo && (
        <div className="p-4">
          <h3 className="text-lg font-bold text-yogun-antrasit mb-4">
            Tanıtım Videosu
          </h3>
          <div className="rounded-xl overflow-hidden">
            <video
              className="w-full aspect-video object-cover"
              poster={player.profilecv.introVideoThumbnail}
              controls
            >
              <source src={player.profilecv.introVideo} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerDetailPage;
